import { RouteProps, Route, Redirect } from 'react-router-dom'

import { FeatureFlag } from 'types'
import { usePermissions } from 'hooks'

interface Props {
  flags: FeatureFlag[]
}
export const ProtectedRoute: React.FC<RouteProps & Props> = ({ flags, ...routeProps }) => {
  const { permissions } = usePermissions()

  if (!permissions.satelliteImagery && flags.includes(FeatureFlag.SATELLITE_IMAGERY))
    return <Redirect to="/dashboard" />

  return <Route {...routeProps} />
}
