import React, { useState } from 'react'
import { Button, Spin, List, Space } from 'antd'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { WeatherType } from 'types'
import { LeftArrowIcon, RightARrowIcon } from 'assets/icons'
import {
  cloudImage,
  partyallyCloudedWithCirrusImage,
  clearImage,
  satelliteImage,
} from 'assets/images'
import { EllipsisText } from 'components'

import {
  LoadingData,
  StyledRow,
  RowContainer,
  IconContainer,
  SpinIcon,
  DividerContainer,
  Divider,
  Popover,
  Image,
  TooltipRow,
  StyledCol,
  Text,
  PaddingText,
} from './components'
import { TimelineProps } from '../../types'
import { useTimeline } from './hooks'

const QTY_MIN_OF_DATES_TO_FETCH_MORE = 3

export const Timeline: React.FC<TimelineProps> = ({
  timelineData,
  setDates,
  isLotsLoading,
  error,
}) => {
  const [showPopover, setShowPopover] = useState<{ date: string; weatherType?: WeatherType }>()
  const {
    selectedTimeline,
    lastTimetableElementDisplayed,
    setSelectedTimeline,
    setLastTimetableElementDisplayed,
    popoverDataForAllLots,
    timelineLotsData,
    firstTimetableElementDisplayed,
  } = useTimeline(timelineData)
  const { t } = useTranslation(I18NService.NAMESPACES.MAP)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)

  const allLotstooltipMap = {
    CLEAR: { url: clearImage, text: t('timeline.tooltips.allLots.clear') },
    CIRRUS: { url: partyallyCloudedWithCirrusImage, text: t('timeline.tooltips.allLots.cirrus') },
    PARTIALLY_CLOUDED: { url: cloudImage, text: t('timeline.tooltips.allLots.partiallyClouded') },
    PARTIALLY_CLOUDED_WITH_CIRRUS: {
      url: partyallyCloudedWithCirrusImage,
      text: t('timeline.tooltips.allLots.partiallyCloudedWithCirrus'),
    },
    CLOUDY: { url: cloudImage, text: t('timeline.tooltips.allLots.cloudy') },
    IMAGE_NOT_AVAILABLE: {
      url: satelliteImage,
      text: t('timeline.tooltips.allLots.imageNotAvailable'),
    },
  }

  const tooltipMap = {
    CLEAR: { url: clearImage, text: t('timeline.tooltips.singleLot.clear') },
    CIRRUS: { url: cloudImage, text: t('timeline.tooltips.singleLot.cirrus') },
    PARTIALLY_CLOUDED: {
      url: cloudImage,
      text: t('timeline.tooltips.singleLot.partiallyClouded'),
    },
    PARTIALLY_CLOUDED_WITH_CIRRUS: {
      url: cloudImage,
      text: t('timeline.tooltips.singleLot.partiallyCloudedWithCirrus'),
    },
    CLOUDY: { url: cloudImage, text: t('timeline.tooltips.singleLot.cloudy') },
    IMAGE_NOT_AVAILABLE: {
      url: satelliteImage,
      text: t('timeline.tooltips.singleLot.imageNotAvailable'),
    },
  }

  if (!timelineData.length) return <LoadingData />
  const checkTimelineLotStatus = (isAvailable: boolean, date: string) => {
    if (!isAvailable) return 'disabled'
    return date === selectedTimeline ? 'active' : 'inactive'
  }

  const hasTimelineDateAnyAvailableLots = (date: string) => {
    return timelineData
      .map(timeline => {
        return timeline.riceLot.catalog.dates.some(
          catalogDate => catalogDate.date === date && catalogDate.isAvailable,
        )
      })
      .some(isTimelineAvailable => isTimelineAvailable)
  }

  const checkTimelineLotsStatus = (date: string) => {
    if (!hasTimelineDateAnyAvailableLots(date)) return 'disabled'
    return date === selectedTimeline ? 'active' : 'inactive'
  }

  const checkTimelineLotType = (date: string, isAvailable: boolean, weatherType: WeatherType) => {
    if (!isAvailable) return setShowPopover({ date, weatherType })
    if (weatherType === WeatherType.CLEAR) return setShowPopover(undefined)
    return setShowPopover({ date, weatherType })
  }

  const setNextTimelineAvailable = () => {
    setLastTimetableElementDisplayed(prevState => prevState + 1)
  }

  const setPreviousTimelineAvailable = () => {
    const toTime = new Date(timelineLotsData.lotsData[0].date)
    const aMonthAgo = new Date(timelineLotsData.lotsData[0].date)
    const fromTime = new Date(aMonthAgo.setMonth(aMonthAgo.getMonth() - 1))
    if (
      firstTimetableElementDisplayed <= QTY_MIN_OF_DATES_TO_FETCH_MORE &&
      timelineLotsData.hasMore
    )
      setDates({ fromTime, toTime, newDates: true })
    setLastTimetableElementDisplayed(prevState => prevState - 1)
  }

  // the condition !firstTimetableElementDisplayed is to prevent disabled button
  // when the first element displayed isn't the first element on the array
  const previousDateDisabled =
    (timelineData && !firstTimetableElementDisplayed && !timelineData[0].riceLot.catalog.hasMore) ||
    (error && !firstTimetableElementDisplayed)

  return (
    <StyledRow>
      <RowContainer>
        <IconContainer
          onClick={setPreviousTimelineAvailable}
          disabled={isLotsLoading || previousDateDisabled}
        >
          {isLotsLoading && timelineData ? <Spin indicator={SpinIcon} /> : <LeftArrowIcon />}
        </IconContainer>
        <DividerContainer>
          <Divider flex={1} />
        </DividerContainer>
        {timelineLotsData.lotsData
          .slice(firstTimetableElementDisplayed, lastTimetableElementDisplayed)
          .map(({ isAvailable, date, type }) => {
            return (
              <React.Fragment key={date}>
                <Popover
                  visible={showPopover?.date === date}
                  onVisibleChange={() => setShowPopover(undefined)}
                  content={
                    <>
                      {timelineData.length > 1
                        ? popoverDataForAllLots[date].map(popoverData => (
                            <List
                              key={popoverData.name}
                              dataSource={[{ name: popoverData.name, type: popoverData.type }]}
                              grid={{ gutter: 16 }}
                              renderItem={item => (
                                <List.Item>
                                  <Space>
                                    <EllipsisText
                                      text={item.name}
                                      overlayInnerStyle={{
                                        fontWeight: 'normal',
                                        fontSize: '13px',
                                        lineHeight: '24px',
                                        width: '100px',
                                      }}
                                    />
                                    <Image
                                      src={allLotstooltipMap[popoverData.type].url}
                                      size="22px"
                                    />

                                    <Text>{allLotstooltipMap[popoverData.type].text}</Text>
                                  </Space>
                                </List.Item>
                              )}
                            />
                          ))
                        : showPopover?.weatherType && (
                            <TooltipRow justify="center" align="middle">
                              <Image src={tooltipMap[showPopover?.weatherType].url} size="50px" />
                              <PaddingText>{tooltipMap[showPopover?.weatherType].text}</PaddingText>
                            </TooltipRow>
                          )}
                    </>
                  }
                  overlayInnerStyle={{
                    overflow: 'hidden',
                    marginBottom: '20px',
                  }}
                >
                  <StyledCol
                    key={date}
                    flex={1}
                    status={
                      timelineData.length > 1
                        ? checkTimelineLotsStatus(date)
                        : checkTimelineLotStatus(isAvailable, date)
                    }
                    onMouseEnter={() =>
                      timelineData.length > 1
                        ? setShowPopover({ date })
                        : checkTimelineLotType(date, isAvailable, type)
                    }
                  >
                    <Button
                      type="link"
                      disabled={
                        timelineData.length > 1
                          ? !hasTimelineDateAnyAvailableLots(date)
                          : !isAvailable
                      }
                      onClick={() => {
                        setSelectedTimeline(date)
                      }}
                    >
                      {commonT('dates.dayAndShortMonth', {
                        val: parseISO(date),
                      })}
                    </Button>
                  </StyledCol>
                </Popover>
                <DividerContainer>
                  <Divider flex={1} />
                </DividerContainer>
              </React.Fragment>
            )
          })}
        <IconContainer
          disabled={lastTimetableElementDisplayed === timelineLotsData.lotsData.length}
          onClick={setNextTimelineAvailable}
        >
          <RightARrowIcon />
        </IconContainer>
      </RowContainer>
    </StyledRow>
  )
}
