import styled, { useTheme } from 'styled-components'
import { Col, Menu, Space } from 'antd'
import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'

import { HiddenEyeIcon, PolygonIcon, ToolbarTrashIcon } from 'assets/icons'
import { EllipsisText } from 'components'
import { DrawingOption } from 'features/MainRouter/features/Map/types'
import { I18NService } from 'services'

const MenuContainer = styled(Menu)`
  width: 220px;
  position: absolute;
  top: 14px;
  border-radius: 4px;
  border: none;
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]};
  .ant-menu-item-selected span,
  .ant-menu-item-selected svg {
    color: ${({ theme }) => theme.colors.white} !important;
  }
  .ant-menu-item-active,
  .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
    border-radius: 4px !important;
  }
`

const MenuItem = styled(Menu.Item)`
  display: flex;
  margin: 0 !important;
  height: 52px !important;
  & span {
    display: flex;
  }
`

const ItemIcon = styled(Col)`
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 18px;
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
`

type Item = {
  id: DrawingOption
  text: string
  Icon: ComponentType
  disabled?: boolean
}

interface Props {
  selectedItem: DrawingOption
  onDrawingOptionSelected: (option: DrawingOption) => void
  isDrawingToolAvailable: boolean
}

export const MeasureAreaDropdown: React.FC<Props> = ({
  selectedItem,
  onDrawingOptionSelected,
  isDrawingToolAvailable,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation(I18NService.NAMESPACES.MAP)

  const dataSource: Item[] = [
    {
      id: 'draw',
      text: t('tools.draw'),
      Icon: PolygonIcon,
    },
    {
      id: 'hide',
      text: t('tools.hide'),
      Icon: HiddenEyeIcon,
      disabled: isDrawingToolAvailable,
    },
    {
      id: 'delete',
      text: t('tools.delete'),
      Icon: ToolbarTrashIcon,
      disabled: isDrawingToolAvailable,
    },
    {
      id: 'delete-all',
      text: t('tools.deleteAll'),
      Icon: ToolbarTrashIcon,
      disabled: isDrawingToolAvailable,
    },
  ]

  return (
    <MenuContainer
      selectedKeys={selectedItem ? [selectedItem] : []}
      onClick={e => onDrawingOptionSelected(e.key as DrawingOption)}
    >
      {dataSource.map(item => {
        const { Icon } = item
        return (
          <MenuItem key={item.id} disabled={item.disabled}>
            <Space size={17}>
              <ItemIcon>
                <Icon />
              </ItemIcon>
              <EllipsisText
                text={item.text}
                overlayInnerStyle={{
                  fontSize: 13,
                  fontWeight: 600,
                  maxWidth: '100%',
                  color: colors.new.baseColor[400],
                }}
              />
            </Space>
          </MenuItem>
        )
      })}
    </MenuContainer>
  )
}
