import { nanoid } from 'nanoid'
import { kml } from '@tmcw/togeojson'

const NANO_ID_SIZE = 5

const generateId = () => nanoid(NANO_ID_SIZE)

const kmlFileToGeoJson = async (file: File) => {
  const text = await file.text()
  const parser = new DOMParser()
  const doc = parser.parseFromString(text, 'text/xml')
  const geoJson = kml(doc)

  // this workaround is to remove the z-axis, which is set to 0 by default.
  geoJson.features.forEach(feature =>
    feature.geometry.coordinates[0].forEach(coordinate => {
      // If we have z-axis we remove it
      if (coordinate.length === 3) {
        coordinate.pop()
      }
    }),
  )

  return geoJson
}

const removeAccents = (text: string) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const MiscUtils = {
  generateId,
  kmlFileToGeoJson,
  removeAccents,
}
