import { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'

import { MapContext } from 'features/MainRouter/contexts'
import { WeatherType } from 'types'

import { DataObject, TimelineData } from '../../../types'

const QTY_MIN_OF_DATES_DISPLAYED = 7
const IMAGE_NOT_AVAILABLE = 'IMAGE_NOT_AVAILABLE'
export const useTimeline = (timelineData: TimelineData[]) => {
  const { setSelectedTimeline, selectedTimeline } = MapContext.useMapContext()

  const [totalDates, setTotalDates] = useState(0)
  const [lastTimetableElementDisplayed, setLastTimetableElementDisplayed] = useState(0)

  const allLotsDates = useMemo(() => {
    return timelineData
      .map(lot => {
        return lot.riceLot.catalog.dates.map(catalogDate => {
          return {
            date: catalogDate.date,
            type: catalogDate.type,
            isAvailable: catalogDate.isAvailable,
          }
        })
      })
      .flat()
  }, [timelineData])

  const allLotsDatesSortedByDate = useMemo(() => {
    return _.sortBy(allLotsDates, 'date')
  }, [allLotsDates])

  const allLotsWithoutDuplicateDates = useMemo(() => {
    return _.sortedUniqBy(allLotsDatesSortedByDate, 'date')
  }, [allLotsDatesSortedByDate])

  const popoverDataForAllLots = useMemo(() => {
    return timelineData.reduce<DataObject>((result, timelineDate, timelineIndex) => {
      allLotsWithoutDuplicateDates.forEach(catalogDate => {
        const key = catalogDate.date
        // eslint-disable-next-line no-param-reassign
        result[key] = result[key] ?? []
        const type =
          timelineData[timelineIndex].riceLot.catalog.dates.find(
            date => date.date === catalogDate.date,
          )?.type ?? IMAGE_NOT_AVAILABLE

        // eslint-disable-next-line no-param-reassign
        result[key] = [...result[key], { name: timelineDate.name, type }]
      })

      return result
    }, {})
  }, [allLotsWithoutDuplicateDates, timelineData])

  const allLotsSortedByRecentDate = useMemo(() => {
    return Object.entries(popoverDataForAllLots).sort(
      ([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime(),
    )
  }, [popoverDataForAllLots])

  const firstLotAvailable = useMemo(() => {
    return allLotsSortedByRecentDate.find(lots =>
      lots[1].some(({ type }) => type !== WeatherType.CLOUDY && type !== IMAGE_NOT_AVAILABLE),
    )
  }, [allLotsSortedByRecentDate])

  useEffect(() => {
    if (!timelineData.length) return
    if ((lastTimetableElementDisplayed && !totalDates) || !selectedTimeline) {
      if (!firstLotAvailable) return

      setSelectedTimeline(firstLotAvailable[0])
      setLastTimetableElementDisplayed(allLotsWithoutDuplicateDates.length)
      setTotalDates(allLotsWithoutDuplicateDates.length)
      return
    }

    if (totalDates !== allLotsWithoutDuplicateDates.length) {
      setLastTimetableElementDisplayed(
        prevState => allLotsWithoutDuplicateDates.length - totalDates + prevState,
      )
      setTotalDates(allLotsWithoutDuplicateDates.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstLotAvailable,
    lastTimetableElementDisplayed,
    popoverDataForAllLots,
    timelineData,
    totalDates,
  ])

  useEffect(() => {
    if (!firstLotAvailable) return

    const lotsDataForSelectedDate = allLotsSortedByRecentDate.find(
      allLotsData => allLotsData[0] === selectedTimeline,
    )
    const isSelectedDateAvailable = lotsDataForSelectedDate?.[1].some(
      ({ type }) => type !== WeatherType.CLOUDY && type !== IMAGE_NOT_AVAILABLE,
    )

    if (isSelectedDateAvailable) return

    setSelectedTimeline(firstLotAvailable[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineData, allLotsSortedByRecentDate])

  const firstTimetableElementDisplayed =
    lastTimetableElementDisplayed - QTY_MIN_OF_DATES_DISPLAYED > 0
      ? lastTimetableElementDisplayed - QTY_MIN_OF_DATES_DISPLAYED
      : 0

  const hasMore = timelineData.some(lot => lot.riceLot.catalog.hasMore)

  return {
    selectedTimeline,
    lastTimetableElementDisplayed,
    setSelectedTimeline,
    setLastTimetableElementDisplayed,
    popoverDataForAllLots,
    timelineLotsData: { lotsData: allLotsWithoutDuplicateDates, hasMore },
    firstTimetableElementDisplayed,
  }
}
