import { useMap } from 'react-map-gl'
import { useEffect } from 'react'
import MapboxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder'

import { GeocoderControlProps } from './types'

const coordinatesGeocoder = (query: string) => {
  // https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-geocoder-accept-coordinates/
  const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i)
  if (!matches) {
    return []
  }

  function coordinateFeature(lng: number, lat: number) {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      place_name: `Latitud: ${lat} Longitud: ${lng}`,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature',
    }
  }

  const coord1 = Number(matches[1])
  const coord2 = Number(matches[2])
  const geocodes = []

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2))
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1))
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord2, coord1))
    geocodes.push(coordinateFeature(coord1, coord2))
  }

  return geocodes as Result[]
}

export const GeocoderControl: React.FC<GeocoderControlProps> = ({
  geocoderOptions,
  containerRef,
  onResult,
}) => {
  const { fieldsMap } = useMap()

  useEffect(() => {
    if (!fieldsMap || !containerRef) return
    const control = new MapboxGeocoder({
      ...geocoderOptions,
      localGeocoder: coordinatesGeocoder,
    })
    control.on('result', onResult)

    const map = fieldsMap.getMap()

    containerRef.current.appendChild(control.onAdd(map))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsMap, containerRef])

  return null
}
