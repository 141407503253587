/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum FeatureSet {
  GRUPO_DIANA = "GRUPO_DIANA",
}

export enum IndicatorStatus {
  BAD = "BAD",
  GOOD = "GOOD",
  MEDIUM = "MEDIUM",
}

export enum SeasonMillDeliveryType {
  NO_DELIVERY = "NO_DELIVERY",
  PARTIAL = "PARTIAL",
  TOTAL = "TOTAL",
}

export enum SentinelHubProvider {
  PLANET = "PLANET",
  SENTINEL2 = "SENTINEL2",
}

export enum UserCompanyRole {
  ADMIN = "ADMIN",
  VIEWER = "VIEWER",
}

export enum UserOrganizationRole {
  ADMIN = "ADMIN",
  COMMERCIAL = "COMMERCIAL",
  MILL = "MILL",
}

export enum WeatherType {
  CIRRUS = "CIRRUS",
  CLEAR = "CLEAR",
  CLOUDY = "CLOUDY",
  PARTIALLY_CLOUDED = "PARTIALLY_CLOUDED",
  PARTIALLY_CLOUDED_WITH_CIRRUS = "PARTIALLY_CLOUDED_WITH_CIRRUS",
}

export interface CreateCompanyInput {
  name: string;
  logo?: Upload | null;
  country: string;
  subregionId?: number | null;
  organizationId?: number | null;
  governmentId?: string | null;
}

export interface CreateFieldInput {
  name: string;
  location: PointInput;
  directions?: string | null;
  companyId: number;
}

export interface CreateLotInput {
  name: string;
  commercialName?: string | null;
  area: PolygonInput;
  seasonId: number;
  fieldId: number;
  previousCropId?: number | null;
  cropId: number;
  varietyId?: number | null;
}

export interface CreateRiceAppliedFertilizerInput {
  name?: string | null;
  brand?: string | null;
  date?: DateTime | null;
  quantity?: number | null;
  unitId?: number | null;
  riceLotId: number;
  fertilizerGrades?: FertilizerGradeInput[] | null;
}

export interface CreateRiceAppliedPhytosanitaryInput {
  name?: string | null;
  brand?: string | null;
  date?: DateTime | null;
  quantity?: number | null;
  unitId?: number | null;
  riceLotId: number;
  activeIngredients?: number[] | null;
}

export interface CreateSeasonInput {
  name: string;
  startDate: DateTime;
  endDate?: DateTime | null;
  description?: string | null;
  companyId: number;
  productionToMill?: number | null;
  millDeliveryType?: string | null;
}

export interface CreateUserInput {
  email: string;
  password: string;
  name: string;
  lastName: string;
  profilePicture?: Upload | null;
}

export interface FertilizerGradeInput {
  grade?: number | null;
  nutrientId?: number | null;
}

export interface InviteUserInput {
  email: string;
  role: UserCompanyRole;
  companyId: number;
}

export interface PointInput {
  type?: string | null;
  coordinates: number[];
}

export interface PolygonInput {
  type: string;
  coordinates: number[][][];
}

export interface UpdateCompanyInput {
  name?: string | null;
  logo?: Upload | null;
  subregionId?: number | null;
  organizationId?: number | null;
  governmentId?: string | null;
}

export interface UpdateFieldInput {
  name?: string | null;
  location?: PointInput | null;
  directions?: string | null;
}

export interface UpdateLotInput {
  name?: string | null;
  commercialName?: string | null;
  area?: PolygonInput | null;
  previousCropId?: number | null;
  cropId?: number | null;
  varietyId?: number | null;
  id: number;
}

export interface UpdateRiceAppliedFertilizerInput {
  name?: string | null;
  brand?: string | null;
  date?: DateTime | null;
  quantity?: number | null;
  unitId?: number | null;
  fertilizerGrades?: FertilizerGradeInput[] | null;
}

export interface UpdateRiceAppliedPhytosanitaryInput {
  name?: string | null;
  brand?: string | null;
  date?: DateTime | null;
  quantity?: number | null;
  unitId?: number | null;
  activeIngredients?: number[] | null;
}

export interface UpdateRiceLotInput {
  plantingDate?: DateTime | null;
  emergenceDate?: DateTime | null;
  firstIrrigationStartDate?: DateTime | null;
  firstIrrigationEndDate?: DateTime | null;
  differentiationDate?: DateTime | null;
  floweringDate?: DateTime | null;
  physiologicalMaturityDate?: DateTime | null;
  harvestDate?: DateTime | null;
  yield?: number | null;
  comment?: string | null;
  preparationDate?: DateTime | null;
  burningDate?: DateTime | null;
  earAppearanceDate?: DateTime | null;
}

export interface UpdateSeasonInput {
  name?: string | null;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  description?: string | null;
  companyId?: number | null;
  productionToMill?: number | null;
  millDeliveryType?: string | null;
}

export interface UpdateUserInput {
  password?: string | null;
  name?: string | null;
  lastName?: string | null;
  profilePicture?: Upload | null;
  isVerified?: boolean | null;
  actualPassword?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
