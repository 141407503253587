import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { I18NService } from 'services'

export const StyledSpan = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.colors.olive};
`

interface Props {
  emergenceDaysActivation?: number
}

export const NotAvailableText: React.FC<Props> = ({ emergenceDaysActivation }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)
  return (
    <>
      {!emergenceDaysActivation ? (
        <StyledSpan>{t('grainHumidityCard.availableNextImage')}</StyledSpan>
      ) : (
        <StyledSpan>
          {t('grainHumidityCard.availableSince', { days: emergenceDaysActivation })}
        </StyledSpan>
      )}
    </>
  )
}
