import { Row } from 'antd'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { GrainHumidityIcon } from 'assets/icons'
import { I18NService } from 'services'

import { GrainHumidity as GrainHumidityType } from '../../api'
import { Chart, ChartContainer, CenterCol, StyledSpace, Ball, NotAvailableText } from './components'

interface Props {
  data?: GrainHumidityType | null
  isGuriVariety: boolean
}

export const GrainHumidity: React.FC<Props> = ({ data, isGuriVariety }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)
  const { colors } = useTheme()

  const varietyData = [
    {
      color: colors.grainHumedityBrown,
      value: isGuriVariety ? `<${data?.low?.highEdge}%` : t('grainHumidityCard.status.dry'),
    },
    {
      color: colors.grainHumedityLightBrown,
      value: isGuriVariety
        ? `${data?.mediumLow?.lowEdge} ${t('grainHumidityCard.to')} ${data?.mediumLow?.highEdge}%`
        : t('grainHumidityCard.status.midDry'),
    },
    {
      color: colors.grainHumedityYellow,
      value: isGuriVariety
        ? `${data?.mediumHigh?.lowEdge} ${t('grainHumidityCard.to')} ${data?.mediumHigh?.highEdge}%`
        : t('grainHumidityCard.status.mid'),
    },
    {
      color: colors.grainHumedityLightGreen,
      value: isGuriVariety ? `> ${data?.high?.lowEdge}%` : t('grainHumidityCard.status.humid'),
    },
  ]

  const chartData = [
    { name: 'low', value: data?.low?.percentage, color: colors.grainHumedityBrown },
    {
      name: 'mediumLow',
      value: data?.mediumLow?.percentage,
      color: colors.grainHumedityLightBrown,
    },
    { name: 'mediumHigh', value: data?.mediumHigh?.percentage, color: colors.grainHumedityYellow },
    { name: 'high', value: data?.high?.percentage, color: colors.grainHumedityLightGreen },
  ]

  return (
    <Row wrap={false} gutter={24}>
      <ChartContainer>
        {data?.isAvailable ? <Chart data={chartData} /> : <GrainHumidityIcon />}
      </ChartContainer>
      <CenterCol>
        {data?.isAvailable ? (
          <StyledSpace size={10} direction="vertical">
            {varietyData.map(variety => {
              return (
                <StyledSpace size={8} key={variety.color}>
                  <Ball color={variety.color} />
                  <span>{variety.value}</span>
                </StyledSpace>
              )
            })}
          </StyledSpace>
        ) : (
          <NotAvailableText emergenceDaysActivation={data?.emergenceDaysActivation ?? undefined} />
        )}
      </CenterCol>
    </Row>
  )
}
