import { Col, Row } from 'antd'
import { addDays } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { usePermissions } from 'hooks'
import { I18NService } from 'services'

import { EstimatedYield, UpdateRiceLotVariables } from '../../api'
import { PeakDate, YieldData, Divider } from './components'

interface Props {
  estimatedYield?: EstimatedYield
  size: number
  yieldValue?: number
  varietyName?: string
  emergenceDate?: string
  updateRiceLotMutation: (updateRiceLotDTO: { variables: UpdateRiceLotVariables }) => void
  riceLotId?: number
}
interface ChlorophyllPeakDate {
  [key: string]: number
}
const chlorophyllPeakDate: ChlorophyllPeakDate = {
  guriIntaCl: 95,
  membyPoraIntaCl: 105,
  irga424: 105,
  hispamar: 90,
  guadiagran: 90,
  carnaroli: 110,
  crianza178: 140,
}

const DEFAULT_CHLOROPHYLL_PEAK_DAYS = 100

export const Yield: React.FC<Props> = ({
  estimatedYield,
  size,
  yieldValue,
  varietyName,
  emergenceDate,
  riceLotId,
  updateRiceLotMutation,
}) => {
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { permissions } = usePermissions()

  const estimatedPeakMonth = () => {
    if (!varietyName || !emergenceDate) return undefined
    const peakNumber = chlorophyllPeakDate[varietyName] ?? DEFAULT_CHLOROPHYLL_PEAK_DAYS
    const estimatedPeakDate = addDays(new Date(emergenceDate), peakNumber)

    const month = commonT('dates.longMonth', { date: estimatedPeakDate })

    return month
  }

  const estimatedPeakDate = () => {
    if (!estimatedYield?.chlorophyllPeakDate) return undefined

    const formattedDate = commonT('dates.medium')

    return formattedDate
  }

  return (
    <Row gutter={24}>
      <Col flex="2 0 0">
        <YieldData
          isAvailable={estimatedYield?.isAvailable}
          yieldValue={yieldValue ? yieldValue / 1000 : undefined}
          yieldKgValuePerHa={yieldValue ? yieldValue / size : undefined}
          estimatedTnValue={estimatedYield?.value ? estimatedYield?.value / 1000 : undefined}
          estimatedKgValuePerHa={estimatedYield?.value ? estimatedYield.value / size : undefined}
          riceLotId={riceLotId}
          updateRiceLotMutation={updateRiceLotMutation}
        />
      </Col>
      {!permissions.isFeatureSetGrupoDiana && (
        <>
          <Col>
            <Divider />
          </Col>
          <Col flex="1 0 0">
            <PeakDate
              chlorophyllPeakDate={estimatedPeakDate()}
              estimatedPeakMonth={estimatedPeakMonth()}
              noFormula={estimatedYield?.noFormula ?? undefined}
            />
          </Col>
        </>
      )}
    </Row>
  )
}
