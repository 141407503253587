import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuth } from 'hooks'
import { I18NService } from 'services'

import {
  BackgroundImage,
  StyledCard,
  CardImage,
  ContentContainer,
  CardContent,
  CardImageTitle,
  ContentHeader,
  Overlay,
  CardContainer,
} from './components'
import {
  Login,
  Signup,
  VerifyEmail,
  ForgotPassword,
  PasswordChangeRequest,
  PasswordChange,
} from './features'

export const LoginRouter: React.FC = () => {
  const { path } = useRouteMatch()
  const { t } = useTranslation(I18NService.NAMESPACES.LOGIN)

  const { userId } = useAuth()

  if (userId) return <Redirect to="/map" />

  return (
    <BackgroundImage>
      <Overlay />
      <CardContainer>
        <Overlay />
        <StyledCard bodyStyle={{ padding: 0, height: '100%' }}>
          <CardContent>
            <CardImage>
              <CardImageTitle>{t('loginPage.heroTitle')}</CardImageTitle>
            </CardImage>
            <ContentContainer>
              <ContentHeader />
              <Switch>
                <Route exact path={path} component={Login} />
                <Route exact path={`${path}/signup`} component={Signup} />
                <Route exact path={`${path}/verify-email`} component={VerifyEmail} />
                <Route exact path={`${path}/forgot-password`} component={ForgotPassword} />
                <Route
                  exact
                  path={`${path}/password-change-request`}
                  component={PasswordChangeRequest}
                />
                <Route exact path={`${path}/password-change`} component={PasswordChange} />
              </Switch>
            </ContentContainer>
          </CardContent>
        </StyledCard>
        <Overlay />
      </CardContainer>
      <Overlay />
    </BackgroundImage>
  )
}
