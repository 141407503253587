import { Row, Col, Typography } from 'antd'
import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ChlorophyllIcon, DropIcon } from 'assets/icons'
import { I18NService } from 'services'
import { usePermissions } from 'hooks'

import { BallColorsTypes } from '../../../types'
import { TrafficLightBall } from '../../../components/TrafficLightBall'

const { Title: AntTitle, Text: AntText } = Typography

const ColContainer = styled(Col)`
  width: 190px;
  flex: 1;
  background: ${({ theme }) => theme.colors.new.baseColor[500]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  &.ant-col {
    height: fit-content !important;
  }

  @media (max-height: 650px) {
    max-height: 500px;
  }

  &.ant-col:first-child {
    padding: 24px !important;
    overflow-y: auto;

    @media (max-height: 650px) {
      padding: 20px !important;
    }
  }
`

const Title = styled(AntTitle)`
  font-weight: 600 !important;
  margin-bottom: 12px !important;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.grey1} !important;
`

const SubTitle = styled(AntText)`
  font-weight: normal !important;
  margin-bottom: 0px !important;
  line-height: 24px;
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.grey1};
`

const CenterCol = styled(Col)`
  display: flex;
  align-items: center;
`

const TrafficLightContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 2px solid rgba(246, 246, 246, 0.1);
  padding: 5px;
  max-width: 55px;
`

const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`

const MarginRow = styled(Row)`
  margin-bottom: 24px;
`

const StickyRow = styled(Row)`
  position: sticky;
  top: 0;
`

interface Props {
  isLotDashboard?: boolean
}
export const InformationCard: React.FC<Props> = ({ isLotDashboard }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.DASHBOARD)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { permissions } = usePermissions()

  const trafficLights = [
    {
      name: t('informationCard.statusOk'),
      balls: [
        <TrafficLightBall type={BallColorsTypes.good} />,
        <TrafficLightBall />,
        <TrafficLightBall />,
      ],
    },
    {
      name: t('informationCard.statusAlert'),
      balls: [
        <TrafficLightBall />,
        <TrafficLightBall type={BallColorsTypes.alert} />,
        <TrafficLightBall />,
      ],
    },
    {
      name: t('informationCard.statusProblem'),
      balls: [
        <TrafficLightBall />,
        <TrafficLightBall />,
        <TrafficLightBall type={BallColorsTypes.danger} />,
      ],
    },
  ]

  return (
    <StickyRow>
      <ColContainer>
        <Title level={5}>{t('informationCard.title')}</Title>
        {!isLotDashboard && (
          <MarginRow>
            <CenterCol span={4}>
              <TrafficLightBall type={BallColorsTypes.field} />
            </CenterCol>
            <Col span={20}>
              <SubTitle>
                {permissions.isFeatureSetGrupoDiana
                  ? t('informationCard.client')
                  : t('informationCard.field')}
              </SubTitle>
            </Col>
            <CenterCol span={4}>
              <TrafficLightBall type={BallColorsTypes.lot} />
            </CenterCol>
            <Col span={20}>
              <SubTitle>{t('informationCard.lot')}</SubTitle>
            </Col>
          </MarginRow>
        )}
        {permissions.satelliteImagery && (
          <>
            <Title level={5}>{t('informationCard.statusTitle')}</Title>
            <MarginRow gutter={[0, 8]}>
              {trafficLights.map(trafficLight => {
                return (
                  <React.Fragment key={trafficLight.name}>
                    <Col span={12}>
                      <TrafficLightContainer>
                        {trafficLight.balls.map((ball, index) => {
                          // eslint-disable-next-line react/no-array-index-key
                          return <React.Fragment key={`ball-${index}`}>{ball}</React.Fragment>
                        })}
                      </TrafficLightContainer>
                    </Col>
                    <CenterCol span={12}>
                      <SubTitle>{trafficLight.name}</SubTitle>
                    </CenterCol>
                  </React.Fragment>
                )
              })}
            </MarginRow>
          </>
        )}
        {!isLotDashboard && (
          <>
            <Title level={5}>{commonT('vocabulary.daysAfterEmergenceAbbreviation')}</Title>
            <MarginRow>
              <Col span={24}>
                <SubTitle>{t('informationCard.daysAfterEmergence')}</SubTitle>
              </Col>
            </MarginRow>
          </>
        )}
        {permissions.satelliteImagery && (
          <>
            <Title level={5}>{t('informationCard.indicatorTitle')}</Title>
            <Row gutter={[0, 10]}>
              <Col span={8}>
                <IconContainer>
                  <DropIcon />
                </IconContainer>
              </Col>
              <CenterCol span={16}>
                <SubTitle>{t('informationCard.waterIndicator')}</SubTitle>
              </CenterCol>
              <Col span={8}>
                <IconContainer>
                  <ChlorophyllIcon />
                </IconContainer>
              </Col>
              <CenterCol span={16}>
                <SubTitle>{t('informationCard.chlorophyllIndicator')}</SubTitle>
              </CenterCol>
            </Row>
          </>
        )}
      </ColContainer>
    </StickyRow>
  )
}
