import React, { useRef, useState, useCallback, useEffect } from 'react'
import { Col, Space, Row } from 'antd'
import ReactMapGL, { MapLayerMouseEvent, Marker } from 'react-map-gl'
import { useHistory, useParams } from 'react-router-dom'
import { Result } from '@mapbox/mapbox-gl-geocoder'
import { useTranslation } from 'react-i18next'

import { config } from 'config'
import { MAP } from 'consts'
import { MapPin, Button } from 'components'
import { ActiveCompanyContext } from 'contexts'
import type { SelectField } from 'components/fields-selects/types'
import { LotsMapLocationState } from 'types'
import { I18NService } from 'services'

import { FieldCard, FieldsSelect, GeocoderControl } from './components'
import { StyledCard, Title, Text, MapSidebar, SearchBoxContainer } from '../../components'

export const FieldsMap: React.FC = () => {
  const { activeCompanyId, activeSeasonId } = ActiveCompanyContext.useActiveCompanyContext()
  const { t } = useTranslation(I18NService.NAMESPACES.FIELD_SETTINGS)

  const [viewport, setViewport] = useState({
    latitude: MAP.DEFAULT_CENTER.LATITUDE,
    longitude: MAP.DEFAULT_CENTER.LONGITUDE,
    zoom: MAP.ZOOM.DEFAULT,
    ...MAP.DEFAULT_TRANSITION,
  })

  const history = useHistory<LotsMapLocationState>()
  const { id } = useParams<{ id?: string }>()

  const [selectedField, setSelectedField] = useState<SelectField>()

  const [markerCoordinates, setMarkerCoordinates] = useState<number[]>([
    MAP.DEFAULT_CENTER.LONGITUDE,
    MAP.DEFAULT_CENTER.LATITUDE,
  ])

  const [isCreateFieldCompleted, setIsCreateFieldCompleted] = useState(false)
  const [fieldName, setFieldName] = useState<string>()
  const [fieldId, setFieldId] = useState<number>()

  useEffect(() => {
    if (selectedField) {
      const [longitude, latitude] = selectedField.location.coordinates
      setMarkerCoordinates(selectedField.location.coordinates)
      setViewport(prevViewport => ({ ...prevViewport, longitude, latitude }))
    }
  }, [selectedField])

  const geocoderContainerRef = useRef<HTMLDivElement>(null)

  const onSuccess = (newFieldName: string, newFieldId?: number) => {
    if (id) {
      history.goBack()
      return
    }

    setFieldName(newFieldName)
    setFieldId(newFieldId)
    setIsCreateFieldCompleted(true)
  }

  const onHandleMarker = (event: MapLayerMouseEvent) => {
    const { lngLat } = event
    setMarkerCoordinates([lngLat.lng, lngLat.lat])
  }

  const handleChange = useCallback((newField: SelectField) => {
    setSelectedField(newField)
  }, [])

  const onMove = useCallback(evt => setViewport(evt.viewState), [])
  const onResult = useCallback(({ result }: { result: Result }) => {
    const location =
      result &&
      (result.center || (result.geometry?.type === 'Point' && result.geometry.coordinates))

    if (location) {
      setMarkerCoordinates([location[0], location[1]])
    }
  }, [])

  return (
    <>
      <MapSidebar>
        <Col flex={1}>
          {isCreateFieldCompleted ? (
            <StyledCard>
              <Title>{fieldName}</Title>
              <Space direction="vertical" size={22}>
                <Text>{t('feedbackCard.text')} </Text>
                <Row justify="end">
                  <Button
                    onClick={() => {
                      setIsCreateFieldCompleted(false)
                      history.replace('/settings/lots', {
                        fieldId: selectedField?.id ?? fieldId,
                        seasonId: activeSeasonId,
                        goTo: `/map?fieldId=${selectedField?.id ?? fieldId}`,
                      })
                    }}
                    type="primary"
                  >
                    {t('feedbackCard.buttonText')}
                  </Button>
                </Row>
              </Space>
            </StyledCard>
          ) : (
            <>
              <SearchBoxContainer ref={geocoderContainerRef} id="geocoder-container" />
              {id && (
                <Row justify="end">
                  <FieldsSelect
                    companyId={activeCompanyId}
                    onChange={handleChange}
                    defaultId={Number(id)}
                  />
                </Row>
              )}
              <FieldCard
                field={selectedField}
                markerCoordinates={markerCoordinates}
                onSuccess={onSuccess}
              />
            </>
          )}
        </Col>
      </MapSidebar>

      <ReactMapGL
        {...viewport}
        onMove={onMove}
        id="fieldsMap"
        mapStyle={MAP.STYLES.SATELLITE_STREET}
        onClick={onHandleMarker}
      >
        {!isCreateFieldCompleted && (
          <GeocoderControl
            geocoderOptions={{
              accessToken: config.mapboxToken,
              language: MAP.LANGUAGES.ES,
              placeholder: t('geocoderPlaceholder'),
            }}
            containerRef={geocoderContainerRef}
            onResult={onResult}
          />
        )}
        <Marker
          latitude={markerCoordinates[1]}
          longitude={markerCoordinates[0]}
          draggable={!isCreateFieldCompleted}
          onDrag={event => {
            const { lngLat } = event
            setMarkerCoordinates([lngLat.lng, lngLat.lat])
          }}
        >
          <MapPin />
        </Marker>
      </ReactMapGL>
    </>
  )
}
