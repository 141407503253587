import { useMutation } from '@apollo/client'

import { AuthContext } from 'contexts'
import { STORAGE_KEYS } from 'consts'
import { useLocalStorage } from 'hooks'

import { LOGIN, Login, LoginVariables } from '../api'

export const useLogin = () => {
  const [, setUserToken] = useLocalStorage(STORAGE_KEYS.USER_TOKEN)

  const { login } = AuthContext.useAuthContext()
  return useMutation<Login, LoginVariables>(LOGIN, {
    onCompleted: data => {
      login({ userId: data.login.user.id })
      setUserToken(data.login.token)
    },
    onError: error => console.log(error),
  })
}
