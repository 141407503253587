/* eslint-disable no-nested-ternary */
import { Col } from 'antd'
import { useTheme } from 'styled-components'
import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { MapContext } from 'features/MainRouter/contexts'

import { MapIndex } from '../../types'
import { BOX_COLORS } from '../../const'
import { Lot } from '../../hooks/useLots/api'
import { Card, BarIndicator, BoxContainer, FlexCol, FlexRow, NoItem } from './components'

interface LevelCardTypes {
  boxBackground: string
  item: ReactElement
  area?: number | undefined
}

interface CardContentTypes {
  irrigation: LevelCardTypes[]
  chlorophyll: LevelCardTypes[]
  estimatedYield: LevelCardTypes[]
  grainHumidity: {
    isGuriVariety: LevelCardTypes[]
    otherVariety: LevelCardTypes[]
  }
}

interface ColorAreaMap {
  [color: string]: number
}

interface Props {
  selectedMapIndex: MapIndex
  variety: 'isGuriVariety' | 'otherVariety'
  lots: Lot[]
}

export const DescriptionCard: React.FC<Props> = ({ selectedMapIndex, variety, lots }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.MAP)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)

  const { colors } = useTheme()

  const { selectedTimeline } = MapContext.useMapContext()

  const colorHistogram = useMemo(() => {
    const lotsSelectedDate = lots.map(
      lot => lot.riceLot.catalog.dates.find(catalog => catalog.date === selectedTimeline)?.mapUrls,
    )
    const areaColorClassification = (indicator: 'irrigation' | 'chlorophyll' | 'grainHumidity') => {
      return lotsSelectedDate.reduce((colorAreaMap: ColorAreaMap, curr) => {
        if (curr) {
          curr[indicator]?.colorHistogram?.forEach((level: { area: number; color: string }) => {
            const color = level.color.toUpperCase()
            // eslint-disable-next-line no-param-reassign
            colorAreaMap[color] = colorAreaMap[color]
              ? // eslint-disable-next-line no-param-reassign
                (colorAreaMap[color] += level.area)
              : // eslint-disable-next-line no-param-reassign
                (colorAreaMap[color] = level.area)
          })
        }
        return colorAreaMap
      }, {})
    }
    return {
      irrigation: areaColorClassification('irrigation'),
      chlorophyll: areaColorClassification('chlorophyll'),
      grainHumidity: areaColorClassification('grainHumidity'),
    }
  }, [selectedTimeline, lots])

  //     TODO: Internationalize estimatedYield
  const cardContent: CardContentTypes = {
    irrigation: [
      {
        boxBackground: BOX_COLORS.IRRIGATION.levelOne,
        item: <>{t('descriptionCard.irrigation.enoughWater')}</>,
        area: colorHistogram.irrigation[BOX_COLORS.IRRIGATION.levelOne] ?? 0,
      },
      {
        boxBackground: BOX_COLORS.IRRIGATION.levelTwo,
        area: colorHistogram.irrigation[BOX_COLORS.IRRIGATION.levelTwo] ?? 0,

        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.IRRIGATION.levelThree,
        area: colorHistogram.irrigation[BOX_COLORS.IRRIGATION.levelThree] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.IRRIGATION.levelFour,
        area: colorHistogram.irrigation[BOX_COLORS.IRRIGATION.levelFour] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.IRRIGATION.levelFive,
        area: colorHistogram.irrigation[BOX_COLORS.IRRIGATION.levelFive] ?? 0,
        item: <>{t('descriptionCard.irrigation.limitedWater')}</>,
      },
    ],

    chlorophyll: [
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelOne,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelOne] ?? 0,
        item: <>{t('descriptionCard.chlorophyll.higherLevel')}</>,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelTwo,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelTwo] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelThree,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelThree] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelFour,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelFour] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelFive,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelFive] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelSix,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelSix] ?? 0,
        item: <NoItem />,
      },
      {
        boxBackground: BOX_COLORS.CHLOROPHYLL.levelSeven,
        area: colorHistogram.chlorophyll[BOX_COLORS.CHLOROPHYLL.levelSeven] ?? 0,
        item: <>{t('descriptionCard.chlorophyll.lowerLevel')}</>,
      },
    ],
    grainHumidity: {
      isGuriVariety: [
        {
          boxBackground: colors.grainHumedityGreen,
          area: colorHistogram.grainHumidity[colors.grainHumedityGreen] ?? 0,
          item: <>{t('descriptionCard.grainHumidity.humid')}</>,
        },
        {
          boxBackground: colors.grainHumedityLightGreen,
          area: colorHistogram.grainHumidity[colors.grainHumedityLightGreen] ?? 0,
          item: <NoItem />,
        },
        {
          boxBackground: colors.grainHumedityYellow,
          area: colorHistogram.grainHumidity[colors.grainHumedityYellow] ?? 0,
          item: <NoItem />,
        },
        {
          boxBackground: colors.grainHumedityOrange,
          area: colorHistogram.grainHumidity[colors.grainHumedityOrange] ?? 0,
          item: <NoItem />,
        },
        {
          boxBackground: colors.grainHumedityLightBrown,
          area: colorHistogram.grainHumidity[colors.grainHumedityLightBrown] ?? 0,
          item: <NoItem />,
        },
        {
          boxBackground: colors.grainHumedityBrown,
          area: colorHistogram.grainHumidity[colors.grainHumedityBrown] ?? 0,
          item: <>{t('descriptionCard.grainHumidity.dry')}</>,
        },
      ],
      otherVariety: [
        {
          boxBackground: colors.grainHumedityGreen,
          area: colorHistogram.grainHumidity[colors.grainHumedityGreen] ?? 0,
          item: <>{t('descriptionCard.otherVariety.humid')}</>,
        },
        {
          boxBackground: colors.grainHumedityLightGreen,
          area: colorHistogram.grainHumidity[colors.grainHumedityLightGreen] ?? 0,
          item: <NoItem />,
        },
        {
          boxBackground: colors.grainHumedityYellow,
          area: colorHistogram.grainHumidity[colors.grainHumedityYellow] ?? 0,
          item: <NoItem />,
        },
        {
          boxBackground: colors.grainHumedityOrange,
          area: colorHistogram.grainHumidity[colors.grainHumedityOrange] ?? 0,
          item: <NoItem />,
        },
        {
          boxBackground: colors.grainHumedityLightBrown,
          area: colorHistogram.grainHumidity[colors.grainHumedityLightBrown] ?? 0,
          item: <NoItem />,
        },
        {
          boxBackground: colors.grainHumedityBrown,
          area: colorHistogram.grainHumidity[colors.grainHumedityBrown] ?? 0,
          item: <>{t('descriptionCard.otherVariety.dry')}</>,
        },
      ],
    },
    estimatedYield: [
      {
        boxBackground: colors.yieldGreen,
        item: <>{t('descriptionCard.estimatedYield.part1')}</>,
      },
      {
        boxBackground: colors.yieldLightGreen,
        item: <NoItem />,
      },
      {
        boxBackground: colors.yieldYellow,
        item: <>{t('descriptionCard.estimatedYield.part2')}</>,
      },
      {
        boxBackground: colors.yieldOrange,
        item: <NoItem />,
      },
      {
        boxBackground: colors.yieldRed,
        item: <>{t('descriptionCard.estimatedYield.part3')}</>,
      },
    ],
  }

  const getSelectedIndexColors = () => {
    if (selectedMapIndex === 'realImage') return ''
    const getStringColors = () => {
      if (selectedMapIndex === 'grainHumidity') {
        return cardContent[selectedMapIndex][variety]
          .map(cardData => cardData.boxBackground.toString())
          .join(',')
      }
      return cardContent[selectedMapIndex]
        .map(cardData => cardData.boxBackground.toString())
        .join(',')
    }
    const selectedMapIndexColors = getStringColors()

    return selectedMapIndexColors
  }

  return (
    <>
      {selectedMapIndex !== 'realImage' && (
        <Card>
          <Col>
            <BarIndicator colorsGradient={getSelectedIndexColors()} />
          </Col>
          <Col span={23}>
            {selectedMapIndex === 'grainHumidity'
              ? cardContent[selectedMapIndex][variety].map(cardData => {
                  return (
                    <React.Fragment key={cardData.boxBackground}>
                      <FlexRow>
                        <BoxContainer span={9}>
                          <FlexCol>{commonT('systemsFormat.area', { val: cardData.area })}</FlexCol>
                        </BoxContainer>
                        <FlexCol span={15}> {cardData.item} </FlexCol>
                      </FlexRow>
                    </React.Fragment>
                  )
                })
              : selectedMapIndex === 'estimatedYield'
              ? cardContent[selectedMapIndex].map(cardData => {
                  return (
                    <React.Fragment key={cardData.boxBackground}>
                      <FlexRow>
                        <FlexCol span={18}> {cardData.item} </FlexCol>
                      </FlexRow>
                    </React.Fragment>
                  )
                })
              : cardContent[selectedMapIndex].map(cardData => {
                  return (
                    <React.Fragment key={cardData.boxBackground}>
                      <FlexRow>
                        <BoxContainer span={9}>
                          <FlexCol>{commonT('systemsFormat.area', { val: cardData.area })}</FlexCol>
                        </BoxContainer>
                        <FlexCol span={15}> {cardData.item} </FlexCol>
                      </FlexRow>
                    </React.Fragment>
                  )
                })}
          </Col>
        </Card>
      )}
    </>
  )
}
