import styled from 'styled-components'
import { Row } from 'antd'

const ContainerRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  height: 300px;
  width: 288px;
  padding: 24px;
`

export const Card: React.FC = ({ children }) => {
  return (
    <ContainerRow align="middle" justify="center">
      {children}
    </ContainerRow>
  )
}
