import { gql } from '@apollo/client'

export type { CreateCompany, CreateCompanyVariables } from './types/CreateCompany'

export const CREATE_COMPANY = gql`
  mutation CreateCompany($companyDTO: CreateCompanyInput!, $founderId: Int!) {
    createCompany(createCompanyDTO: $companyDTO, founderId: $founderId) {
      company {
        id
        name
        logo
        country {
          id
          lng
          code
        }
      }
    }
  }
`
