/* eslint-disable no-nested-ternary */
import { Col, Row, Space } from 'antd'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { ShopOutlined, CheckOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { usePermissions, useUser } from 'hooks'
import { ActiveCompanyContext } from 'contexts'
import { UserCompanyRole } from 'types'
import { EllipsisText } from 'components'
import { I18NService } from 'services'
import { UserUserCompanyRole as UserCompanyRoleType } from 'api/user'
import { MiscUtils } from 'utils'

import {
  List,
  ListItem,
  FullWidthRow,
  Title,
  LogoContainer,
  Image,
  Button,
  Divider,
  Container,
  UserSettings,
  CreateCompany,
  PopoverSearchInput,
  OrganizationItem,
} from './components'

const MAX_COMPANIES_ROLES = 3

interface Props {
  onItemActionClick: (company: { id: number; isAdmin: boolean }) => void
  onCreateCompany: () => void
  setIsFirstCompany: Dispatch<SetStateAction<boolean>>
  onConfigureAccount: () => void
}

export const CompaniesPopoverContent: React.FC<Props> = ({
  onItemActionClick,
  onCreateCompany,
  setIsFirstCompany,
  onConfigureAccount,
}) => {
  const user = useUser()
  const { permissions } = usePermissions()
  const { t } = useTranslation(I18NService.NAMESPACES.NAVBAR)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { colors } = useTheme()

  const [companiesRoles, setCompaniesRoles] = useState<UserCompanyRoleType[]>()

  const { activeCompanyId, setActiveCompany } = ActiveCompanyContext.useActiveCompanyContext()

  useEffect(() => {
    if (user.companiesRoles?.length) {
      setCompaniesRoles(user.companiesRoles)
    }
  }, [user.companiesRoles])

  const onSearchChange = useCallback(
    (value: string) => {
      if (!user.companiesRoles) return

      const inputValue = MiscUtils.removeAccents(value).toLowerCase()
      const filterCompanies = user.companiesRoles?.filter(({ company }) => {
        const companyName = MiscUtils.removeAccents(company.name).toLowerCase()
        return companyName.includes(inputValue)
      })
      setCompaniesRoles(filterCompanies)
    },
    [user.companiesRoles],
  )

  if (!user.companiesRoles?.length) {
    return (
      <>
        <Container>
          <Title level={5}>
            {permissions.isAnOrganizationUser
              ? t('user.menu.organization.noClientTitle', {
                  organizationName: user.organizationRole?.organization.name,
                })
              : t('user.menu.companies.noCompanyTitle')}
          </Title>
        </Container>

        {permissions.companyCreation && (
          <Container style={{ marginTop: '20px' }}>
            <CreateCompany
              onClick={e => {
                setIsFirstCompany(true)
                e.stopPropagation()
                onCreateCompany()
              }}
              isFirstCompany
            >
              {t('user.menu.companies.noCompanyTitle')}
            </CreateCompany>
          </Container>
        )}
        {permissions.isAnOrganizationUser && (
          <>
            <Divider />
            <Container>
              <OrganizationItem
                name={user.organizationRole?.organization.name ?? ''}
                logo={user.organizationRole?.organization.logo}
              />
            </Container>
          </>
        )}
        <Divider />
        <Container>
          <UserSettings
            name={user.name}
            lastName={user.lastName}
            onConfigureAccount={onConfigureAccount}
          />
        </Container>
      </>
    )
  }

  return (
    <>
      {user?.companiesRoles?.length > MAX_COMPANIES_ROLES && (
        <Container style={{ paddingBottom: '20px' }}>
          <PopoverSearchInput onChange={onSearchChange} />
        </Container>
      )}

      <List size="small">
        {companiesRoles?.map(({ company, role }) => {
          const isAnActiveCompany = company.id === activeCompanyId
          return (
            <Container key={company.id}>
              <ListItem
                key={company.id}
                onClick={() => {
                  setActiveCompany({ id: company.id, countryId: company.country.id })
                }}
              >
                <FullWidthRow wrap={false}>
                  <Col flex={1} style={{ height: '48px' }}>
                    <Space size="middle">
                      <LogoContainer $isActive={isAnActiveCompany}>
                        {company.organization?.id ? (
                          isAnActiveCompany && (
                            <CheckOutlined style={{ fontSize: '25px', color: 'white' }} />
                          )
                        ) : company.logo ? (
                          <Image src={company.logo} alt={commonT('misc.logoImageAlt')} />
                        ) : (
                          <ShopOutlined style={{ color: 'white' }} />
                        )}
                      </LogoContainer>
                      <Row>
                        <Col span={24}>
                          <Title
                            level={5}
                            ellipsis={{ rows: 2, tooltip: true }}
                            style={{ width: '148px' }}
                          >
                            {company.name}
                          </Title>
                        </Col>
                        {/* { TODO: Show number of lots again once we query this info } */}
                        {/* <Col>
                          <Text>0 lotes</Text>
                        </Col> */}
                      </Row>
                    </Space>
                  </Col>
                </FullWidthRow>
                <FullWidthRow>
                  <Col>
                    <Button
                      type="link"
                      onClick={e => {
                        e.stopPropagation()
                        onItemActionClick({
                          id: company.id,
                          isAdmin: role === UserCompanyRole.ADMIN,
                        })
                      }}
                    >
                      {t('user.menu.companies.settingsButtonText')}
                      <EllipsisText
                        text={company.name}
                        overlayInnerStyle={{
                          fontWeight: 400,
                          maxWidth: '100%',
                          color: colors.white,
                        }}
                        showTooltip={false}
                      />
                    </Button>
                  </Col>
                </FullWidthRow>
              </ListItem>
            </Container>
          )
        })}
        {permissions.companyCreation && (
          <Container>
            <ListItem>
              <CreateCompany
                onClick={e => {
                  setIsFirstCompany(false)
                  e.stopPropagation()
                  onCreateCompany()
                }}
              />
            </ListItem>
          </Container>
        )}
      </List>
      {permissions.isAnOrganizationUser && (
        <>
          <Divider />
          <Container>
            <OrganizationItem
              name={user.organizationRole?.organization.name ?? ''}
              logo={user.organizationRole?.organization.logo}
            />
          </Container>
        </>
      )}
      <Divider />
      <Container>
        <UserSettings
          name={user.name}
          lastName={user.lastName}
          onConfigureAccount={onConfigureAccount}
        />
      </Container>
    </>
  )
}
