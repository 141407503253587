import React from 'react'
import { Row, Upload as AntUpload, notification } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import ImgCrop from 'antd-img-crop'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'

import { InputTitle } from '../InputTitle'
import { LargeUploadBox, SmallUploadBoxV2, SmallUploadBox } from './components'
import { UploadVariantsType, UploadVariantSizeType } from './types'

const MAX_UPLOAD_SIZE = 4 // In MB

const uploadBoxVariants: UploadVariantsType = {
  v1: {
    small: SmallUploadBox,
    large: LargeUploadBox,
  },
  v2: {
    small: SmallUploadBoxV2,
  },
}

interface Props {
  title?: string
  image?: string
  onChange: (image: string, file: File) => void
  isReadOnly?: boolean
  size?: keyof UploadVariantSizeType
  version?: keyof UploadVariantsType
  allowCrop?: boolean
}

export const Upload: React.FC<Props> = ({
  title,
  image,
  onChange,
  isReadOnly,
  size = 'large',
  allowCrop = false,
  version = 'v1',
}) => {
  const onUploadChange = ({ fileList }: UploadChangeParam<UploadFile>) => {
    const file = fileList[0]
    if (!isValidUpload(file)) return

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      onChange(reader.result as string, file.originFileObj!)
    })
    reader.readAsDataURL(file.originFileObj!)
  }

  const { t } = useTranslation(I18NService.NAMESPACES.COMMON)

  const isValidUpload = (file: UploadFile) => {
    if (!file.size) return false
    const isLowerThanMaxMB = file.size / 1024 / 1024 < MAX_UPLOAD_SIZE
    if (!isLowerThanMaxMB) {
      notification.error({
        message: t('messages.validUpload', { maxUploadSize: MAX_UPLOAD_SIZE }),
      })
    }
    return isLowerThanMaxMB
  }

  const UploadBox = uploadBoxVariants[version][size] ?? SmallUploadBox

  const upload = (
    <AntUpload
      // eslint-disable-next-line i18next/no-literal-string
      name="logo"
      accept="image/*"
      disabled={isReadOnly}
      showUploadList={false}
      maxCount={1}
      beforeUpload={allowCrop ? undefined : () => false}
      onChange={onUploadChange}
    >
      <UploadBox image={image} />
    </AntUpload>
  )

  return (
    <>
      {title && <InputTitle title={title} />}
      <Row>{allowCrop ? <ImgCrop>{upload}</ImgCrop> : upload}</Row>
    </>
  )
}
