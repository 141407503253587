import { ComponentProps } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Row, Col, Button, Popover as AntPopover, Spin, Typography } from 'antd'

import { LeftArrowIcon, RightARrowIcon } from 'assets/icons'
import { theme as globalTheme } from 'styles'

const { Text: AntText } = Typography

export const StyledRow = styled(Row)`
  flex: 1;
  display: flex;
  justify-content: space-around;
`

export const RowContainer = styled(Row)`
  display: flex;
  flex-wrap: nowrap !important;
  flex: 1;
`
export const TooltipRow = styled(Row)`
  flex-wrap: nowrap !important;
`

const PopoverAdapter: React.FC<ComponentProps<typeof AntPopover>> = ({
  className,
  ...restProps
}) => <AntPopover {...restProps} overlayClassName={className} />

export const Popover = styled(PopoverAdapter)`
  .ant-popover-inner-content {
    padding: 10px;
    max-height: 200px;
    overflow-y: scroll;
  }
  .ant-popover-arrow {
    display: none !important;
  }
`
interface ImgProps {
  size: string
}

export const Image = styled.img<ImgProps>`
  background-position: center;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

interface Props {
  status: 'inactive' | 'active' | 'disabled'
}

const colStatus = {
  active: `
  background: ${globalTheme.colors.olive5};
  & span {
    color: ${globalTheme.colors.white};
  font-weight: 700;

  }
  & button {
  background: ${globalTheme.colors.olive5};
}
  `,
  inactive: `
  background: ${globalTheme.colors.white};
  & span {
    color: ${globalTheme.colors.olive};
    cursor: pointer;
  }
  & button {
    background: ${globalTheme.colors.white};

  }`,
  disabled: `
  background: ${globalTheme.colors.olive2};
  & span {
    color: ${globalTheme.colors.grey3}
  }
  & button {
  background: ${globalTheme.colors.olive2};
  
  }
  `,
}
export const StyledCol = styled(Col)<Props>`
  ${({ status }) => colStatus[status]}
  border: ${({ theme }) => `5px solid ${theme.colors.olive}`};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  min-width: 60px !important;
  height: 40px !important;
  display: flex;
  justify-content: center !important;
  align-items: center;
  & span {
    font-size: 12px !important;
  }
  & .ant-btn {
    padding: 0 !important;
    height: 24px !important;
    cursor: auto !important;
  }
`
// TODO: Look for a better way to make the divider, so as not to render a div, perhaps by applying pseudo-selector after
export const Divider = styled(Col)`
  width: 100%;
  height: 3px;
  background: ${({ theme }) => theme.colors.olive};
`
export const IconContainer = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  min-width: 40px !important;
  max-height: 40px !important;
  border: ${({ theme }) => `5px solid ${theme.colors.olive}`};
  background: ${({ theme }) => theme.colors.white};
  padding: 0 !important;
  &:hover,
  :focus {
    border-color: ${({ theme }) => theme.colors.olive} !important;
  }
`

const textProps = `
font-weight: normal;
font-size: 13px;
line-height: 24px;
`

export const Text = styled(AntText)`
  ${textProps}
  color: ${({ theme }) => theme.colors.olive};
  width: 100px;
`

export const PaddingText = styled(AntText)`
  ${textProps}
  color: ${({ theme }) => theme.colors.olive};
  padding-left: 10px;
`

export const DividerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export const LoadingData = () => (
  <StyledRow>
    <RowContainer>
      <IconContainer>
        <LeftArrowIcon />
      </IconContainer>
      <DividerContainer>
        <Divider flex={1} />
      </DividerContainer>
      <IconContainer>
        <Spin indicator={SpinIcon} />
      </IconContainer>
      <DividerContainer>
        <Divider flex={1} />
      </DividerContainer>
      <IconContainer>
        <RightARrowIcon />
      </IconContainer>
    </RowContainer>
  </StyledRow>
)
