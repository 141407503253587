import accountSettingsEnLocales from './en/account-settings.json'
import accountSettingsEsLocales from './es/account-settings.json'
import commonEnLocales from './en/common.json'
import commonEsLocales from './es/common.json'
import companySettingsEnLocales from './en/company-settings.json'
import companySettingsEsLocales from './es/company-settings.json'
import dashboardEnLocales from './en/dashboard.json'
import dashboardEsLocales from './es/dashboard.json'
import fieldSettingsEnLocales from './en/field-settings.json'
import fieldSettingsEsLocales from './es/field-settings.json'
import loginEnLocales from './en/login.json'
import loginEsLocales from './es/login.json'
import lotDashboardEnLocales from './en/lot-dashboard.json'
import lotDashboardEsLocales from './es/lot-dashboard.json'
import lotSettingsEnLocales from './en/lot-settings.json'
import lotSettingsEsLocales from './es/lot-settings.json'
import mapEnLocales from './en/map.json'
import mapEsLocales from './es/map.json'
import navbarEnLocales from './en/navbar.json'
import navbarEsLocales from './es/navbar.json'
import seasonSettingsEnLocales from './en/season-settings.json'
import seasonSettingsEsLocales from './es/season-settings.json'
import settingsEnLocales from './en/settings.json'
import settingsEsLocales from './es/settings.json'
import internalMetricsEnLocales from './en/internal-metrics.json'
import internalMetricsEsLocales from './es/internal-metrics.json'
import onboardingEsLocales from './es/onboarding.json'
import onboardingEnLocales from './en/onboarding.json'

export const Locales = {
  accountSettingsEnLocales,
  accountSettingsEsLocales,
  commonEnLocales,
  commonEsLocales,
  companySettingsEnLocales,
  companySettingsEsLocales,
  dashboardEnLocales,
  dashboardEsLocales,
  fieldSettingsEnLocales,
  fieldSettingsEsLocales,
  loginEnLocales,
  loginEsLocales,
  lotDashboardEnLocales,
  lotDashboardEsLocales,
  lotSettingsEnLocales,
  lotSettingsEsLocales,
  mapEnLocales,
  mapEsLocales,
  navbarEnLocales,
  navbarEsLocales,
  seasonSettingsEnLocales,
  seasonSettingsEsLocales,
  settingsEnLocales,
  settingsEsLocales,
  internalMetricsEnLocales,
  internalMetricsEsLocales,
  onboardingEsLocales,
  onboardingEnLocales,
}
