import { gql } from '@apollo/client'

export type { CreateField, CreateFieldVariables } from './types/CreateField'
export type { UpdateField, UpdateFieldVariables } from './types/UpdateField'

export const CREATE_FIELD = gql`
  mutation CreateField($createFieldDTO: CreateFieldInput!) {
    createField(createFieldDTO: $createFieldDTO) {
      field {
        id
        name
      }
    }
  }
`

export const UPDATE_FIELD = gql`
  mutation UpdateField($id: Int!, $updateFieldDTO: UpdateFieldInput!) {
    updateField(id: $id, updateFieldDTO: $updateFieldDTO) {
      field {
        id
        name
        directions
        location {
          coordinates
        }
      }
    }
  }
`
