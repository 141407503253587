import { memo } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { useTheme } from 'styled-components'
import { parseISO, addDays } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'

import { CustomTooltip } from '../CustomTooltip'

type Values = {
  daysSinceEmergence: number
  chlorophyll: number
}
interface Props {
  targetYield?: number | null
  yieldName?: string
  targetYieldCurveValues?: Values[]
  emergenceDate?: string
  realYieldCurveValues?: Values[]
}

export const Chart: React.FC<Props> = memo(
  ({ yieldName, targetYieldCurveValues, realYieldCurveValues, emergenceDate }) => {
    const { colors } = useTheme()
    const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)

    const maxEmergenceDays = Math.max(
      ...(targetYieldCurveValues
        ? targetYieldCurveValues.map(({ daysSinceEmergence }) => daysSinceEmergence)
        : []),
    )

    const realYieldCurveDatafiltered = realYieldCurveValues?.filter(
      ({ daysSinceEmergence }) => daysSinceEmergence <= maxEmergenceDays,
    )
    const realYieldCurveData = realYieldCurveDatafiltered?.map(realYieldCurveValue => {
      return {
        real: realYieldCurveValue.chlorophyll.toFixed(2),
        daysSinceEmergence: realYieldCurveValue.daysSinceEmergence,
        date:
          emergenceDate && addDays(parseISO(emergenceDate), realYieldCurveValue.daysSinceEmergence),
      }
    })

    const data = [...(targetYieldCurveValues ?? []), ...(realYieldCurveData ?? [])].sort(
      (yieldValueA, yieldValueB) => yieldValueA.daysSinceEmergence - yieldValueB.daysSinceEmergence,
    )

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 10,
            bottom: 5,
          }}
        >
          <CartesianGrid />
          <XAxis
            dataKey="daysSinceEmergence"
            label={{
              value: t('targetYieldCard.chart.xAxis'),
              position: 'insideBottomRight',
              offset: -5,
            }}
            type="number"
            domain={['dataMin', 'dataMax']}
            ticks={targetYieldCurveValues?.map(
              targetYieldCurveValue => targetYieldCurveValue.daysSinceEmergence,
            )}
            interval="preserveStart"
          />

          <YAxis
            label={{ value: t('targetYieldCard.chart.yAxis'), angle: -90, position: 'center' }}
          />
          <Tooltip
            content={({ payload, active, label }) => (
              <CustomTooltip payload={payload} active={active} label={label} />
            )}
          />
          <Legend
            payload={[
              {
                value: t('targetYieldCard.chart.targetLegend', {
                  context: !yieldName && 'noYield',
                  value: yieldName,
                }),
                type: 'plainline',
                id: 'ID01',
                color: colors.primary,
                payload: { strokeDasharray: '0 0' },
              },
              {
                value: t('targetYieldCard.chart.realLegend'),
                type: 'line',
                id: 'ID02',
                color: colors.olive,
                payload: { strokeDasharray: '0 0' },
              },
            ]}
          />
          <Line
            type="monotone"
            dot={false}
            activeDot={false}
            dataKey="chlorophyll"
            connectNulls
            stroke={colors.primary}
            strokeWidth={3}
          />
          {!!emergenceDate && (
            <Line
              type="monotone"
              dataKey="real"
              connectNulls
              stroke={colors.olive}
              strokeWidth={3}
              dot={{ r: 4, fill: colors.olive, strokeWidth: 2 }}
              activeDot={{ r: 4, fill: colors.olive, strokeWidth: 1 }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    )
  },
)
