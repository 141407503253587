import React from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'

const ViewerUserTooltip: React.FC = ({ children }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMMON)

  return (
    <Tooltip title={t('messages.readOnlyAccess')} placement="top">
      {children}
    </Tooltip>
  )
}

export { ViewerUserTooltip }
