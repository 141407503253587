import { Space, Col, Row } from 'antd'
import styled from 'styled-components'

import { Layout as BaseLayout } from 'components'

export { GoBackButton } from './GoBackButton'
export { EmergenceDateCard } from './EmergenceDateCard'
export { IrrigationMonitorCard } from './IrrigationMonitorCard'
export { TrafficLight } from './TrafficLight'
export { TargetYield } from './TargetYield'
export { Timeline } from './Timeline'
export { LastUpdateCard } from './LastUpdateCard'
export { Card } from './Card'
export { PhytosanitaryTable } from './PhytosanitaryTable'
export { FertilizerTable } from './FertilizerTable'
export { PendingEmergenceDateTooltip } from './PendingEmergenceDateTooltip'
export { Comment } from './Comment'
export { GrainHumidity } from './GrainHumidity'
export { Yield } from './Yield'

export const Layout = styled(BaseLayout)`
  display: flex;
  flex-direction: column;
  > div:first-child {
    width: 1129px;
  }
  @media (min-width: 1360px) {
    align-items: center;
  }
`

export const ContainerCol = styled(Col)`
  height: 100%;
`

export const StyledCol = styled(Col)`
  overflow: hidden;
`

export const Container = styled(Space).attrs({
  direction: 'vertical',
  size: 24,
})`
  width: fit-content;
  max-width: 1129px;
  padding-bottom: 50px;

  @media (max-height: 900px) {
    padding-bottom: 25px;
  }
`

export const TablesSpace = styled(Space).attrs({
  direction: 'vertical',
  size: 24,
})`
  height: 100%;
  > .ant-space-item {
    flex: 1;
  }
`
export const RowContainer = styled(Row)`
  height: 100%;
`

export const YieldCardContainer = styled(Col)`
  height: 210px;
`

export const CommentsCardContainer = styled(Col)`
  height: calc(100% - 234px);
`
