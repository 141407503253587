import { Col, Row } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { calendarImage } from 'assets/images'
import { useSeason } from 'hooks'

import { Layout } from '../../components'
import { SeasonForm, SeasonLots } from './components'

export const Season: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { t } = useTranslation(I18NService.NAMESPACES.SEASON_SETTINGS)

  if (!id) history.goBack()

  const { data } = useSeason(Number(id))

  if (!data?.season) return null

  return (
    <Layout headerTitle={`${t('title')} ${data.season.name}`} headerImage={calendarImage}>
      <Row gutter={30} wrap={false}>
        <Col flex={1}>
          <SeasonForm season={data.season} />
        </Col>
        <Col flex={4}>
          <SeasonLots season={data.season} />
        </Col>
      </Row>
    </Layout>
  )
}
