import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { CreateClientForm } from 'features/MainRouter/components/create-company-modals/CreateClientModal/components'
import { useSubregions, useUser } from 'hooks'
import { UpdateCompany, UpdateCompanyVariables, UPDATE_COMPANY } from 'api'
import { ConversionUtils, GraphQLUtils } from 'utils'
import { I18NService } from 'services'

interface FormType {
  companyName: string
  country: string
  governmentId?: string
  subregion: number
}

interface Props {
  companyId: number
  data: {
    companyName?: string
    country: string
    governmentId?: string
    selectedSubregionId?: number
  }
}

export const UpdateClientForm: React.FC<Props> = ({ companyId, data }) => {
  const user = useUser()
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const [updateCompany] = useMutation<UpdateCompany, UpdateCompanyVariables>(UPDATE_COMPANY, {
    onError: GraphQLUtils.errorHandler,
  })

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(commonT('validations.required')),
    governmentId: yup.string().required(commonT('validations.required')),
  })

  const { control, handleSubmit, reset } = useForm<FormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      companyName: data.companyName,
      country: data.country,
      governmentId: data.governmentId,
      subregion: data.selectedSubregionId,
    },
  })

  const { subregions } = useSubregions(user.regions?.length ? user.regions?.[0].id : undefined)

  const subregionOptions = subregions?.map(subregion => {
    return { label: subregion.name, value: subregion.id }
  })

  useEffect(() => {
    reset({
      companyName: data.companyName,
      country: data.country,
      governmentId: data.governmentId ?? undefined,
      subregion: data.selectedSubregionId ?? undefined,
    })
  }, [reset, data])

  return (
    <CreateClientForm
      control={control}
      selectedSubregionId={data.selectedSubregionId ?? undefined}
      organizationCountry={user.organizationRole?.organization.country.id ?? ''}
      commercialExecutiveRegion={user.regions?.length ? user.regions?.[0].name : ''}
      subregionOptions={subregionOptions ?? []}
      onInputNameBlur={handleSubmit(({ companyName }) => {
        updateCompany({ variables: { id: companyId, updateCompanyDTO: { name: companyName } } })
      })}
      onInputGovernmentIdBlur={handleSubmit(({ governmentId }) => {
        updateCompany({
          variables: {
            id: companyId,
            updateCompanyDTO: {
              governmentId: governmentId
                ? ConversionUtils.keepOnlyNumbers(governmentId)
                : undefined,
            },
          },
        })
      })}
      onSelectSubregionBlur={handleSubmit(({ subregion }) => {
        updateCompany({
          variables: { id: companyId, updateCompanyDTO: { subregionId: subregion } },
        })
      })}
    />
  )
}
