/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  CompanySeasons,
  CompanySeasonsVariables,
  CompanySeasons_seasons_results_seasonFields as SeasonField,
} from './types/CompanySeasons'

export const COMPANY_SEASONS = gql`
  query CompanySeasons($companyId: Int!) {
    seasons(companyId: $companyId) {
      results {
        id
        name
        startDate
        seasonFields {
          lots {
            id
          }
          field {
            id
            name
          }
        }
      }
    }
  }
`

export type {
  Season as SeasonData,
  SeasonVariables,
  Season_season as Season,
  Season_season_seasonFields_lots as SeasonLot,
  Season_season_seasonFields_field_weather_days as WeatherDay,
} from './types/Season'

export const SEASON = gql`
  query Season(
    $id: Int!
    $fromDate: DateTime!
    $isSatelliteImageryFlagOn: Boolean!
    $includeWeather: Boolean!
  ) {
    season(id: $id) {
      id
      name
      startDate
      endDate
      description
      productionToMill
      millDeliveryType
      seasonFields {
        lots {
          id
          name
          size
          commercialName
          riceLot {
            id
            emergenceDate
            isPlanetEnabled
            yield
            indicators @include(if: $isSatelliteImageryFlagOn) {
              irrigation
              chlorophyll
              semaphore
            }
            estimatedYield {
              value
            }
          }
          location {
            coordinates
          }
          area {
            coordinates
          }
          variety {
            locales {
              es
              en
            }
            id
          }
        }
        field {
          id
          name
          directions
          location {
            type
            coordinates
          }
          weather(fromDate: $fromDate) @include(if: $includeWeather) {
            days {
              dateTime
              tempMax
              tempMin
              precipProb
              cloudCover
            }
          }
        }
      }
      updatedAt
    }
  }
`

export type { UpdateSeason, UpdateSeasonVariables } from './types/UpdateSeason'

export const UPDATE_SEASON = gql`
  mutation UpdateSeason($id: Int!, $updateSeasonDTO: UpdateSeasonInput!) {
    updateSeason(id: $id, updateSeasonDTO: $updateSeasonDTO) {
      season {
        id
        name
        startDate
        endDate
        description
        updatedAt
        productionToMill
        millDeliveryType
      }
    }
  }
`
