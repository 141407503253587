import { Col, Row } from 'antd'
import styled from 'styled-components'

export const FlexRow = styled(Row)`
  flex: 1;
  height: 22px;
`

export const NoItem = styled.div`
  width: 4px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.new.baseColor[400]};
`

export const FlexCol = styled(Col)`
  display: flex;
  align-items: center;
  padding-left: 12px;
  font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.new.baseColor[400]};
`

interface BoxContainerProps {
  justify?: string
}

export const BoxContainer = styled(FlexCol)<BoxContainerProps>`
  padding-right: 12px;
  justify-content: ${({ justify }) => justify ?? 'flex-end'};
  border-right: ${({ theme }) => `2px solid ${theme.colors.new.baseColor[400]}`};
`
export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]};
  padding: 20px 16px;
  display: flex;
`
interface BarIndicatorProps {
  colorsGradient: string
}
export const BarIndicator = styled.div<BarIndicatorProps>`
  background: ${({ colorsGradient }) => `linear-gradient(180deg, ${colorsGradient})`};
  width: 6px;
  height: 100%;
  border-radius: 20px;
`
