import { EmptyResults, OrganizationEmptyResults } from './components'

interface Props {
  noFields?: boolean
  isOrganizationUser: boolean
  isCommercialUser: boolean
  isAdminUser: boolean
}
export const EmptyResultsCard: React.FC<Props> = ({
  noFields,
  isOrganizationUser,
  isCommercialUser,
  isAdminUser,
}) => {
  return isOrganizationUser ? (
    <OrganizationEmptyResults isCommercialUser={isCommercialUser} />
  ) : (
    <EmptyResults noFields={noFields} isAdminUser={isAdminUser} />
  )
}
