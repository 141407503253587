import { StoreObject, useMutation, useQuery } from '@apollo/client'
import { Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Button } from 'components'
import { GraphQLUtils } from 'utils'
import { useUser } from 'hooks'
import { UserCompanyRole } from 'types'
import { UserUserCompanyRole } from 'api/user'
import { ActiveCompanyContext } from 'contexts'
import { I18NService } from 'services'

import {
  COMPANY_WITH_USERS,
  CompanyWithUsers,
  CompanyWithUsersVariables,
  LEAVE_COMPANY,
  LeaveCompany,
  LeaveCompanyVariables,
  ChangeUserRole,
  ChangeUserRoleVariables,
  CHANGE_USER_ROLE,
  InviteUser,
  InviteUserVariables,
  INVITE_USER,
} from './api'
import {
  CompanyUsersTable,
  ButtonsContainer,
  UsersSectionTitle,
  InviteUserModal,
  LeaveCompanyModal,
  StyledButton,
  UpdateClientForm,
  UpdateCompanyForm,
} from './components'

const { Title } = Typography
interface Props {
  id: number
  onCancel: () => void
}

export const CompanySettingsModal: React.FC<Props> = ({ id, onCancel }) => {
  const [showInviteUserModal, setShowInviteUserModal] = useState(false)
  const [showLeaveCompanyModal, setShowLeaveCompanyModal] = useState(false)
  const { setActiveCompany } = ActiveCompanyContext.useActiveCompanyContext()
  const { t } = useTranslation(I18NService.NAMESPACES.COMPANY_SETTINGS)

  const user = useUser()

  const { data } = useQuery<CompanyWithUsers, CompanyWithUsersVariables>(COMPANY_WITH_USERS, {
    variables: { id },
  })

  const [leaveCompany, { loading }] = useMutation<LeaveCompany, LeaveCompanyVariables>(
    LEAVE_COMPANY,
    {
      onCompleted: leaveCompanyResponse => {
        setShowLeaveCompanyModal(false)
        onCancel()
        const newActiveCompanyId =
          leaveCompanyResponse.leaveCompany.user.companiesRoles?.length &&
          leaveCompanyResponse.leaveCompany.user.companiesRoles[0].company.id
        const newActiveCountryId = leaveCompanyResponse.leaveCompany.user.companiesRoles?.length
          ? leaveCompanyResponse.leaveCompany.user.companiesRoles[0].company.country.id
          : undefined
        setActiveCompany({ id: newActiveCompanyId, countryId: newActiveCountryId })
      },
      onError: GraphQLUtils.errorHandler,
    },
  )

  const [inviteUser, { loading: inviteUserLoading }] = useMutation<InviteUser, InviteUserVariables>(
    INVITE_USER,
    {
      onError: GraphQLUtils.errorHandler,
    },
  )

  const [changeUserRole] = useMutation<ChangeUserRole, ChangeUserRoleVariables>(CHANGE_USER_ROLE, {
    update: (cache, { data: response }) => {
      if (!response) return
      const newUserRole = response.changeUserRole.company.usersRoles?.find(
        userRole => userRole.user.id === user.id,
      )
      if (!newUserRole) return

      cache.modify({
        // Workaraound because of this: https://github.com/apollographql/apollo-client/issues/7577
        id: cache.identify(user as unknown as StoreObject),
        fields: {
          companiesRoles(cachedCompaniesRoles?: UserUserCompanyRole[]) {
            const newCompanyRole = {
              role: newUserRole.role,
              company: response.changeUserRole.company,
              __typename: 'CompanyRole',
            }
            if (!cachedCompaniesRoles) return [newCompanyRole]
            return [...cachedCompaniesRoles, newCompanyRole]
          },
        },
      })
    },
    onError: GraphQLUtils.errorHandler,
  })

  const onUserRoleChange = (userId: number, role: UserCompanyRole) => {
    changeUserRole({ variables: { userId, role, companyId: id } })
  }

  const isReadOnly =
    (data?.company.usersRoles?.find(userRole => userRole.user.id === user.id)?.role ??
      UserCompanyRole.VIEWER) === UserCompanyRole.VIEWER

  const isAnOrganizationCompany = !!data?.company?.organization?.id

  const translationSettingsType = isAnOrganizationCompany ? 'editClient' : 'editCompany'

  return (
    <Modal
      title={t(`${translationSettingsType}.modalTitle`)}
      visible
      centered
      footer={null}
      onCancel={onCancel}
    >
      <Title level={5}>{t(`${translationSettingsType}.title`)}</Title>
      {isAnOrganizationCompany ? (
        <UpdateClientForm
          companyId={id}
          data={{
            companyName: data?.company.name,
            country: user.organizationRole?.organization.country.id ?? '',
            governmentId: data?.company.governmentId ?? undefined,
            selectedSubregionId: data?.company.subregionId ?? undefined,
          }}
        />
      ) : (
        <UpdateCompanyForm
          companyId={id}
          data={{
            companyName: data?.company.name,
            countryId: data?.company.country.id,
            logo: data?.company.logo ?? undefined,
          }}
          isReadOnly={isReadOnly}
        />
      )}
      <UsersSectionTitle level={5}>
        {t(`${translationSettingsType}.userSectionTitle`)}
      </UsersSectionTitle>
      <ButtonsContainer>
        <StyledButton
          type="primary"
          loading={inviteUserLoading}
          disabled={isReadOnly}
          onClick={() => setShowInviteUserModal(true)}
        >
          {t('buttons.addMember')}
        </StyledButton>
        <Button onClick={() => setShowLeaveCompanyModal(true)}>{t('buttons.leaveCompany')}</Button>
      </ButtonsContainer>
      <CompanyUsersTable
        users={data?.company.usersRoles ?? []}
        invitations={data?.company.invitations ?? []}
        isReadOnly={isReadOnly}
        onUserRoleChange={onUserRoleChange}
        currentUserId={user.id}
      />
      {showInviteUserModal && (
        <InviteUserModal
          onInvite={(email, role) => {
            setShowInviteUserModal(false)
            inviteUser({ variables: { inviteUserDTO: { email, companyId: id, role } } })
          }}
          onCancel={() => setShowInviteUserModal(false)}
          isAnOrganizationCompany={isAnOrganizationCompany}
        />
      )}
      {showLeaveCompanyModal && (
        <LeaveCompanyModal
          onLeaveCompany={() => leaveCompany({ variables: { companyId: id, userId: user.id } })}
          onCancel={() => setShowLeaveCompanyModal(false)}
          loading={loading}
          isAnOrganizationCompany={isAnOrganizationCompany}
        />
      )}
    </Modal>
  )
}
