import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmationModal } from 'components'
import { I18NService } from 'services'

type Props = Omit<ComponentProps<typeof ConfirmationModal>, 'description'> & {
  lotName: string
  fieldName: string
}

export const DeleteLotConfirmationModal: React.FC<Props> = ({
  lotName,
  fieldName,
  ...restProps
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_SETTINGS)

  return (
    <ConfirmationModal
      description={t('deleteConfirmationText', {
        lotName,
        fieldName,
      })}
      {...restProps}
    />
  )
}
