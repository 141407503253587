import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { UpdateRiceLot, UpdateRiceLotVariables, UPDATE_RICE_LOT } from '../api'

export const useUpdateRiceLot = () => {
  const [updateRiceLotMutation] = useMutation<UpdateRiceLot, UpdateRiceLotVariables>(
    UPDATE_RICE_LOT,
    {
      onError: GraphQLUtils.errorHandler,
    },
  )

  return { updateRiceLotMutation }
}
