import { Select as AntSelect } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { SeasonMillDeliveryType } from 'types'

const Select = styled(AntSelect)`
  width: 100%;
  height: 38px;
  color: ${({ theme }) => theme.colors.new.baseColor[500]};
` as typeof AntSelect

interface Props {
  value?: SeasonMillDeliveryType
  onChange: (value: SeasonMillDeliveryType) => void
}

export const DeliverySelect: React.FC<Props> = ({ onChange, value }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.DASHBOARD)

  const data = [
    { label: t('deliverySelect.total'), value: SeasonMillDeliveryType.TOTAL },
    { label: t('deliverySelect.partial'), value: SeasonMillDeliveryType.PARTIAL },
    { label: t('deliverySelect.noDelivery'), value: SeasonMillDeliveryType.NO_DELIVERY },
  ]

  return (
    <Select
      value={value}
      options={data}
      onChange={onChange}
      placeholder={t('deliverySelect.placeholder')}
    />
  )
}
