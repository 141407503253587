import { Dropdown } from 'antd'

import { PolygonIcon, CompareImageIcon, ToolPinIcon } from 'assets/icons'
import { useIsProdEnv } from 'hooks'

import { IconContainer, ToolbarContainer, MeasureAreaDropdown } from './components'
import { DrawingOption, ToolMode } from '../../types'

interface Props {
  mode: ToolMode
  onHandleCompareImages: () => void
  drawingOption: DrawingOption
  showDrawingToolDropdown: boolean
  onDrawingToolDropdownVisibleChange: () => void
  onDrawingOptionSelected: (option: DrawingOption) => void
  isDrawingToolAvailable: boolean
}

export const Toolbar: React.FC<Props> = ({
  onHandleCompareImages,
  drawingOption,
  onDrawingOptionSelected,
  showDrawingToolDropdown,
  onDrawingToolDropdownVisibleChange,
  mode,
  isDrawingToolAvailable,
}) => {
  const isProdEnv = useIsProdEnv()

  return (
    <ToolbarContainer>
      <Dropdown
        overlay={
          <MeasureAreaDropdown
            selectedItem={drawingOption}
            onDrawingOptionSelected={onDrawingOptionSelected}
            isDrawingToolAvailable={isDrawingToolAvailable}
          />
        }
        trigger={['click']}
        onVisibleChange={onDrawingToolDropdownVisibleChange}
        visible={showDrawingToolDropdown}
      >
        <IconContainer
          $isActive={mode === 'draw' || drawingOption === 'hide'}
          onClick={() => onDrawingOptionSelected('reset')}
        >
          <PolygonIcon />
        </IconContainer>
      </Dropdown>

      <IconContainer onClick={onHandleCompareImages} $isActive={mode === 'compare'}>
        <CompareImageIcon />
      </IconContainer>

      {!isProdEnv && (
        <IconContainer>
          <ToolPinIcon />
        </IconContainer>
      )}
    </ToolbarContainer>
  )
}
