import { PlusOutlined } from '@ant-design/icons'
import { Row, Typography, Space as AntSpace, Col, Divider as AntDivider } from 'antd'
import styled from 'styled-components'

const { Title: AntTitle, Text } = Typography

const RowContainer = styled(Row)`
  background: ${({ theme }) => theme.colors.grey1};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  padding: 24px;
  height: 100%;
`

const Title = styled(AntTitle)`
  color: ${({ theme }) => theme.colors.olive5};
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 0 !important;
`
const Space = styled(AntSpace)`
  width: 100%;
`

const IconContainer = styled(Col)`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.grey2};
  overflow: hidden;
  cursor: pointer;
`
const StyledPlusCircle = styled(PlusOutlined)`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.white};
`

const Divider = styled(AntDivider)`
  height: 24px !important;
  border-left: ${({ theme }) => `2px solid ${theme.colors.grey2} !important `};
  width: 2px !important;
  margin: 0;
`

const SubTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.olive};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
`
interface Props {
  title: string
  subTitle?: string
  onCreate?: () => void
}
export const Card: React.FC<Props> = ({ title, children, onCreate, subTitle }) => {
  return (
    <RowContainer>
      <Space direction="vertical" size={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <Space size={12}>
              <Title level={5}> {title} </Title>
              {subTitle && (
                <>
                  <Divider type="vertical" />

                  <SubTitle> {subTitle} </SubTitle>
                </>
              )}
            </Space>
          </Col>

          {onCreate && (
            <IconContainer onClick={onCreate}>
              <StyledPlusCircle />
            </IconContainer>
          )}
        </Row>
        {children}
      </Space>
    </RowContainer>
  )
}
