/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import ReactMapGL, { Source, Layer, useMap as useReactMapGl, Marker } from 'react-map-gl'
import { MapLayerMouseEvent } from 'mapbox-gl'
import { parseInt as toInt } from 'lodash'
import { useTheme } from 'styled-components'
import bbox from '@turf/bbox'
import center from '@turf/center'
import centroid from '@turf/centroid'
import { points, polygon } from '@turf/helpers'
import { differenceInDays } from 'date-fns'
import MapboxCompare from 'mapbox-gl-compare'
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useHistory } from 'react-router-dom'

import { MAP } from 'consts'
import type { SelectField } from 'components/fields-selects/types'
import { ActiveCompanyContext } from 'contexts'
import { useHasCompanyOrFieldsOrLots, useQueryParams } from 'hooks'
import { config } from 'config'
import { I18NService } from 'services'
import { Language } from 'types/i18n'
import { SentinelHubProvider } from 'types'
import { Header } from 'components'

import { GeoUtils } from './utils'
import {
  SideBar,
  SeasonFieldsSelect,
  LotsSelect,
  BottomBar,
  Timeline,
  LotCard,
  DatesLoader,
  StyledCol,
  DescriptionCard,
  Toolbar,
  PopupCard,
  SatelliteInformationSelect,
  CompareTimeline,
  CompareTimelineContainer,
  CompareImagesMap,
  Control,
  TooltipTextContainer,
  TooltipText,
  MapContainer,
  MainContainer,
  Container,
} from './components'
import { DateState, PopupInfo } from './types'
import { useMap, useDrawingTool, useTools } from './hooks'

// TODO: Look for a better way to make the components Map, LotsSelect and TimeLine. For example, now we have states storing the same,
// like lots in Map and LotsSelect or data we can extract from other state and use useMemo to see if it changes like imgUrls in Map.
// The idea for the future is to have one component Map where we have all logic, and two dummy components only to render data.
const VARIETY_GURI_NAME = 'guriIntaCl'

export const Map: React.FC = () => {
  const history = useHistory()
  const { noLots, noFields, loading } = useHasCompanyOrFieldsOrLots()
  useEffect(() => {
    if (loading) return
    if (noFields || noLots) history.replace('/dashboard')
  }, [history, loading, noFields, noLots])

  const today = new Date('2022-03-20')
  const aMonthAgo = new Date('2022-03-20')
  const firstFromTime = new Date(aMonthAgo.setMonth(aMonthAgo.getMonth() - 1))
  const { activeSeasonId } = ActiveCompanyContext.useActiveCompanyContext()
  const { colors } = useTheme()
  const { map, compareMap } = useReactMapGl()
  const [cursor, setCursor] = useState<string>('grab')

  const [field, setField] = useState<SelectField>()
  const [dates, setDates] = useState<DateState>({
    fromTime: firstFromTime,
    toTime: today,
    newDates: false,
  })

  const { setToolMode, toolMode } = useTools()
  const { t } = useTranslation(I18NService.NAMESPACES.COMMON)

  const {
    selectedMapIndex,
    setSelectedMapIndex,
    isGrainHumidityNotAvailable,
    isEstimatedYieldNotAvailable,
    isEstimatedYieldIndexSelected,
    allSelectedLotsArePlanet,
    selectedCompareMapIndex,
    setSelectedCompareMapIndex,
    setSelectedTimeline,
    setSelectedCompareTimeline,
    selectedTimeline,
    selectedCompareTimeline,
    setCompareMapData,
    compareLots,
    lots,
    isLotsLoading,
    isCompareModeSelected,
    isEstimatedYieldCompareMapIndexSelected,
    selectedLots,
    setSelectedLots,
    error,
    allSelectedCompareLotsArePlanet,
  } = useMap({
    seasonId: activeSeasonId,
    fromTime: dates.fromTime,
    toTime: dates.toTime,
    fieldId: field?.id,
    toolMode,
  })
  const query = useQueryParams()
  const defaultFieldId = toInt(query.get('fieldId') ?? '')
  const defaultLotId = toInt(query.get('lotId') ?? '')

  const [viewport, setViewport] = useState({
    latitude: MAP.DEFAULT_CENTER.LATITUDE,
    longitude: MAP.DEFAULT_CENTER.LONGITUDE,
    zoom: MAP.ZOOM.DEFAULT,
  })

  const [popupInfo, setPopupInfo] = useState<PopupInfo>()

  const onChangeLotTransition = useCallback(
    ({ longitude, latitude, zoom }) => {
      map.flyTo({
        center: [longitude, latitude],
        duration: MAP.DEFAULT_TRANSITION.transitionDuration,
        zoom,
        essential: true,
      })
    },
    [map],
  )
  useEffect(() => {
    if (selectedLots.length) {
      const locationsCoordinates = selectedLots.map(lot => lot.location.coordinates)
      const features = points(locationsCoordinates)
      const lotsCenter = center(features)
      // this conditionals are in order to set close zoom and move lot only when user change lot and not when get more dates
      const latitude = !dates.newDates ? lotsCenter.geometry.coordinates[1] : viewport.latitude
      const longitude = !dates.newDates ? lotsCenter.geometry.coordinates[0] : viewport.longitude
      const zoom =
        viewport.zoom === MAP.ZOOM.DEFAULT || !dates.newDates ? MAP.ZOOM.CLOSE : viewport.zoom
      if (map) onChangeLotTransition({ longitude, latitude, zoom })
      setDates(prevDates => ({
        ...prevDates,
        newDates: false,
      }))
      setPopupInfo(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLots, map])

  const grainHumidityHasNotemErgenceDaysActivation = !selectedLots.every(lot => {
    if (!lot.riceLot.catalog.dates.length) return true
    return lot.riceLot.catalog.dates[0].mapUrls.grainHumidity?.emergenceDaysActivation
  })

  useEffect(() => {
    setSelectedTimeline(undefined)
    setDates({
      fromTime: firstFromTime,
      toTime: today,
      newDates: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])
  const estimatedYieldHasNotFormula = selectedLots.every(
    lot => lot.riceLot.estimatedYield.noFormula,
  )

  const formatTimeLineData = useMemo(() => {
    return selectedLots.map(lot => ({
      id: lot.id,
      name: lot.name,
      riceLot: {
        catalog: lot.riceLot.catalog,
      },
    }))
  }, [selectedLots])

  const {
    drawnAreas,
    drawMode,
    onCreateFeature,
    onUpdateFeature,
    onDeleteAllFeatures,
    onDeleteFeature,
    drawnAreasFeatures,
    drawingOption,
    onDrawingOptionSelected,
    showDropdown,
    toggleDropdownVisibility,
  } = useDrawingTool(toolMode, setToolMode)

  const formatCompareTimelineData = useMemo(() => {
    return compareLots.map(lot => ({
      id: lot.id,
      calendar: lot.riceLot.calendar,
      dates: lot.riceLot.catalog.dates,
    }))
  }, [compareLots])

  const [popupCoordinates, setPopupCoordinates] = useState<number[]>()

  const interactiveLayerIds = useMemo(
    () => selectedLots.map(lot => lot.id.toString()),
    [selectedLots],
  )

  const handleChange = useCallback(
    (newField: SelectField) => {
      setField(newField)
      onDeleteAllFeatures()
    },
    [onDeleteAllFeatures],
  )

  const onMove = useCallback(evt => setViewport(evt.viewState), [])

  // This is a workaround to track when we should show popup or not based on if we are drawing or deleting with the drawing tool
  const [showPopup, setShowPopup] = useState(true)

  const onMouseEnter = useCallback(() => {
    setShowPopup(drawingOption !== 'draw' && drawingOption !== 'delete')
    setCursor('pointer')
  }, [drawingOption])

  const onMouseLeave = useCallback(() => setCursor('grab'), [])

  const onClick = useCallback(
    (event: MapLayerMouseEvent) => {
      if (popupInfo) {
        setPopupInfo(undefined)
        return
      }

      const { features } = event

      const feature = features?.[0]
      if (!feature) return

      const { id } = feature.layer

      const lot = selectedLots?.find(lotToFind => lotToFind.id === Number(id))
      if (!lot) return
      const bboxLot = GeoUtils.bboxCoords(bbox(lot.area))
      const polygonLot = polygon(lot.area.coordinates)
      const polygonCenter = centroid(polygonLot)
      setPopupCoordinates([polygonCenter.geometry.coordinates[0], bboxLot[0][1] + 0.003])
      setCursor('grab')

      setPopupInfo({
        name: lot.name,
        daysSinceEmergencyDate: lot.riceLot.emergenceDate
          ? differenceInDays(today, new Date(lot.riceLot.emergenceDate))
          : 0,
        variety: lot.variety?.locales[i18next.resolvedLanguage as Language],
        size: Math.round(lot.size),
        irrigationIndicator: lot.riceLot.indicators?.irrigation ?? undefined,
        chlorophyllIndicator: lot.riceLot.indicators?.chlorophyll ?? undefined,
        id: lot.id,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLots, popupInfo],
  )

  const compareMapRef = useRef<MapboxCompare>()

  const onHandleCompareImages = () => {
    setToolMode(prevMode => (prevMode === 'compare' ? null : 'compare'))
  }

  useEffect(() => {
    if (!isCompareModeSelected) {
      compareMapRef.current?.remove()
    }
  }, [isCompareModeSelected])

  const onCompareMapRender = () => {
    if (!map || !compareMap) return
    setCompareMapData()
    compareMapRef.current = new MapboxCompare(compareMap, map, '#comparison-container')
  }

  const SELECT_NEW_UI_CLASSNAME = 'custom-select-dropdown'

  const headerItems = [
    <SeasonFieldsSelect
      defaultId={Number.isNaN(defaultFieldId) ? undefined : defaultFieldId}
      onChange={handleChange}
      dropdownClassName={SELECT_NEW_UI_CLASSNAME}
      dropdownMatchSelectWidth={false}
    />,
    <LotsSelect
      fieldId={field?.id}
      defaultId={Number.isNaN(defaultLotId) ? undefined : defaultLotId}
      onChange={setSelectedLots}
      lots={lots}
      loading={isLotsLoading}
      dropdownClassName={SELECT_NEW_UI_CLASSNAME}
      dropdownMatchSelectWidth={false}
    />,
  ]

  return (
    <MainContainer>
      <Header items={headerItems} />
      <Container>
        {isLotsLoading && <DatesLoader />}
        <Toolbar
          mode={toolMode}
          onHandleCompareImages={onHandleCompareImages}
          drawingOption={drawingOption}
          showDrawingToolDropdown={showDropdown}
          onDrawingToolDropdownVisibleChange={toggleDropdownVisibility}
          onDrawingOptionSelected={onDrawingOptionSelected}
          isDrawingToolAvailable={!drawnAreas.length}
        />
        <SideBar>
          <StyledCol>
            {!isCompareModeSelected && (
              <>
                <SatelliteInformationSelect
                  borderRadius="4px 4px 0px 0px"
                  estimatedYieldHasNotFormula={estimatedYieldHasNotFormula}
                  grainHumidityHasNotemErgenceDaysActivation={
                    grainHumidityHasNotemErgenceDaysActivation
                  }
                  isEstimatedYieldNotAvailable={
                    isEstimatedYieldNotAvailable || allSelectedLotsArePlanet
                  }
                  isGrainHumidityNotAvailable={
                    isGrainHumidityNotAvailable || allSelectedLotsArePlanet
                  }
                  onChange={setSelectedMapIndex}
                  selectedMapIndex={selectedMapIndex}
                  isIrrigationNotAvailable={allSelectedLotsArePlanet}
                  isChlorophyllNotAvailable={allSelectedLotsArePlanet}
                  isNotAvailableForHighFrequency={allSelectedLotsArePlanet}
                />

                {!!selectedLots.length && (
                  <DescriptionCard
                    selectedMapIndex={selectedMapIndex}
                    variety={
                      selectedLots[0].variety?.name === VARIETY_GURI_NAME
                        ? 'isGuriVariety'
                        : 'otherVariety'
                    }
                    lots={selectedLots}
                  />
                )}
              </>
            )}
          </StyledCol>
        </SideBar>

        {!isCompareModeSelected ? (
          <BottomBar $isVisible={!isEstimatedYieldIndexSelected}>
            <Timeline
              setDates={setDates}
              isLotsLoading={isLotsLoading}
              timelineData={formatTimeLineData}
              error={error}
            />
          </BottomBar>
        ) : (
          <>
            <CompareTimelineContainer $isVisible>
              <CompareTimeline
                compareTimelineData={formatCompareTimelineData}
                onChange={setSelectedCompareTimeline}
                defaultValue={selectedCompareTimeline}
                setDates={setDates}
                isLotsLoading={isLotsLoading}
                showDatePicker={!isEstimatedYieldCompareMapIndexSelected}
              >
                <SatelliteInformationSelect
                  borderRadius="4px"
                  estimatedYieldHasNotFormula={estimatedYieldHasNotFormula}
                  grainHumidityHasNotemErgenceDaysActivation={
                    grainHumidityHasNotemErgenceDaysActivation
                  }
                  isEstimatedYieldNotAvailable={
                    isEstimatedYieldNotAvailable || allSelectedCompareLotsArePlanet
                  }
                  isGrainHumidityNotAvailable={
                    isGrainHumidityNotAvailable || allSelectedCompareLotsArePlanet
                  }
                  onChange={setSelectedCompareMapIndex}
                  selectedMapIndex={selectedCompareMapIndex}
                  bordered={false}
                  isIrrigationNotAvailable={allSelectedCompareLotsArePlanet}
                  isChlorophyllNotAvailable={allSelectedCompareLotsArePlanet}
                  isNotAvailableForHighFrequency={allSelectedCompareLotsArePlanet}
                />
              </CompareTimeline>
            </CompareTimelineContainer>
            <CompareTimelineContainer $isVisible position="right">
              <CompareTimeline
                compareTimelineData={formatCompareTimelineData}
                onChange={setSelectedTimeline}
                defaultValue={selectedTimeline}
                setDates={setDates}
                isLotsLoading={isLotsLoading}
                showDatePicker={!isEstimatedYieldIndexSelected}
              >
                <SatelliteInformationSelect
                  borderRadius="4px"
                  estimatedYieldHasNotFormula={estimatedYieldHasNotFormula}
                  grainHumidityHasNotemErgenceDaysActivation={
                    grainHumidityHasNotemErgenceDaysActivation
                  }
                  isEstimatedYieldNotAvailable={
                    isEstimatedYieldNotAvailable || allSelectedLotsArePlanet
                  }
                  isGrainHumidityNotAvailable={
                    isGrainHumidityNotAvailable || allSelectedLotsArePlanet
                  }
                  onChange={setSelectedMapIndex}
                  selectedMapIndex={selectedMapIndex}
                  bordered={false}
                  isIrrigationNotAvailable={allSelectedLotsArePlanet}
                  isChlorophyllNotAvailable={allSelectedLotsArePlanet}
                  isNotAvailableForHighFrequency={allSelectedLotsArePlanet}
                />
              </CompareTimeline>
            </CompareTimelineContainer>
          </>
        )}
        <MapContainer id="comparison-container">
          <ReactMapGL
            onMove={onMove}
            interactiveLayerIds={popupInfo ? undefined : interactiveLayerIds}
            mapStyle={MAP.STYLES.SATELLITE_STREET}
            {...(!isCompareModeSelected && viewport)}
            id="map"
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            cursor={drawMode === 'draw_polygon' ? 'crosshair' : cursor}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <Control
              mapOptions={{ displayControlsDefault: false }}
              onCreate={onCreateFeature}
              onUpdate={onUpdateFeature}
              onDeleteFeature={onDeleteFeature}
              features={drawnAreasFeatures}
              mode={drawMode}
              drawingOption={drawingOption}
            />
            {selectedTimeline &&
              selectedLots.map(lot => {
                const foundLot = selectedLots.find(lotToFind => lotToFind.id === lot.id)
                const getImageUrl = () => {
                  if (selectedMapIndex === 'estimatedYield')
                    return lot.riceLot.estimatedYield.mapUrl ?? undefined

                  const selectedLotDate = foundLot?.riceLot.catalog.dates.find(
                    date => date.date === selectedTimeline,
                  )

                  if (
                    selectedLotDate?.provider === SentinelHubProvider.PLANET &&
                    selectedMapIndex !== 'realImage'
                  )
                    return undefined
                  return selectedLotDate?.mapUrls[selectedMapIndex]?.url ?? undefined
                }

                return (
                  <React.Fragment key={lot.id}>
                    <>
                      <Source
                        type="geojson"
                        id={lot.id.toString()}
                        data={polygon(lot.area.coordinates)}
                      >
                        <Layer
                          beforeId="country-label"
                          id={lot.id.toString()}
                          type="fill"
                          paint={{
                            'fill-color': getImageUrl() ? 'transparent' : colors.grey3,
                          }}
                        />
                        <Layer
                          beforeId="country-label"
                          id={`lot-${lot.id}`}
                          type="line"
                          paint={{
                            'line-color': colors.olive5,
                            'line-width': 4,
                          }}
                        />
                      </Source>
                      {config.isSentinelImageryOn && getImageUrl() && (
                        <Source
                          type="image"
                          url={getImageUrl()}
                          coordinates={GeoUtils.bboxCoords(bbox(lot.area))}
                        >
                          <Layer
                            type="raster"
                            beforeId={lot.id.toString()}
                            id={`image-${lot.id.toString()}`}
                            paint={{}}
                          />
                        </Source>
                      )}
                      {showPopup && popupInfo && (
                        <Marker
                          latitude={popupCoordinates?.[1]}
                          longitude={popupCoordinates?.[0]}
                          draggable={false}
                        >
                          <PopupCard>
                            <LotCard data={popupInfo} />
                          </PopupCard>
                        </Marker>
                      )}
                    </>
                  </React.Fragment>
                )
              })}
            {drawingOption !== 'hide' &&
              drawnAreas?.map(drawnArea => (
                <Marker
                  key={drawnArea.feature.id}
                  latitude={drawnArea.polygonCenter.geometry.coordinates[1]}
                  longitude={drawnArea.polygonCenter.geometry.coordinates[0]}
                  draggable={false}
                >
                  <TooltipTextContainer>
                    <TooltipText>{t('systemsFormat.area', { val: drawnArea.area })}</TooltipText>
                  </TooltipTextContainer>
                </Marker>
              ))}
          </ReactMapGL>
          {isCompareModeSelected && (
            <CompareImagesMap
              lots={selectedLots}
              initialViewPort={{ ...viewport }}
              onMount={onCompareMapRender}
            />
          )}
        </MapContainer>
      </Container>
    </MainContainer>
  )
}
