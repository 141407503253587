import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { differenceInDays } from 'date-fns'
import { Row, Col, Space } from 'antd'
import { times } from 'lodash'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { dashboardImage } from 'assets/images'
import { ConfirmationModal, FullScreenLoader } from 'components'
import { GraphQLUtils } from 'utils'
import { LotsMapLocationState } from 'types'
import { ActiveCompanyContext } from 'contexts'
import { usePermissions, useSeason } from 'hooks'
import { I18NService } from 'services'
import { Language } from 'types/i18n'

import {
  GoBackButton,
  EmergenceDateCard,
  IrrigationMonitorCard,
  TargetYield,
  TrafficLight,
  Timeline,
  ContainerCol,
  LastUpdateCard,
  Card,
  PhytosanitaryTable,
  Layout,
  FertilizerTable,
  StyledCol,
  Container,
  TablesSpace,
  Comment,
  GrainHumidity,
  Yield,
  RowContainer,
  YieldCardContainer,
  CommentsCardContainer,
} from './components'
import {
  LOT_DASHBOARD,
  LotDashboard as LotDashboardType,
  LotDashboardVariables,
  AppliedFertilizer,
  RICE_UNITS_NUTRIENTS_AND_INGREDIENTS,
  RiceUnitsNutrientsAndIngredients,
  AppliedPhytosanitaries,
  RiceUnitsNutrientsAndIngredientsVariables,
} from './api'
import { LotOrFieldInformation, MapImage } from '../components'
import { InformationCard } from '../Dashboard/components'
import {
  useCreateRiceFertilizer,
  useDeleteRiceFertilizer,
  useDeleteRicePhytosanitary,
  useCreateRicePhytosanitary,
  useUpdateRiceLot,
} from './hooks'
import { DeleteAplicationData, DeleteType } from './components/types'

const INITAL_INPUT_TABLES_ELEMENTS_AMOUNT = 4
const VARIETY_GURI_NAME = 'guriIntaCl'

export const LotDashboard: React.FC = () => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { permissions } = usePermissions()

  const [appliedFertilizers, setAppliedFertilizers] = useState<AppliedFertilizer[]>([])
  const [appliedPhytosanitaries, setAppliedPhytosanitaries] = useState<AppliedPhytosanitaries[]>([])
  const [deleteAplicationData, setDeleteAplicationData] = useState<DeleteAplicationData>()
  const [fieldId, setFieldId] = useState<number>()
  const { id } = useParams<{ id: string }>()
  const history = useHistory<LotsMapLocationState>()
  const today = new Date()
  const { activeSeasonId, activeCountryId } = ActiveCompanyContext.useActiveCompanyContext()

  if (!id) history.goBack()

  const { data: season, loading: seasonLoading } = useSeason(activeSeasonId)
  const { data, loading } = useQuery<LotDashboardType, LotDashboardVariables>(LOT_DASHBOARD, {
    variables: { id: Number(id), isSatelliteImageryFlagOn: permissions.satelliteImagery },
  })
  const { data: riceUnitsNutrientsAndIngredients } = useQuery<
    RiceUnitsNutrientsAndIngredients,
    RiceUnitsNutrientsAndIngredientsVariables
  >(RICE_UNITS_NUTRIENTS_AND_INGREDIENTS, {
    variables: { countryId: activeCountryId! },
    skip: !activeCountryId,
  })

  const riceUnits = riceUnitsNutrientsAndIngredients?.riceUnits.results
  const riceNutrients = riceUnitsNutrientsAndIngredients?.riceNutrients.results
  const riceActiveIngredients = riceUnitsNutrientsAndIngredients?.riceActiveIngredients.results

  const { createRiceFertilizer, loading: createFertilizerLoading } = useCreateRiceFertilizer()
  const { createRicePhytosanitary, loading: createPhytosanitaryLoading } =
    useCreateRicePhytosanitary()
  const { deleteRiceFertilizer, loading: deleteRiceFertilizerLoading } = useDeleteRiceFertilizer(
    () => setDeleteAplicationData({ showModal: false, id: undefined, type: undefined }),
  )
  const { deleteRicePhytosanitary, loading: deleteRicePhytosanitaryLoading } =
    useDeleteRicePhytosanitary(() =>
      setDeleteAplicationData({ showModal: false, id: undefined, type: undefined }),
    )

  const { updateRiceLotMutation } = useUpdateRiceLot()

  useEffect(() => {
    const seasonLot = season?.season.seasonFields.find(seasonField =>
      seasonField.lots.some(lot => lot.id === Number(id)),
    )

    if (!seasonLoading && !seasonLot) history.replace('/dashboard')
    setFieldId(seasonLot?.field?.id)
  }, [history, id, season?.season.seasonFields, seasonLoading])

  useEffect(() => {
    const existingRiceFertilizers = data?.lot.riceLot.appliedFertilizers
    if (!data?.lot.riceLot || !permissions.satelliteImagery) return

    if (!existingRiceFertilizers?.length && !createFertilizerLoading)
      times(INITAL_INPUT_TABLES_ELEMENTS_AMOUNT, () => {
        createRiceFertilizer({
          variables: { createFertilizerDTO: { riceLotId: data?.lot.riceLot.id } },
        })
      })
    if (!existingRiceFertilizers) return
    setAppliedFertilizers(existingRiceFertilizers)
  }, [
    createFertilizerLoading,
    createRiceFertilizer,
    data?.lot.riceLot,
    permissions.satelliteImagery,
  ])

  useEffect(() => {
    const existingRicePhytosanitaries = data?.lot.riceLot.appliedPhytosanitaries
    if (!data?.lot.riceLot || !permissions.satelliteImagery) return

    if (!existingRicePhytosanitaries?.length && !createPhytosanitaryLoading)
      times(INITAL_INPUT_TABLES_ELEMENTS_AMOUNT, () => {
        createRicePhytosanitary({
          variables: { createPhytosanitaryDTO: { riceLotId: data?.lot.riceLot.id } },
        })
      })
    if (!existingRicePhytosanitaries) return
    setAppliedPhytosanitaries(existingRicePhytosanitaries)
  }, [
    createPhytosanitaryLoading,
    createRicePhytosanitary,
    data?.lot.riceLot,
    permissions.satelliteImagery,
  ])

  if (loading || seasonLoading) return <FullScreenLoader />

  return (
    <Layout
      headerTitle={t('headerTitle')}
      headerImage={dashboardImage}
      actionButton={<GoBackButton />}
    >
      <Container>
        <Col span={24}>
          <LotOrFieldInformation
            name={data?.lot.name ?? ''}
            commercialName={`Sucursal ${data?.lot.commercialName}`}
            lotsOrHaQty={commonT('systemsFormat.area', { val: data?.lot.size })}
            onSettingsClick={() =>
              history.push('/settings/lots', {
                seasonId: activeSeasonId,
                lotId: Number(id),
                fieldId,
              })
            }
          />
        </Col>
        <Col span={24}>
          <Row gutter={24} wrap={false}>
            <ContainerCol>
              <Space direction="vertical" size={24}>
                <Row gutter={[24, 24]} wrap={false}>
                  {permissions.satelliteImagery && (
                    <Col>
                      <TrafficLight
                        hasEmergenceDate={!!data?.lot.riceLot.emergenceDate}
                        semaphoreIndicator={data?.lot.riceLot.indicators?.semaphore ?? undefined}
                      />
                    </Col>
                  )}
                  <Col>
                    <EmergenceDateCard
                      variety={
                        data?.lot.variety?.locales[i18next.resolvedLanguage as Language] ?? '-'
                      }
                      daysSinceEmergenceDate={
                        data?.lot.riceLot.emergenceDate
                          ? differenceInDays(
                              today,
                              new Date(data.lot.riceLot.emergenceDate),
                            ).toString()
                          : '-'
                      }
                    />
                  </Col>
                  {permissions.satelliteImagery && (
                    <Col>
                      <IrrigationMonitorCard
                        sufficientWaterPercentage={
                          Number(
                            data?.lot.riceLot.indicators?.sufficientWaterPercentage?.toFixed(),
                          ) ?? 0
                        }
                        irrigationIndicator={data?.lot.riceLot.indicators?.irrigation ?? undefined}
                        hasEmergenceDate={!!data?.lot.riceLot.emergenceDate}
                      />
                    </Col>
                  )}
                  <Col>
                    <MapImage
                      fieldId={fieldId}
                      lotId={data?.lot.id}
                      lotCoordinates={data?.lot.area.coordinates[0]}
                      width="288px"
                      height="157px"
                    />
                  </Col>
                </Row>
                {permissions.satelliteImagery && (
                  <Row>
                    <TargetYield
                      emergenceDate={data?.lot.riceLot.emergenceDate ?? undefined}
                      yieldName={data?.lot.riceLot.targetYieldCurve?.name}
                      targetYieldCurveValues={data?.lot.riceLot.targetYieldCurve?.values}
                      realYieldCurveValues={data?.lot.riceLot.realYieldCurve?.values}
                      chlorophyllIndicator={data?.lot.riceLot.indicators?.chlorophyll ?? undefined}
                    />
                  </Row>
                )}
              </Space>
            </ContainerCol>
            {permissions.satelliteImagery && (
              <Col hidden={!permissions.satelliteImagery}>
                <Space direction="vertical" size={24}>
                  <LastUpdateCard
                    weatherType={data?.lot.riceLot.indicators?.weatherType}
                    lastUpdate={data?.lot.riceLot.indicators?.lastUpdate}
                  />
                  <InformationCard isLotDashboard />
                </Space>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Space direction="vertical" size={24}>
            <Row gutter={24} wrap={false}>
              <Col>
                {data?.lot.riceLot.id && (
                  <Timeline
                    lotId={Number(id)}
                    previousCropId={data?.lot.previousCrop?.id}
                    riceLotId={data?.lot.riceLot.id}
                    emergenceDate={data?.lot.riceLot.emergenceDate ?? undefined}
                    plantingDate={data?.lot.riceLot.plantingDate ?? undefined}
                    firstIrrigationStartDate={
                      data?.lot.riceLot.firstIrrigationStartDate ?? undefined
                    }
                    firstIrrigationEndDate={data?.lot.riceLot.firstIrrigationEndDate ?? undefined}
                    floweringDate={data?.lot.riceLot.floweringDate ?? undefined}
                    harvestDate={data?.lot.riceLot.harvestDate ?? undefined}
                    differentiationDate={data?.lot.riceLot.differentiationDate ?? undefined}
                    physiologicalMaturityDate={
                      data?.lot.riceLot.physiologicalMaturityDate ?? undefined
                    }
                    preparationDate={data?.lot.riceLot.preparationDate ?? undefined}
                    burningDate={data?.lot.riceLot.burningDate ?? undefined}
                    earAppearanceDate={data?.lot.riceLot.earAppearanceDate ?? undefined}
                    updateRiceLotMutation={updateRiceLotMutation}
                  />
                )}
              </Col>
              {permissions.isFeatureSetGrupoDiana && (
                <Col>
                  <RowContainer gutter={[0, 24]}>
                    <YieldCardContainer flex={1}>
                      <Card title={t('yieldCard.title')} subTitle={t('yieldCard.subtitle')}>
                        <Yield
                          estimatedYield={data?.lot.riceLot.estimatedYield}
                          size={data?.lot.size ?? 0}
                          yieldValue={data?.lot.riceLot.yield ?? undefined}
                          varietyName={data?.lot.variety?.name}
                          emergenceDate={data?.lot.riceLot.emergenceDate ?? undefined}
                          updateRiceLotMutation={updateRiceLotMutation}
                          riceLotId={data?.lot.riceLot.id}
                        />
                      </Card>
                    </YieldCardContainer>
                    <CommentsCardContainer flex={1}>
                      <Card title={t('commentsCard.title')}>
                        <Comment
                          updateRiceLotMutation={updateRiceLotMutation}
                          comment={data?.lot.riceLot.comment ?? undefined}
                          riceLotId={data?.lot.riceLot.id}
                          height="195px"
                        />
                      </Card>
                    </CommentsCardContainer>
                  </RowContainer>
                </Col>
              )}

              {permissions.satelliteImagery && (
                <StyledCol flex={1}>
                  <TablesSpace>
                    <Card
                      title={t('fertilizersTable.title')}
                      onCreate={() => {
                        if (!data?.lot.riceLot.id) return GraphQLUtils.errorHandler
                        return createRiceFertilizer({
                          variables: { createFertilizerDTO: { riceLotId: data?.lot.riceLot.id } },
                        })
                      }}
                    >
                      <FertilizerTable
                        appliedFertilizers={appliedFertilizers}
                        onDelete={setDeleteAplicationData}
                        riceNutrients={riceNutrients ?? []}
                        riceUnits={riceUnits ?? []}
                      />
                    </Card>
                    <Card
                      onCreate={() => {
                        if (!data?.lot.riceLot.id) return GraphQLUtils.errorHandler
                        return createRicePhytosanitary({
                          variables: {
                            createPhytosanitaryDTO: { riceLotId: data?.lot.riceLot.id },
                          },
                        })
                      }}
                      title={t('phytosanitariesTable.title')}
                    >
                      <PhytosanitaryTable
                        appliedPhytosanitaries={appliedPhytosanitaries}
                        riceIngredients={riceActiveIngredients ?? []}
                        riceUnits={riceUnits ?? []}
                        onDelete={setDeleteAplicationData}
                      />
                    </Card>
                  </TablesSpace>
                </StyledCol>
              )}
            </Row>
            {permissions.satelliteImagery && (
              <>
                <Card title={t('commentsCard.title')}>
                  <Comment
                    updateRiceLotMutation={updateRiceLotMutation}
                    comment={data?.lot.riceLot.comment ?? undefined}
                    riceLotId={data?.lot.riceLot.id}
                  />
                </Card>
                <Row gutter={24}>
                  <Col flex="0 0 290px">
                    <Card title={t('grainHumidityCard.title')}>
                      <GrainHumidity
                        data={data?.lot.riceLot.indicators?.grainHumidity}
                        isGuriVariety={data?.lot.variety?.name === VARIETY_GURI_NAME}
                      />
                    </Card>
                  </Col>
                  <Col flex="1">
                    <Card title={t('yieldCard.title')} subTitle={t('yieldCard.subtitle')}>
                      <Yield
                        estimatedYield={data?.lot.riceLot.estimatedYield}
                        size={data?.lot.size ?? 0}
                        yieldValue={data?.lot.riceLot.yield ?? undefined}
                        varietyName={data?.lot.variety?.name}
                        emergenceDate={data?.lot.riceLot.emergenceDate ?? undefined}
                        updateRiceLotMutation={updateRiceLotMutation}
                        riceLotId={data?.lot.riceLot.id}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </Space>
        </Col>
      </Container>
      {deleteAplicationData?.showModal && (
        <ConfirmationModal
          description={t('inputDeletionModal.confirmation')}
          title={t('inputDeletionModal.title')}
          onConfirm={() => {
            if (deleteAplicationData.id) {
              if (deleteAplicationData.type === DeleteType.FERTILIZER) {
                deleteRiceFertilizer({ variables: { id: deleteAplicationData.id } })
                return
              }
              deleteRicePhytosanitary({ variables: { id: deleteAplicationData.id } })
            }
          }}
          onCancel={() =>
            setDeleteAplicationData({ showModal: false, id: undefined, type: undefined })
          }
          loading={deleteRiceFertilizerLoading || deleteRicePhytosanitaryLoading}
        />
      )}
    </Layout>
  )
}
