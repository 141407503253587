import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { AuthContext } from 'contexts'
import { STORAGE_KEYS } from 'consts'
import { useLocalStorage } from 'hooks'
import { GraphQLUtils } from 'utils'

import { SIGNUP, SignUp, SignUpVariables } from '../api'

export const useSignup = () => {
  const [, setUserToken] = useLocalStorage(STORAGE_KEYS.USER_TOKEN)
  const history = useHistory()

  const { login } = AuthContext.useAuthContext()
  return useMutation<SignUp, SignUpVariables>(SIGNUP, {
    onCompleted: ({ signup: signupResponse }) => {
      if (signupResponse.user?.isVerified) {
        login({ userId: signupResponse.user.id })
        return setUserToken(signupResponse.token)
      }

      return history.push('verify-email')
    },
    // TODO: Handle error
    onError: GraphQLUtils.errorHandler,
  })
}
