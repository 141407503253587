import styled from 'styled-components'
import { ComponentProps } from 'react'
import { isFuture } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { DatePicker as AntDatePicker } from 'components'
import { I18NService } from 'services'
import { usePermissions } from 'hooks'

const StyledDatePicker = styled(AntDatePicker)`
  width: 143px;
`

type Props = ComponentProps<typeof AntDatePicker>

const disabledDateCurrentActualDate = (date: Date) => isFuture(date)

export const DatePicker: React.FC<Props> = props => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { permissions } = usePermissions()

  return (
    <StyledDatePicker
      placeholder={t('inputs.date')}
      format={t('dateFormats.medium')}
      disabledDate={permissions.isFeatureSetGrupoDiana ? disabledDateCurrentActualDate : undefined}
      {...props}
    />
  )
}
