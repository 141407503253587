/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  Lots,
  LotsVariables,
  Lots_lots_results as Lot,
  Lots_lots_results_riceLot_catalog_dates as CatalogDates,
  Lots_lots_results_riceLot_calendar as CalendarDates,
} from './types/Lots'

export const LOTS = gql`
  query Lots($seasonId: Int!, $fieldId: Int!, $fromTime: DateTime!, $toTime: DateTime!) {
    lots(seasonId: $seasonId, fieldId: $fieldId) {
      results {
        id
        name
        size
        area {
          type
          coordinates
        }
        variety {
          id
          name
          locales {
            es
            en
          }
        }
        location {
          coordinates
          type
        }
        riceLot {
          emergenceDate
          id
          indicators {
            irrigation
            chlorophyll
            semaphore
          }
          estimatedYield {
            mapUrl
            isAvailable
            noFormula
          }
          calendar {
            date
            isAvailable
            type
          }
          catalog(fromTime: $fromTime, toTime: $toTime) {
            hasMore
            dates {
              date
              isAvailable
              type
              provider
              mapUrls {
                chlorophyll {
                  url
                  colorHistogram {
                    color
                    area
                  }
                }
                realImage {
                  url
                }
                irrigation {
                  url
                  colorHistogram {
                    color
                    area
                  }
                }
                grainHumidity {
                  url
                  isAvailable
                  emergenceDaysActivation
                  colorHistogram {
                    color
                    area
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
