import { useQuery } from '@apollo/client'

import { CompanySeasons, CompanySeasonsVariables, COMPANY_SEASONS } from 'api'

export const useCompanySeasons = (companyId?: number) => {
  return useQuery<CompanySeasons, CompanySeasonsVariables>(COMPANY_SEASONS, {
    variables: { companyId: companyId! },
    skip: !companyId,
  })
}
