import styled from 'styled-components'
import { Col } from 'antd'

export { PeakDate } from './PeakDate'
export { YieldData } from './YieldData'

export const Divider = styled(Col)`
  width: 2px;
  height: 100%;
  border: ${({ theme }) => `2px solid ${theme.colors.grey2}`};
`
