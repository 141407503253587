import { Table as AntTable, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { UserAvatar } from 'components'
import { TABLE } from 'consts'
import { UserCompanyRole } from 'types'
import { I18NService } from 'services'

import { Invitation, UserRole } from '../../api'
import { RoleSelect } from '../RoleSelect'

const { Text } = Typography

const StyledRoleSelect = styled(RoleSelect)`
  width: 100%;
`

const Table = styled(AntTable)`
  & .ant-table-header {
    padding-bottom: ${({ scroll }) => scroll && '16px '};
  }
  & .ant-table-cell-scrollbar {
    box-shadow: none;
  }
` as typeof AntTable

interface TableUser {
  key: string
  id?: number
  fullname?: string
  email: string
  role: UserCompanyRole
  profilePicture?: string
}

interface Props {
  users: UserRole[]
  invitations: Invitation[]
  isReadOnly?: boolean
  onUserRoleChange: (userId: number, role: UserCompanyRole) => void
  currentUserId: number
}

export const CompanyUsersTable: React.FC<Props> = ({
  users,
  invitations,
  isReadOnly,
  onUserRoleChange,
  currentUserId,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMPANY_SETTINGS)

  const mappedUsers: TableUser[] = users.map(({ user, role }) => ({
    id: user.id,
    key: user.id.toString(),
    fullname: `${user.name} ${user.lastName}`,
    email: user.email,
    profilePicture: user.profilePicture ?? undefined,
    role,
  }))
  const mappedInvitations: TableUser[] = invitations.map(invitation => ({
    key: invitation.email,
    email: invitation.email,
    role: invitation.role,
  }))

  const isDataSourceBiggerThanMaxTableUsers =
    mappedUsers.length + mappedInvitations.length > TABLE.MAX_TABLE_DATA
  const columns: ColumnsType<TableUser> = [
    {
      title: t('usersTable.member'),
      width: isDataSourceBiggerThanMaxTableUsers ? '16%' : undefined,
      render: (user: TableUser) => <UserAvatar src={user.profilePicture} />,
    },
    {
      render: (user: TableUser) => <Text strong>{user.fullname ?? t('usersTable.guestUser')}</Text>,
      width: isDataSourceBiggerThanMaxTableUsers ? '25%' : undefined,
    },
    {
      render: (user: TableUser) => <Text>{user.email}</Text>,
      width: isDataSourceBiggerThanMaxTableUsers ? '40%' : undefined,
    },
    {
      title: t('usersTable.accessLevel'),
      width: '30%',
      render: (user: TableUser) => (
        <StyledRoleSelect
          defaultValue={user.role}
          disabled={isReadOnly || !user.id || currentUserId === user.id}
          onChange={newRole => onUserRoleChange(user.id!, newRole)}
        />
      ),
    },
  ]

  return (
    <Table
      dataSource={[...mappedUsers, ...mappedInvitations]}
      columns={columns}
      pagination={false}
      scroll={isDataSourceBiggerThanMaxTableUsers ? { y: 240 } : undefined}
    />
  )
}
