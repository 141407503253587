import { Col, Row, Typography } from 'antd'
import { format } from 'date-fns'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { LastUpdateIcon } from 'assets/icons'
import { DATE_FORMATS } from 'consts'
import { DATE_LOCALES } from 'consts/dates'

const { Title: AntTitle, Text: AntText } = Typography

const Callout = styled(Row).attrs({
  gutter: 23,
})`
  width: 100%;
  height: 105px;
  padding: 22px;
  background-color: ${({ theme }) => theme.colors.grey1};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
`

const Title = styled(AntTitle)`
  margin-bottom: 10px !important;
`

const Text = styled(AntText)`
  font-size: 13px;
`

interface Props {
  date: Date
}

export const LastModificationCallout: React.FC<Props> = ({ date }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.SEASON_SETTINGS)

  return (
    <Callout>
      <Col>
        <LastUpdateIcon />
      </Col>
      <Col flex={1}>
        <Row>
          <Title level={5}>{t('seasonCard.lasUpdateText')}</Title>
        </Row>
        <Row justify="space-between">
          <Text>{format(date, DATE_FORMATS.DAY_WITH_YEAR, { locale: DATE_LOCALES.ES })}</Text>
          <Text>{format(date, DATE_FORMATS.HOURS_AND_SECS)}</Text>
        </Row>
      </Col>
    </Callout>
  )
}
