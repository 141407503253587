import { Row, Typography } from 'antd'
import styled from 'styled-components'

import { SeasonFieldsSelect as BaseSeasonFieldsSelect } from 'components'

import { LotsSelect as BaseLotsSelect } from './LotsSelect'

export { Timeline } from './Timeline'
export { LotCard } from './LotCard'
export { DatesLoader } from './DatesLoader'
export { DescriptionCard } from './DescriptionCard'
export { Toolbar } from './Toolbar'
export { PopupCard } from './PopupCard'
export { SatelliteInformationSelect } from './SatelliteInformationSelect'
export { CompareTimeline } from './CompareTimeline'
export { Control } from './Control'
export { CompareImagesMap } from './CompareImagesMap'

export const SideBar = styled(Row)`
  width: 265px;
  position: absolute;
  flex-direction: column;
  top: 40px;
  right: 50px;
  z-index: 10;
  max-height: calc(100% - 80px);
  @media (max-height: 850px) {
    top: 10px;
    max-height: calc(100% - 50px);
  }
`

export const StyledCol = styled.div`
  width: 100%;
  overflow-y: auto;
`

export const SeasonFieldsSelect = styled(BaseSeasonFieldsSelect)`
  width: 250px;
  .ant-select-selector {
    font-size: 13px;
    font-weight: 600;
    color: white;
    border: none !important;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
`

export const LotsSelect = styled(BaseLotsSelect)`
  min-width: 100%;
  max-width: 250px;
  .ant-select-selector {
    font-size: 13px;
    font-weight: 600;
    color: white;
    border: none !important;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
`

interface BottomBarProps {
  $isVisible: boolean
}

export const BottomBar = styled(Row)<BottomBarProps>`
  width: calc((100% - 300px) * 85 / 100);
  position: absolute;
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  right: 300px;
  left: calc((100% - 300px) * 7.5 / 100);
  padding: 20px;
  bottom: 40px;
  z-index: 10;
  background: rgba(36, 40, 37, 0.7);
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
`

interface Props {
  position?: 'right'
}

export const CompareTimelineContainer = styled(BottomBar)<Props>`
  width: 350px;
  padding: 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.new.baseColor[900]};
  left: ${({ position }) => position && `calc(((100% - 300px) * 7.5 / 100) + 60%)`};
`

const { Text: AntText } = Typography

export const TooltipText = styled(AntText)`
  color: ${({ theme }) => theme.colors.olive} !important;
  font-family: Inter;
  font-size: 12px;
`
export const TooltipTextContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 10px;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
`

export const MapContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const Container = styled.div`
  position: relative;
  height: 100%;
`
