/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from 'styled-components'
import { ConfigProvider } from 'antd'
import { MapProvider } from 'react-map-gl'

import { theme, GlobalStyles } from 'styles'
import { ActiveCompanyContext, AuthContext } from 'contexts'
import { FullScreenLoader, PrivateRoute } from 'components'
import { useAuth, useCurrentUser } from 'hooks'
import { ApolloService, I18NService } from 'services'

import { LoginRouter } from './LoginRouter'
import { MainRouter } from './MainRouter'

const apolloClient = ApolloService.buildApolloClient()

const Routes: React.FC = () => {
  const activeCompanyContextValue = ActiveCompanyContext.useValue()
  const { userToken, tokenLogin, userId } = useAuth()
  const [loadingApp, setLoadingApp] = useState(!!userToken)
  const { loading: loadingUser } = useCurrentUser()
  const locale = I18NService.getAntdLocale()

  useEffect(() => {
    if (userToken && !userId) {
      const loginWithToken = async () => {
        await tokenLogin()
        setLoadingApp(false)
      }

      loginWithToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If app initialization or user are loading, we show a full screen loader. This way, we can
  // assume that when using the user in different parts of the app, we already have it.
  if (loadingApp || loadingUser) return <FullScreenLoader />

  return (
    <ConfigProvider locale={locale}>
      <Switch>
        <Route path="/login" component={LoginRouter} />
        <ActiveCompanyContext.Provider value={activeCompanyContextValue}>
          <PrivateRoute component={MainRouter} />
        </ActiveCompanyContext.Provider>
      </Switch>
    </ConfigProvider>
  )
}

export const App: React.FC = () => {
  const authContextValue = AuthContext.useValue()

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AuthContext.Provider value={authContextValue}>
          <MapProvider>
            <Router>
              <Routes />
            </Router>
          </MapProvider>
        </AuthContext.Provider>
      </ThemeProvider>
    </ApolloProvider>
  )
}
