import { useQuery } from '@apollo/client'

import { MapLots, MapLotsVariables, MAP_LOTS } from '../api'

export const useLots = (fieldId?: number, seasonId?: number) => {
  const { data, loading } = useQuery<MapLots, MapLotsVariables>(MAP_LOTS, {
    variables: { fieldId: fieldId!, seasonId: seasonId! },
    skip: !fieldId || !seasonId,
  })
  return { lots: data?.lots.results, loadingLots: loading }
}
