import styled from 'styled-components'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'

const { Text: AntText } = Typography

const Text = styled(AntText)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.olive};
`

interface Props {
  noFormula?: boolean
  estimatedPeakMonth?: string
}

export const NotAvailableText: React.FC<Props> = ({ noFormula, estimatedPeakMonth }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)

  return (
    <>
      {noFormula ? (
        <Text>{t('yieldCard.estimatedYieldHasNotFormula')}</Text>
      ) : (
        <Text>
          {t('yieldCard.isEstimatedYieldNotAvailable', {
            context: estimatedPeakMonth && 'estimatedPeakMonth',
            value: estimatedPeakMonth,
          })}
        </Text>
      )}
    </>
  )
}
