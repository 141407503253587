import { Control } from 'react-hook-form'
import { MailOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { FormInput } from 'components'
import { I18NService } from 'services'

interface Props {
  // TODO Fix type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  iconStyle: React.CSSProperties
  defaultValue?: string
  size?: 'small' | 'middle' | 'large'
  isReadOnly?: boolean
}

export const FormEmailInput: React.FC<Props> = ({
  control,
  iconStyle,
  defaultValue,
  isReadOnly = false,
  ...restProps
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMMON)
  return (
    <FormInput
      {...restProps}
      disabled={isReadOnly}
      // eslint-disable-next-line i18next/no-literal-string
      name="email"
      placeholder={t('inputs.email')}
      defaultValue={defaultValue}
      control={control}
      prefixIcon={<MailOutlined style={iconStyle} />}
    />
  )
}
