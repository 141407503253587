import { Popover as AntPopover, Row } from 'antd'
import { ComponentProps } from 'react'
import { NavLink as ReactNavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { MapIcon, SettingsIcon, DashboardIcon } from 'assets/icons'

export { AccountSettingsModal } from './AccountSettingsModal'
export { CompanySettingsModal } from './CompanySettingsModal'
export { CompaniesPopoverContent } from './CompaniesPopoverContent'

export const FlexCol = styled.div.attrs({
  size: 'large',
})`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 0;
`

export const FlexStartCol = styled(FlexCol)`
  justify-content: flex-start;
  padding: 0;
`

export const FlexEndCol = styled(FlexCol)`
  justify-content: flex-end;
  padding: 0;
`

export const NavRow = styled(Row)`
  position: relative;
  margin-bottom: 50px;
`

export const RowIconContainer = styled(Row)`
  height: 55px;
  display: flex;
  align-items: center;
`

export const NavLinkRow = styled(NavRow).attrs({
  justify: 'start',
})``

export const NavLink = styled(ReactNavLink).attrs({
  activeClassName: 'active',
})`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0px 11px 11px 0px;
  height: 55px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    color: ${({ theme }) => theme.colors.white};
  }
  &.active svg {
    color: ${({ theme }) => theme.colors.new.secondary[600]};
  }
  &:hover svg {
    color: ${({ theme }) => theme.colors.new.secondary[600]};
  }
`

const iconStyles = css`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`

export const StyledMapIcon = styled(MapIcon)`
  ${iconStyles};
  width: 100%;
`

export const StyledSettingsIcon = styled(SettingsIcon)`
  ${iconStyles}
`

export const StyledDashboardIcon = styled(DashboardIcon)`
  ${iconStyles}
`

const PopoverAdapter: React.FC<ComponentProps<typeof AntPopover>> = ({
  className,
  ...restProps
}) => <AntPopover {...restProps} overlayClassName={className} />

export const Popover = styled(PopoverAdapter)`
  padding-left: 20px;
  max-width: 290px;
  .ant-popover-inner-content {
    padding: 0;
    padding-top: 30px;
  }
  .ant-popover-arrow {
    display: none !important;
  }
`
