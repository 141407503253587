import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { I18NService } from 'services'

const { Text } = Typography

const CenteredText = styled(Text)`
  width: 100%;
  text-align: center;
`

export const LoginTextCta: React.FC = () => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOGIN)

  return (
    <CenteredText>
      {t('loginPage.haveAccountQuestion')}
      <Link to="/login">{t('loginPage.loginButton')}</Link>
    </CenteredText>
  )
}
