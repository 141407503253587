/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { CreateLots, CreateLotsVariables } from './types/CreateLots'
export type { UpdateLots, UpdateLotsVariables } from './types/UpdateLots'
export type { MapLots, MapLotsVariables, MapLots_lots_results as Lot } from './types/MapLots'

export const CREATE_LOTS = gql`
  mutation CreateLots($createLotDTOs: [CreateLotInput!]!) {
    createLots(createLotDTOs: $createLotDTOs) {
      results {
        id
        name
        commercialName
        riceLot {
          isPlanetEnabled
        }
        area {
          type
          coordinates
        }
        location {
          type
          coordinates
        }
        size
        crop {
          id
        }
        variety {
          id
          name
          locales {
            es
            en
          }
        }
      }
    }
  }
`

export const UPDATE_LOTS = gql`
  mutation UpdateLots($updateLotDTOs: [UpdateLotInput!]!) {
    updateLots(updateLotDTOs: $updateLotDTOs) {
      results {
        id
        name
        commercialName
        area {
          type
          coordinates
        }
        location {
          type
          coordinates
        }
        size
        crop {
          id
        }
        variety {
          id
          name
          locales {
            es
            en
          }
        }
      }
    }
  }
`

export const MAP_LOTS = gql`
  query MapLots($seasonId: Int!, $fieldId: Int!) {
    lots(seasonId: $seasonId, fieldId: $fieldId) {
      results {
        id
        name
        commercialName
        riceLot {
          isPlanetEnabled
        }
        area {
          type
          coordinates
        }
        location {
          coordinates
          type
        }
        crop {
          id
        }
        variety {
          id
          name
          locales {
            es
            en
          }
        }
      }
    }
  }
`
