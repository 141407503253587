/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import {
  CreateRiceFertilizer,
  CreateRiceFertilizerVariables,
  CREATE_RICE_FERTILIZER,
  LotDashboard,
  LotDashboardVariables,
  LOT_DASHBOARD,
} from '../api'

export const useCreateRiceFertilizer = () => {
  const [createRiceFertilizer, { loading }] = useMutation<
    CreateRiceFertilizer,
    CreateRiceFertilizerVariables
  >(CREATE_RICE_FERTILIZER, {
    update: (cache, { data: response }, { variables }) => {
      if (!variables) return
      if (!response) return

      const firstCreatedLot = variables.createFertilizerDTO
      const { riceLotId } = firstCreatedLot

      const cachedLot = cache.readQuery<LotDashboard, LotDashboardVariables>({
        query: LOT_DASHBOARD,
        variables: { id: riceLotId, isSatelliteImageryFlagOn: true },
      })

      const newAppliedFertilizer = response.createRiceFertilizer.fertilizer
      cache.writeQuery<LotDashboard, LotDashboardVariables>({
        query: LOT_DASHBOARD,
        variables: { id: riceLotId, isSatelliteImageryFlagOn: true },
        data: {
          lot: {
            ...cachedLot?.lot!,
            riceLot: {
              ...cachedLot?.lot.riceLot!,
              appliedFertilizers: cachedLot?.lot.riceLot.appliedFertilizers
                ? [...cachedLot?.lot.riceLot.appliedFertilizers, newAppliedFertilizer]
                : [newAppliedFertilizer],

              __typename: 'RiceLot',
            },
            __typename: 'Lot',
          },
        },
      })
    },
    onError: GraphQLUtils.errorHandler,
  })

  return { createRiceFertilizer, loading }
}
