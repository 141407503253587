import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom'
import useUpdateEffect from 'react-use/lib/useUpdateEffect'
import { useEffect } from 'react'

import { FullScreenLoader, ProtectedRoute } from 'components'
import { ActiveCompanyContext } from 'contexts'
import { FeatureFlag } from 'types'
import { useHasCompanyOrFieldsOrLots } from 'hooks'

import { Layout, Sider, Content } from './components'
import {
  SideNavbar,
  SettingsRouter,
  DashboardRouter,
  Map,
  InternalMetrics,
  Onboarding,
} from './features'
import { MapContext } from './contexts'

export const MainRouter: React.FC = () => {
  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()
  const { noCompany, loading } = useHasCompanyOrFieldsOrLots()
  const mapContextValue = MapContext.useValue()

  const history = useHistory()
  const { pathname } = useLocation()

  useUpdateEffect(() => {
    const path = pathname.split('/')[1]
    history.replace(`/${path}`)
  }, [activeCompanyId, history])

  useEffect(() => {
    if (noCompany) history.replace('/onboarding')
  }, [history, noCompany])

  return loading ? (
    <FullScreenLoader />
  ) : (
    <Layout>
      {!noCompany && (
        <Sider width="80px">
          <SideNavbar />
        </Sider>
      )}
      <Content>
        <Switch>
          {noCompany && <Route path="/onboarding" component={Onboarding} />}
          <Route path="/dashboard" component={DashboardRouter} />
          <ProtectedRoute flags={[FeatureFlag.SATELLITE_IMAGERY]} exact path="/map">
            <MapContext.Provider value={mapContextValue}>
              <Map />
            </MapContext.Provider>
          </ProtectedRoute>
          <Route path="/settings" component={SettingsRouter} />
          <Route path="/internal/metrics" component={InternalMetrics} />
          <Redirect to="/login" />
        </Switch>
      </Content>
    </Layout>
  )
}
