import { memo, useState } from 'react'
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { EllipsisText } from 'components'
import { I18NService } from 'services'

import { Props, ChartDataObject } from '../../../../../types'

const RenderActiveShape = (props: {
  cx: number
  cy: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  payload: { name: string }
  percent: number
  totalHa: number
}) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload, percent, totalHa } =
    props
  const { colors } = useTheme()
  const { t } = useTranslation(I18NService.NAMESPACES.DASHBOARD)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)

  return (
    <g>
      <foreignObject x={15} y={100} width="82%" height="30">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <EllipsisText
            text={payload.name}
            overlayInnerStyle={{
              fontWeight: 600,
              maxWidth: '90px',
              color: colors.white,
              lineHeight: '24px',
            }}
          />
        </div>
      </foreignObject>
      <text x={cx} y={cy} dy={25} textAnchor="middle" fill={colors.white}>
        {`${(percent * 100).toFixed()}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={colors.primary}
      />
      <text
        x={cx}
        y={cy}
        dy={110}
        textAnchor="middle"
        fill={colors.white}
        stroke={colors.white}
        style={{ fontSize: '16px', fontWeight: 250 }}
      >
        {t('varietiesCard.farmTotalSize', {
          size: commonT('systemsFormat.area', { val: totalHa }),
        })}
      </text>
    </g>
  )
}

export const Chart: React.FC<Props> = memo(({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const { colors } = useTheme()
  const onPieEnter = (_: unknown, index: number) => {
    setActiveIndex(index)
  }

  const getTotalOFHa = (chartObject?: ChartDataObject[]) =>
    chartObject?.reduce((accumulator, { size }) => {
      const totalHa = accumulator + size
      return totalHa
    }, 0)

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={100} height={100}>
        <Pie
          width={100}
          height={100}
          activeIndex={activeIndex}
          activeShape={props => <RenderActiveShape {...props} totalHa={getTotalOFHa(data)} />}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={80}
          fill={colors.new.baseColor[700]}
          dataKey="size"
          onMouseEnter={onPieEnter}
          stroke={colors.olive3}
        />
      </PieChart>
    </ResponsiveContainer>
  )
})
