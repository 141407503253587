import { Space as AntdSpace, Divider as AntDivider, Typography } from 'antd'
import styled, { useTheme } from 'styled-components'
import React from 'react'

import { CogSettingsIcon } from 'assets/icons'
import { usePermissions } from 'hooks'

const { Title: AntTitle } = Typography

const Title = styled(AntTitle)`
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  line-height: 32px;
`

const FieldTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.olive} !important;
  line-height: 24px;
  font-size: 14px !important;
  font-weight: 400 !important;
`

const Space = styled(AntdSpace)`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  margin-bottom: 24px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

interface Props {
  name: string
  lotsOrHaQty: string
  commercialName?: string
  onSettingsClick: () => void
}

export const Divider = styled(AntDivider)`
  height: 32px !important;
  border-left: ${({ theme }) => `2px solid ${theme.colors.grey2} !important `};
`
export const LotOrFieldInformation: React.FC<Props> = ({
  name,
  lotsOrHaQty,
  onSettingsClick,
  commercialName,
}) => {
  const { colors } = useTheme()
  const { permissions } = usePermissions()

  return (
    <Space size={20}>
      <Title level={4}>{name}</Title>
      {permissions.isFeatureSetGrupoDiana && commercialName && (
        <>
          <Divider type="vertical" />
          <FieldTitle>{commercialName}</FieldTitle>
        </>
      )}
      <Divider type="vertical" />
      <FieldTitle>{lotsOrHaQty}</FieldTitle>
      <Divider type="vertical" />
      <IconContainer onClick={onSettingsClick}>
        <CogSettingsIcon color={colors.olive5} height={20} width={20} />
      </IconContainer>
    </Space>
  )
}
