import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'

import { FeedbackCard } from '../../components'

export const VerifyEmail: React.FC = () => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOGIN)
  return <FeedbackCard title={t('verifyEmailPage.title')} showLoginText={false} />
}
