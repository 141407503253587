import { Row, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'

import { SelectField } from 'components/fields-selects/types'
import { I18NService } from 'services'

import { Lot } from '../../types'
import { LotsList, StyledCard, AddLotButton, ConfirmLotsButton, Title, Text } from './components'

interface Props {
  field?: SelectField
  lots: Lot[]
  selectedLotIndex?: number
  onSelectLot: (lotIndex: number) => void
  onAddLot: () => void
  onChangeLot: (lot: Lot['lot'], lotIndex: number) => void
  onDeleteLot: (lotIndex: number) => void
  onConfirmLots: () => void
  loading?: boolean
  handleKml: (file: UploadChangeParam<UploadFile>) => void
  onDrawMode: boolean
}

export const LotsCard: React.FC<Props> = ({
  field,
  lots,
  selectedLotIndex,
  onSelectLot,
  onAddLot,
  onChangeLot,
  onDeleteLot,
  onConfirmLots,
  loading,
  handleKml,
  onDrawMode,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_SETTINGS)

  if (!field) return null
  return (
    <StyledCard>
      <Title level={3}>{field.name}</Title>
      <Text>{t('description')}</Text>
      <Row justify="space-between">
        <AddLotButton onClick={onAddLot}>{t('buttons.drawLotText')}</AddLotButton>
        <Upload
          beforeUpload={() => false}
          onChange={handleKml}
          showUploadList={false}
          accept=".kml"
        >
          <AddLotButton>{t('buttons.uploadKMLText')}</AddLotButton>
        </Upload>
      </Row>
      <LotsList
        lots={lots}
        selectedLotIndex={selectedLotIndex}
        onSelect={onSelectLot}
        onChange={onChangeLot}
        onDelete={onDeleteLot}
        loading={loading}
        disableDelete={onDrawMode}
      />
      <Row justify="end">
        <ConfirmLotsButton
          disabled={
            !lots.length || lots.some(({ lot, feature }) => !lot.name || !lot.cropId || !feature)
          }
          onClick={onConfirmLots}
        >
          {t('buttons.confirmLotsText')}
        </ConfirmLotsButton>
      </Row>
    </StyledCard>
  )
}
