import { useState, createContext, useContext, useEffect, useCallback } from 'react'
import useCustomCompareEffect from 'react-use/lib/useCustomCompareEffect'
import { useTranslation } from 'react-i18next'
import { orderBy } from 'lodash'

import { useCurrentUser, useCompanySeasons, usePermissions } from 'hooks'

type ActiveCompanyType = { id?: number; countryId?: string; language?: string }

interface ActiveCompanyContextType {
  loadingCompanySeasons: boolean
  activeCompanyId?: number
  setActiveCompany: (company: ActiveCompanyType) => void
  activeSeasonId?: number
  setActiveSeason: (id?: number) => void
  isNewCompany?: boolean
  setIsNewCompany: (isNewCompany: boolean) => void
  activeCountryId?: string
}

const Context = createContext<ActiveCompanyContextType>({
  loadingCompanySeasons: false,
  setActiveCompany: () => {
    throw new Error(
      'Attempted to use default setActiveCompany function for ActiveCompanyContext, make sure to pass a custom function implementation',
    )
  },
  setActiveSeason: () => {
    throw new Error(
      'Attempted to use default setActiveSeason function for ActiveCompanyContext, make sure to pass a custom function implementation',
    )
  },
  setIsNewCompany: () => {
    throw new Error(
      'Attempted to use default setIsNewCompany function for ActiveCompanyContext, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): ActiveCompanyContextType => {
  const [activeCompanyId, setActiveCompanyId] = useState<number>()
  const [activeCountryId, setactiveCountryId] = useState<string>()
  const [activeSeasonId, setActiveSeasonId] = useState<number>()
  const [isNewCompany, setIsNewCompany] = useState<boolean>()

  const { user } = useCurrentUser()
  const { permissions } = usePermissions()
  const { i18n } = useTranslation()
  const { data: seasonsData, loading } = useCompanySeasons(activeCompanyId)

  const setActiveCompany = ({ id, countryId, language }: ActiveCompanyType) => {
    setActiveCompanyId(id)
    setactiveCountryId(countryId)
    const appLanguage =
      user?.companiesRoles?.find(company => company.company.id === id)?.company.country.lng ??
      language
    i18n.changeLanguage(appLanguage)
  }

  useCustomCompareEffect(
    () => {
      if (user && user.companiesRoles?.length) {
        setActiveCompany({
          id: user.companiesRoles[0].company.id,
          countryId: user.companiesRoles[0].company.country.id,
        })
      }
    },
    [user],
    (prevDeps, nextDeps) => prevDeps[0]?.id === nextDeps[0]?.id,
  )

  const getEarliestSeason = useCallback(() => {
    const currentDate = new Date()

    const earliestSeasons = seasonsData?.seasons.results.filter(
      season => new Date(season.startDate) <= currentDate,
    )
    // This is for case there are no seasons with a start date before or equal to the current one
    if (!earliestSeasons?.length) return seasonsData?.seasons.results[0]

    const orderedSeasons = orderBy(earliestSeasons, 'startDate', 'desc')

    return orderedSeasons[0]
  }, [seasonsData?.seasons.results])

  useEffect(() => {
    if (seasonsData) {
      const activeSeason = permissions.isFeatureSetGrupoDiana
        ? getEarliestSeason()
        : // We only consider the first one as active season. In the future use will be able to switch
          // and mark another as the active one.
          seasonsData.seasons.results[0]
      setActiveSeasonId(activeSeason?.id)
    }
  }, [getEarliestSeason, permissions.isFeatureSetGrupoDiana, seasonsData])

  return {
    loadingCompanySeasons: loading,
    activeCompanyId,
    setActiveCompany,
    activeSeasonId,
    setActiveSeason: setActiveSeasonId,
    isNewCompany,
    setIsNewCompany,
    activeCountryId,
  }
}

const useActiveCompanyContext = () => {
  return useContext(Context)
}

export const ActiveCompanyContext = {
  Provider: Context.Provider,
  useValue,
  useActiveCompanyContext,
}
