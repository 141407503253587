import { usePermissions } from 'hooks'

import { UpdateRiceLotVariables } from '../../../../api'
import { Body, DianaGroupBody } from './components'

interface Props {
  isAvailable?: boolean
  yieldValue?: number
  estimatedTnValue?: number
  estimatedKgValuePerHa?: number
  yieldKgValuePerHa?: number
  riceLotId?: number
  updateRiceLotMutation: (updateRiceLotDTO: { variables: UpdateRiceLotVariables }) => void
}

export const YieldData: React.FC<Props> = ({
  isAvailable,
  estimatedTnValue,
  estimatedKgValuePerHa,
  yieldKgValuePerHa,
  yieldValue,
  riceLotId,
  updateRiceLotMutation,
}) => {
  const { permissions } = usePermissions()

  return (
    <>
      {permissions.isFeatureSetGrupoDiana ? (
        <DianaGroupBody
          isAvailable={isAvailable}
          yieldValue={yieldValue}
          yieldKgValuePerHa={yieldKgValuePerHa}
          estimatedTnValue={estimatedTnValue}
          estimatedKgValuePerHa={estimatedKgValuePerHa}
          riceLotId={riceLotId}
          updateRiceLotMutation={updateRiceLotMutation}
        />
      ) : (
        <Body
          isAvailable={isAvailable}
          yieldValue={yieldValue}
          yieldKgValuePerHa={yieldKgValuePerHa}
          estimatedTnValue={estimatedTnValue}
          estimatedKgValuePerHa={estimatedKgValuePerHa}
          riceLotId={riceLotId}
          updateRiceLotMutation={updateRiceLotMutation}
        />
      )}
    </>
  )
}
