import { Typography, Row, Col } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { satelliteImage } from 'assets/images'
import { Loader } from 'components'

const { Text: AntText } = Typography

const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.olive} !important;
  font-size: 14px !important;
  font-weight: 500;
`

const Image = styled.img`
  width: 55px;
  height: 55px;
`

const StyledCol = styled(Col)`
  padding: 0 5px;
`

const Container = styled(Row)`
  background-color: ${({ theme }) => theme.colors.grey2};
  width: calc(35% - 81px);
  position: absolute;
  padding: 15px;
  top: 40px;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
`

export const DatesLoader: React.FC = () => {
  const { t } = useTranslation(I18NService.NAMESPACES.MAP)

  return (
    <Container justify="space-around" align="middle">
      <Col>
        <Image src={satelliteImage} alt={t('datesLoader.satelliteImageAlt')} />
      </Col>
      <StyledCol span={12}>
        <Text>{t('datesLoader.text')}</Text>
      </StyledCol>
      <Col>
        <Loader />
      </Col>
    </Container>
  )
}
