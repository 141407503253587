import { ActiveCompanyContext } from 'contexts'
import { FeatureSet, UserCompanyRole, UserOrganizationRole } from 'types'

import { useCurrentUser } from './useCurrentUser'

export const usePermissions = () => {
  // TODO: analyze why user can be undefined if this is used when user is already logged in
  // we should use useUser hook
  const { user } = useCurrentUser()
  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()

  const isAnOrganizationUser = user?.organizationRole

  const isFeatureSetGrupoDiana =
    user?.organizationRole?.organization.featureSetId === FeatureSet.GRUPO_DIANA

  const activeCompany = user?.companiesRoles?.find(
    companyRole => companyRole.company.id === activeCompanyId,
  )

  const isCompanyAdminUser =
    (activeCompany?.role ?? UserCompanyRole.VIEWER) === UserCompanyRole.ADMIN

  const satelliteImageryFlag = activeCompany?.company.satelliteImageryFlag ?? !isAnOrganizationUser

  const activeCompanyFieldEntityFlag = activeCompany?.company.organization?.fieldEntityFlag
  const fieldEntityFlag =
    activeCompanyFieldEntityFlag ?? user?.organizationRole?.organization.fieldEntityFlag ?? true

  const hasAnOrganizationCompany = user?.companiesRoles?.some(
    company => company.company?.organization?.id,
  )

  const isCommercialUser = user?.organizationRole?.role === UserOrganizationRole.COMMERCIAL

  const isUserNotAllowedToCreateCompany =
    (hasAnOrganizationCompany && !isAnOrganizationUser) ||
    (isAnOrganizationUser && !isCommercialUser)

  const permissions = {
    satelliteImagery: satelliteImageryFlag,
    fieldEntity: fieldEntityFlag,
    companyCreation: !isUserNotAllowedToCreateCompany,
    isCompanyAdminUser,
    isAnOrganizationUser,
    isFeatureSetGrupoDiana,
    isCommercialUser,
  }

  return { permissions }
}
