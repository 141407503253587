import { Button as BaseButton } from 'antd'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { LeftArrowIcon as BaseLeftArrowIcon } from 'assets/icons'
import { I18NService } from 'services'
import { usePermissions } from 'hooks'

const Button = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.grey2};
  color: ${({ theme }) => theme.colors.olive5};
  height: 48px;
  width: 190px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey2};
    color: ${({ theme }) => theme.colors.olive5};
    border-color: ${({ theme }) => theme.colors.grey2};
  }
`

const LeftArrowIcon = styled(BaseLeftArrowIcon)`
  font-size: 20px;
  position: absolute;
  left: 15px;
`

export const GoBackButton = () => {
  const history = useHistory()
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)
  const { permissions } = usePermissions()

  return (
    <Button
      icon={<LeftArrowIcon width={4} height={8} />}
      onClick={() => history.push('/dashboard')}
    >
      {permissions.fieldEntity ? t('goBackButtonText') : t('goBackButtonTextFieldFlagOff')}
    </Button>
  )
}
