import es from 'date-fns/locale/es'

export const DATE_FORMATS = {
  DATE: 'yyyy-MM-dd',
  DAY: 'dd MMM',
  DAY_WITH_YEAR: 'dd MMM yyyy',
  HOURS_AND_SECS: `HH:mm 'hs'`,
  MONTH: 'MMMM',
  STRING_DAY: 'iii',
}

// TODO: Remove once we complete internationalization
export const DATE_LOCALES = {
  ES: es,
}
