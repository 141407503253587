import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { VALIDATIONS } from 'consts'
import { I18NService } from 'services'

interface Form {
  password: string
}

export const useChangePasswordForm = () => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMMON)

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(
        VALIDATIONS.PASSWORD_MIN_LENGTH,
        t('validations.minLength', { minLength: VALIDATIONS.PASSWORD_MIN_LENGTH }),
      )
      .required(t('validations.required')),
  })

  return useForm<Form>({ resolver: yupResolver(validationSchema) })
}
