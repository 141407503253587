import { useCallback, useEffect, useMemo, useState } from 'react'
import { orderBy, sortedUniqBy } from 'lodash'

import { MapContext } from 'features/MainRouter/contexts'
import { SentinelHubProvider } from 'types'

import { useLots } from './useLots'
import { Lot } from './useLots/api'
import { ToolMode } from '../types'

// TODO: Move the logic from the map, that we think is important to be here, in order to optimize this component and make the code easier to read than it is.
// like: grainHumidityHasNotemErgenceDaysActivation, estimatedYieldHasNotFormula, formatTimeLineData etc..

interface Props {
  seasonId?: number
  fieldId?: number
  fromTime: Date
  toTime: Date
  toolMode: ToolMode
}

export const useMap = ({ seasonId, fromTime, toTime, fieldId, toolMode }: Props) => {
  const [lots, setLots] = useState<Lot[]>([])
  const [selectedLots, setSelectedLots] = useState<Lot[]>([])
  const [error, setError] = useState<boolean>(false)

  const {
    selectedMapIndex,
    setSelectedMapIndex,
    selectedTimeline,
    selectedCompareMapIndex,
    setSelectedCompareMapIndex,
    setSelectedTimeline,
    selectedCompareTimeline,
    setSelectedCompareTimeline,
    compareLots,
    setCompareLots,
  } = MapContext.useMapContext()
  const isEstimatedYieldIndexSelected = selectedMapIndex === 'estimatedYield'
  const isCompareModeSelected = toolMode === 'compare'
  const isEstimatedYieldCompareMapIndexSelected = selectedCompareMapIndex === 'estimatedYield'

  const { data, loading } = useLots({ seasonId, fromTime, toTime, fieldId, setError })

  const isGrainHumidityNotAvailable = !selectedLots?.some(
    lot =>
      lot.riceLot.catalog.dates.find(date => date.date === selectedTimeline)?.mapUrls.grainHumidity
        ?.isAvailable,
  )
  const isEstimatedYieldNotAvailable = !selectedLots?.some(
    lot => lot.riceLot.estimatedYield.isAvailable,
  )

  const selectedLotsFilterDates = useMemo(
    () =>
      selectedLots.map(lot => {
        // TODO: We have two providers (SENTINEL2 and PLANET), to prefer SENTINEL images, we order by date and by descending provider. When removing duplicates, we keep the first one
        const lotDatesSortedByDate = orderBy(
          lot.riceLot.catalog.dates,
          ['date', 'provider'],
          ['asc', 'desc'],
        )

        const lotFilterDuplicateDates = sortedUniqBy(lotDatesSortedByDate, 'date')

        return {
          ...lot,
          riceLot: {
            ...lot.riceLot,
            catalog: {
              ...lot.riceLot.catalog,
              dates: lotFilterDuplicateDates,
            },
          },
        }
      }),
    [selectedLots],
  )

  const allLotsByDateArePlanet = useCallback(
    selectedDate => {
      if (!selectedDate) return false
      if (!selectedLotsFilterDates.length) return false

      const HAS_ONLY_ONE_LOT = selectedLotsFilterDates.length === 1

      return selectedLotsFilterDates.every(lot => {
        const selectedLot = lot.riceLot.catalog.dates.find(date => date.date === selectedDate)
        if (!selectedLot && HAS_ONLY_ONE_LOT) return false
        return selectedLot?.provider !== SentinelHubProvider.SENTINEL2
      })
    },
    [selectedLotsFilterDates],
  )

  const allSelectedLotsArePlanet = useMemo(
    () => allLotsByDateArePlanet(selectedTimeline),
    [allLotsByDateArePlanet, selectedTimeline],
  )

  const allSelectedCompareLotsArePlanet = useMemo(
    () => allLotsByDateArePlanet(selectedCompareTimeline),
    [allLotsByDateArePlanet, selectedCompareTimeline],
  )

  useEffect(() => {
    if (allSelectedLotsArePlanet) {
      if (selectedMapIndex !== 'realImage') setSelectedMapIndex('realImage')
    } else if (
      // change indicator to 'irrigation' if 'grainHumidity or estimatedYield' was selected and in the new selectedTimeline grain humidity is not available
      (selectedMapIndex === 'grainHumidity' && isGrainHumidityNotAvailable) ||
      (selectedMapIndex === 'estimatedYield' && isEstimatedYieldNotAvailable)
    ) {
      setSelectedMapIndex('irrigation')
    }

    if (allSelectedCompareLotsArePlanet) {
      if (selectedCompareMapIndex !== 'realImage') setSelectedCompareMapIndex('realImage')
    } else if (
      // change indicator to 'irrigation' if 'grainHumidity or estimatedYield' was selected and in the new selectedTimeline grain humidity is not available
      (selectedCompareMapIndex === 'grainHumidity' && isGrainHumidityNotAvailable) ||
      (selectedCompareMapIndex === 'estimatedYield' && isEstimatedYieldNotAvailable)
    ) {
      setSelectedCompareMapIndex('irrigation')
    }
  }, [
    isEstimatedYieldNotAvailable,
    isGrainHumidityNotAvailable,
    selectedMapIndex,
    setSelectedMapIndex,
    selectedLots,
    selectedCompareMapIndex,
    setSelectedCompareMapIndex,
    allSelectedLotsArePlanet,
    allSelectedCompareLotsArePlanet,
  ])

  useEffect(() => {
    const newLots = data?.lots.results.map(lot => {
      const newDates = [...lot.riceLot.catalog.dates].reverse()
      return {
        ...lot,
        riceLot: {
          ...lot.riceLot,
          catalog: {
            ...lot.riceLot.catalog,
            dates: [
              ...newDates,
              ...(isCompareModeSelected
                ? compareLots.find(stateLot => stateLot?.id === lot.id)?.riceLot.catalog.dates ?? []
                : lots.find(stateLot => stateLot?.id === lot.id)?.riceLot.catalog.dates ?? []),
            ],
          },
        },
      }
    })

    if (!newLots) return
    if (isCompareModeSelected) {
      setCompareLots(newLots ?? [])
      // We need set lots without new dates for don't break dates structure in general map, but if user left from compare mode
      // general map should be updated with last field and lots selected, exist an unique case where we need set new dates it is when
      // user enter in compare images mode, change the field and field's lots doesn't have dates yet, because that we
      // need set new dates.
      setLots(
        data?.lots.results.map(lot => {
          const lotDates =
            lots.find(stateLot => stateLot?.id === lot.id)?.riceLot.catalog.dates ?? []
          return {
            ...lot,
            riceLot: {
              ...lot.riceLot,
              catalog: {
                ...lot.riceLot.catalog,
                dates: [
                  ...lotDates,
                  ...(lotDates.length === 0 ? [...lot.riceLot.catalog.dates].reverse() : []),
                ],
              },
            },
          }
        }) ?? [],
      )

      return
    }
    setCompareLots(newLots ?? [])
    setLots(newLots ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const setCompareMapData = () => {
    setSelectedCompareTimeline(selectedTimeline)
    if (selectedMapIndex === 'irrigation') setSelectedCompareMapIndex('chlorophyll')
  }

  return {
    selectedMapIndex,
    setSelectedMapIndex,
    isGrainHumidityNotAvailable,
    isEstimatedYieldNotAvailable,
    isEstimatedYieldIndexSelected,
    allSelectedLotsArePlanet,
    selectedCompareMapIndex,
    setSelectedCompareMapIndex,
    setSelectedTimeline,
    selectedCompareTimeline,
    setSelectedCompareTimeline,
    selectedTimeline,
    setCompareMapData,
    compareLots,
    isLotsLoading: loading,
    lots: isCompareModeSelected ? compareLots : lots,
    setLots,
    isCompareModeSelected,
    isEstimatedYieldCompareMapIndexSelected,
    selectedLots: selectedLotsFilterDates,
    setSelectedLots,
    error,
    allSelectedCompareLotsArePlanet,
  }
}
