import { Table as AntTable, Space as AntSpace, Input, Select } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { DatePicker as BaseDatePicker } from 'components'
import { DateUtils } from 'utils'
import { TrashIcon } from 'assets/icons'
import { I18NService } from 'services'
import { Language } from 'types/i18n'

import { AppliedPhytosanitaries, RiceActiveIngredient, RiceUnit } from '../../api'
import { DeleteAplicationData, DeleteType } from '../types'
import { useUpdateRicePhytosanitary } from './hooks'

const Space = styled(AntSpace)`
  width: 100%;
`
const DatePicker = styled(BaseDatePicker)`
  padding: 6px;
`

const StyledSelect = styled(Select)`
  width: 74px;
`
const IngredientsSelect = styled(Select)`
  width: 140px;
`

const StyledInput = styled(Input)`
  width: 50px;
  padding-left: 9px !important;
  padding-right: 9px !important;
`

const TrashButton = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.grey3};
`

const Table = styled(AntTable)<AppliedPhytosanitaries>`
  & .ant-table-cell {
    border-bottom: unset;
    padding: 5px;
  }

  & th {
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.grey3} !important`};
    background: ${({ theme }) => theme.colors.transparent} !important;
    border-radius: 0px !important;
    text-align: center !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }
  & td:nth-child(even) {
    background: ${({ theme }) => theme.colors.grey2} !important;
  }
  .ant-table-tbody {
    & tr:last-child {
      & td:nth-child(even) {
        border-bottom-left-radius: ${({ theme }) => theme.foundation.borderRadius};
        border-bottom-right-radius: ${({ theme }) => theme.foundation.borderRadius};
      }
    }
  }
  & .ant-table-cell-scrollbar {
    box-shadow: none;
  }
` as typeof AntTable

interface Props {
  appliedPhytosanitaries: AppliedPhytosanitaries[]
  riceUnits: RiceUnit[]
  riceIngredients: RiceActiveIngredient[]
  onDelete: (data: DeleteAplicationData) => void
}

export const PhytosanitaryTable: React.FC<Props> = ({
  appliedPhytosanitaries,
  riceIngredients,
  riceUnits,
  onDelete,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { colors } = useTheme()

  const { updateRicePhytosanitary } = useUpdateRicePhytosanitary()

  const columns: ColumnsType<AppliedPhytosanitaries> = [
    {
      title: t('phytosanitariesTable.dateColumn'),
      render: ({ date, id }) => (
        <DatePicker
          suffixIcon={null}
          placeholder={commonT('inputs.date')}
          format={commonT('dateFormats.medium')}
          defaultValue={DateUtils.parseDate(date)}
          onChange={value =>
            updateRicePhytosanitary({
              variables: {
                id,
                updatePhytosanitaryDTO: { date: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      width: '100px',
    },
    {
      title: t('phytosanitariesTable.productColumn'),
      width: '93px',
      render: ({ name, id }) => (
        <Input
          placeholder={t('phytosanitariesTable.productColumn')}
          defaultValue={name ?? undefined}
          onBlur={(event: React.FormEvent<HTMLInputElement>) => {
            const newName = event.currentTarget.value
            updateRicePhytosanitary({
              variables: {
                id,
                updatePhytosanitaryDTO: { name: newName },
              },
            })
          }}
        />
      ),
    },
    {
      title: t('phytosanitariesTable.brandColumn'),
      width: '93px',
      render: ({ brand, id }) => (
        <Input
          placeholder={t('phytosanitariesTable.brandColumn')}
          defaultValue={brand ?? undefined}
          onBlur={(event: React.FormEvent<HTMLInputElement>) => {
            const newBrand = event.currentTarget.value
            updateRicePhytosanitary({
              variables: {
                id,
                updatePhytosanitaryDTO: { brand: newBrand },
              },
            })
          }}
        />
      ),
    },
    {
      title: t('phytosanitariesTable.doseColumn'),
      width: '125px',
      render: ({ unit, quantity, id }) => (
        <Space size={4}>
          <StyledInput
            defaultValue={quantity ?? undefined}
            onBlur={(event: React.FormEvent<HTMLInputElement>) => {
              const newQuantity = Number(event.currentTarget.value)
              updateRicePhytosanitary({
                variables: {
                  id,
                  updatePhytosanitaryDTO: { quantity: newQuantity },
                },
              })
            }}
          />
          <StyledSelect
            placeholder={commonT('systems.doseSurfaceDensity')}
            defaultValue={unit?.id}
            dropdownStyle={{
              minWidth: '80px',
            }}
            options={riceUnits.map(riceUnit => ({
              label: riceUnit.locales[i18next.resolvedLanguage as Language],
              value: riceUnit.id,
            }))}
            onChange={value =>
              updateRicePhytosanitary({
                variables: {
                  id,
                  updatePhytosanitaryDTO: { unitId: Number(value) },
                },
              })
            }
          />
        </Space>
      ),
    },
    {
      title: t('phytosanitariesTable.activeIngredient', { count: 2 }),
      width: '320px',
      render: ({ activeIngredients, id }: AppliedPhytosanitaries) => {
        return (
          <Space size={8}>
            <IngredientsSelect
              placeholder={t('phytosanitariesTable.activeIngredient', { count: 1 })}
              dropdownStyle={{
                minWidth: '150px',
              }}
              options={riceIngredients.map(riceIngredient => ({
                label: riceIngredient.locales[i18next.resolvedLanguage as Language],
                value: riceIngredient.id,
              }))}
              defaultValue={activeIngredients?.[0] ? activeIngredients[0].id : undefined}
              onChange={value => {
                const newActiveIngredients =
                  activeIngredients?.map(activeIngredient => activeIngredient.id) ?? []
                newActiveIngredients[0] = Number(value)
                updateRicePhytosanitary({
                  variables: {
                    id,
                    updatePhytosanitaryDTO: { activeIngredients: newActiveIngredients },
                  },
                })
              }}
            />
            {t('phytosanitariesTable.andSeparator')}
            <IngredientsSelect
              placeholder={t('phytosanitariesTable.activeIngredient', { count: 1 })}
              dropdownStyle={{
                minWidth: '150px',
              }}
              options={riceIngredients.map(riceIngredient => ({
                label: riceIngredient.locales[i18next.resolvedLanguage as Language],
                value: riceIngredient.id,
              }))}
              defaultValue={activeIngredients?.[1] ? activeIngredients[1].id : undefined}
              onChange={value => {
                const newActiveIngredients =
                  activeIngredients?.map(activeIngredient => activeIngredient.id) ?? []
                if (newActiveIngredients.length >= 1) newActiveIngredients[1] = Number(value)
                else newActiveIngredients[0] = Number(value)
                updateRicePhytosanitary({
                  variables: {
                    id,
                    updatePhytosanitaryDTO: { activeIngredients: newActiveIngredients },
                  },
                })
              }}
            />
            <TrashButton
              onClick={() => onDelete({ showModal: true, id, type: DeleteType.PHYTOSANITARY })}
            >
              <TrashIcon color={colors.white} />
            </TrashButton>
          </Space>
        )
      },
    },
  ]

  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={appliedPhytosanitaries}
      rowKey={({ id }) => id}
      scroll={{ y: 200 }}
    />
  )
}
