/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  LotDashboard,
  LotDashboardVariables,
  LotDashboard_lot_riceLot_appliedFertilizers as AppliedFertilizer,
  LotDashboard_lot_riceLot_appliedFertilizers_grades as FertilizerGrades,
  LotDashboard_lot_riceLot_appliedPhytosanitaries as AppliedPhytosanitaries,
  LotDashboard_lot_riceLot_indicators_grainHumidity as GrainHumidity,
  LotDashboard_lot_riceLot_estimatedYield as EstimatedYield,
} from './types/LotDashboard'

export type {
  CreateRiceFertilizerVariables,
  CreateRiceFertilizer,
} from './types/CreateRiceFertilizer'
export type {
  DeleteRiceFertilizerVariables,
  DeleteRiceFertilizer,
} from './types/DeleteRiceFertilizer'

export type {
  RiceUnitsNutrientsAndIngredients,
  RiceUnitsNutrientsAndIngredients_riceUnits_results as RiceUnit,
  RiceUnitsNutrientsAndIngredients_riceNutrients_results as RiceNutrient,
  RiceUnitsNutrientsAndIngredients_riceActiveIngredients_results as RiceActiveIngredient,
  RiceUnitsNutrientsAndIngredientsVariables,
} from './types/RiceUnitsNutrientsAndIngredients'

export type {
  CreateRicePhytosanitaryVariables,
  CreateRicePhytosanitary,
} from './types/CreateRicePhytosanitary'

export type {
  DeleteRicePhytosanitaryVariables,
  DeleteRicePhytosanitary,
} from './types/DeleteRicePhytosanitary'

export type { UpdateRiceLot, UpdateRiceLotVariables } from './types/UpdateRiceLot'

export const LOT_DASHBOARD = gql`
  query LotDashboard($id: Int!, $isSatelliteImageryFlagOn: Boolean!) {
    lot(id: $id) {
      id
      name
      size
      commercialName
      area {
        coordinates
      }
      previousCrop {
        id
      }
      variety {
        id
        name
        locales {
          es
          en
        }
      }
      riceLot {
        targetYieldCurve @include(if: $isSatelliteImageryFlagOn) {
          id
          name
          values {
            daysSinceEmergence
            chlorophyll
          }
        }
        realYieldCurve @include(if: $isSatelliteImageryFlagOn) {
          values {
            daysSinceEmergence
            chlorophyll
          }
        }
        id
        emergenceDate
        plantingDate
        firstIrrigationStartDate
        firstIrrigationEndDate
        floweringDate
        harvestDate
        preparationDate
        burningDate
        earAppearanceDate
        physiologicalMaturityDate
        differentiationDate
        comment
        yield
        indicators @include(if: $isSatelliteImageryFlagOn) {
          semaphore
          sufficientWaterPercentage
          irrigation
          chlorophyll
          weatherType
          lastUpdate
          grainHumidity {
            isAvailable
            emergenceDaysActivation
            low {
              lowEdge
              highEdge
              percentage
            }
            mediumLow {
              lowEdge
              highEdge
              percentage
            }
            mediumHigh {
              lowEdge
              highEdge
              percentage
            }
            high {
              lowEdge
              highEdge
              percentage
            }
          }
        }
        appliedFertilizers @include(if: $isSatelliteImageryFlagOn) {
          id
          name
          brand
          date
          quantity
          unit {
            id
          }
          grades {
            grade
            nutrient {
              id
              name
              symbol
            }
          }
        }
        appliedPhytosanitaries @include(if: $isSatelliteImageryFlagOn) {
          id
          name
          brand
          date
          quantity
          unit {
            id
          }
          activeIngredients {
            id
            name
            locales {
              es
              en
            }
          }
        }
        estimatedYield {
          isAvailable
          value
          chlorophyllPeakDate
          noFormula
        }
      }
    }
  }
`

export const CREATE_RICE_FERTILIZER = gql`
  mutation CreateRiceFertilizer($createFertilizerDTO: CreateRiceAppliedFertilizerInput!) {
    createRiceFertilizer(createFertilizerDTO: $createFertilizerDTO) {
      code
      fertilizer {
        id
        name
        brand
        date
        quantity
        unit {
          id
          name
          locales {
            es
            en
          }
        }
        grades {
          grade
          nutrient {
            id
            name
            symbol
            locales {
              es
              en
            }
          }
        }
      }
    }
  }
`

export const DELETE_RICE_FERTILIZER = gql`
  mutation DeleteRiceFertilizer($id: Int!) {
    deleteRiceFertilizer(id: $id) {
      success
    }
  }
`

export const RICE_UNITS_NUTRIENTS_AND_INGREDIENTS = gql`
  query RiceUnitsNutrientsAndIngredients($countryId: String!) {
    riceUnits(countryId: $countryId) {
      results {
        id
        locales {
          es
          en
        }
      }
    }

    riceNutrients(countryId: $countryId) {
      results {
        id
        name
        symbol
        locales {
          es
          en
        }
      }
    }

    riceActiveIngredients(countryId: $countryId) {
      results {
        id
        name
        locales {
          es
          en
        }
      }
    }
  }
`

export const CREATE_RICE_PHYTOSANITARY = gql`
  mutation CreateRicePhytosanitary($createPhytosanitaryDTO: CreateRiceAppliedPhytosanitaryInput!) {
    createRicePhytosanitary(createPhytosanitaryDTO: $createPhytosanitaryDTO) {
      code
      phytosanitary {
        id
        name
        brand
        date
        quantity
        unit {
          id
          name
          locales {
            es
            en
          }
        }
        activeIngredients {
          id
          name
          locales {
            es
            en
          }
        }
      }
    }
  }
`
export const DELETE_RICE_PHYTOSANITARY = gql`
  mutation DeleteRicePhytosanitary($id: Int!) {
    deleteRicePhytosanitary(id: $id) {
      success
    }
  }
`

export const UPDATE_RICE_LOT = gql`
  mutation UpdateRiceLot($id: Int!, $updateRiceLotDTO: UpdateRiceLotInput!) {
    updateRiceLot(id: $id, updateRiceLotDTO: $updateRiceLotDTO) {
      code
      riceLot {
        id
        plantingDate
        emergenceDate
        firstIrrigationStartDate
        firstIrrigationEndDate
        floweringDate
        harvestDate
        preparationDate
        burningDate
        earAppearanceDate
        yield
        physiologicalMaturityDate
        differentiationDate
        comment
        indicators {
          semaphore
          sufficientWaterPercentage
          irrigation
          chlorophyll
          weatherType
          lastUpdate
          grainHumidity {
            isAvailable
            emergenceDaysActivation
            low {
              lowEdge
              highEdge
              percentage
            }
            mediumLow {
              lowEdge
              highEdge
              percentage
            }
            mediumHigh {
              lowEdge
              highEdge
              percentage
            }
            high {
              lowEdge
              highEdge
              percentage
            }
          }
        }
        targetYieldCurve {
          id
          yield
          values {
            daysSinceEmergence
            chlorophyll
          }
        }
        realYieldCurve {
          values {
            daysSinceEmergence
            chlorophyll
          }
        }
        estimatedYield {
          isAvailable
          value
          chlorophyllPeakDate
          noFormula
        }
      }
    }
  }
`
