import { gql } from '@apollo/client'

export type {
  User,
  UserVariables,
  // eslint-disable-next-line camelcase
  User_user_companiesRoles as UserUserCompanyRole,
} from './types/User'

export type { UpdateUser, UpdateUserVariables } from './types/UpdateUser'

export const USER = gql`
  query User($id: Int!) {
    user(id: $id) {
      id
      email
      name
      lastName
      profilePicture
      isOnboarded
      companiesRoles {
        role
        company {
          id
          name
          logo
          satelliteImageryFlag
          organization {
            id
            fieldEntityFlag
          }
          country {
            id
            lng
            code
          }
        }
      }
      organizationRole {
        role
        organization {
          id
          name
          logo
          featureSetId
          fieldEntityFlag
          country {
            id
            lng
            code
          }
        }
      }
      regions {
        id
        name
        location {
          type
          coordinates
        }
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $updateUserDTO: UpdateUserInput!) {
    updateUser(id: $id, updateUserDTO: $updateUserDTO) {
      user {
        id
        name
        lastName
        profilePicture
      }
    }
  }
`
