import { Row, Space as AntSpace, Typography } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ArrowClockIcon } from 'assets/icons'
import { I18NService } from 'services'

import { NotAvailableText } from './components'

const { Text: AntText } = Typography

const Text = styled(AntText)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.olive};
`
const Space = styled(AntSpace)`
  height: 100%;
  width: 150px;
  justify-content: center;
`

const DateText = styled(AntText)`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.olive};
`

const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  margin-right: 14px;
  align-items: center;
  justify-content: center;
`

interface Props {
  chlorophyllPeakDate?: string
  estimatedPeakMonth?: string
  noFormula?: boolean
}

export const PeakDate: React.FC<Props> = ({
  chlorophyllPeakDate,
  estimatedPeakMonth,
  noFormula,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)

  return (
    <Space direction="vertical" size={16}>
      {chlorophyllPeakDate ? (
        <>
          <Text>{t('yieldCard.peakText')}</Text>
          <Row align="middle">
            <IconContainer>
              <ArrowClockIcon color={colors.olive} />
            </IconContainer>
            <DateText>{chlorophyllPeakDate}</DateText>
          </Row>
        </>
      ) : (
        <NotAvailableText estimatedPeakMonth={estimatedPeakMonth} noFormula={noFormula} />
      )}
    </Space>
  )
}
