import { gql } from '@apollo/client'

export type { CompanyUserInvited, CompanyUserInvitedVariables } from './types/CompanyUserInvited'

export const COMPANY_USER_INVITED = gql`
  query CompanyUserInvited($id: Int!) {
    company(id: $id) {
      id
      name
      logo
    }
  }
`
