import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button } from 'components'
import { useUser } from 'hooks'
import { ConversionUtils } from 'utils'
import { I18NService } from 'services'

import { useCreateCompany, useCreateOrganizationCompanyForm } from '../../../../hooks'
import { CreateClientForm } from './components'

export const CreateClientCard = () => {
  const { t } = useTranslation(I18NService.NAMESPACES.ONBOARDING)
  const history = useHistory()

  const { control, handleSubmit } = useCreateOrganizationCompanyForm()
  const user = useUser()

  const onSuccess = () => {
    history.replace('/dashboard')
  }

  const { createCompany, loading } = useCreateCompany(onSuccess)

  return (
    <Space direction="vertical" size={60} style={{ width: '100%' }}>
      <CreateClientForm
        control={control}
        organizationCountry={user.organizationRole?.organization.country.id ?? ''}
        commercialExecutiveRegion={user.regions?.length ? user.regions?.[0].name : ''}
      />
      <Button
        style={{ width: '100%' }}
        type="primary"
        loading={loading}
        onClick={handleSubmit(({ companyName, country, governmentId, subregion }) =>
          createCompany(
            {
              name: companyName,
              country,
              organizationId: user.organizationRole?.organization.id,
              governmentId: governmentId
                ? ConversionUtils.keepOnlyNumbers(governmentId)
                : undefined,
              subregionId: subregion,
            },
            user.id,
          ),
        )}
      >
        {t('firstClient.buttonText')}
      </Button>
    </Space>
  )
}
