import { Language } from 'types/i18n'
/**
 * Language format lng-REGION (es-AR)
 * lng => 2 letters, ISO 639-1
 * REGION => optional, 2 letters, uppercase, ISO 3166-1 alpha-2
 */

export const LANGUAGE_CODES: { ES: Language; US: Language } = {
  ES: 'es',
  US: 'en',
}
