import { Typography, Row, Col, Space } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { EllipsisText, EllipsisTitle } from 'components'
import { Indicators } from 'types'
import { DashboardIcon } from 'assets/icons'

import { Indicator } from '../../../../components'
import { PopupInfo } from '../../types'

const Container = styled(Row)`
  flex-direction: column;
`

const Header = styled(Row)`
  padding: 16px;
  border-radius: 4px 4px 0px 0px;
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
`

const Body = styled(Space).attrs({
  direction: 'vertical',
  size: 8,
})`
  padding: 16px;
`

const FullWidthRow = styled(Row)`
  border-radius: 4px 4px 0px 0px;
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]};
  width: 100%;
`

const { Text: AntText } = Typography

const Text = styled(AntText)`
  font-size: 11px !important;
  font-family: Inter;
  font-weight: 600;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
`

const DaysText = styled(Text)`
  padding: 4px 20px;
  border-radius: 4px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
`

const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.new.baseColor[850]};
  cursor: pointer;
`
interface Props {
  data: PopupInfo
}

export const LotCard: React.FC<Props> = ({ data }) => {
  const { colors } = useTheme()
  const history = useHistory()
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { t } = useTranslation(I18NService.NAMESPACES.MAP)

  return (
    <Container>
      <Header>
        <EllipsisTitle
          title={data.name}
          overlayInnerStyle={{
            fontWeight: 600,
            fontSize: '13px',
            color: colors.white,
            fontFamily: 'Inter',
          }}
        />
      </Header>
      <Body>
        <FullWidthRow style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <DaysText>
            {data.daysSinceEmergencyDate} {commonT('vocabulary.daysAfterEmergenceAbbreviation')}
          </DaysText>
          <IconContainer onClick={() => history.push(`/dashboard/lots/${data.id}`)}>
            <DashboardIcon color={colors.white} width={17} height={17} />
          </IconContainer>
        </FullWidthRow>
        <FullWidthRow>
          <Col span={10}>
            <Text>{t('lotPopup.size')}</Text>
          </Col>
          <Col span={14}>
            <EllipsisText
              text={commonT('systemsFormat.area', { val: data.size })}
              overlayInnerStyle={{
                fontWeight: 600,
                width: '100%',
                fontSize: '11px',
                color: colors.new.baseColor[400],
                fontFamily: 'Inter',
              }}
            />
          </Col>
        </FullWidthRow>
        <FullWidthRow>
          <Col span={10}>
            <Text>{t('lotPopup.variety')}</Text>
          </Col>
          <Col span={14}>
            <EllipsisText
              text={data.variety ?? '-'}
              overlayInnerStyle={{
                fontWeight: 600,
                width: '100%',
                fontSize: '11px',
                color: colors.new.baseColor[400],
                fontFamily: 'Inter',
              }}
            />
          </Col>
        </FullWidthRow>
        <Space direction="vertical" size={5}>
          <Text>{t('lotPopup.satelliteIndices')}</Text>
          <Row gutter={8}>
            <Col>
              <Indicator type={Indicators.IRRIGATION} indicatorStatus={data.irrigationIndicator} />
            </Col>
            <Col>
              <Indicator
                type={Indicators.CHLOROPHYLL}
                indicatorStatus={data.chlorophyllIndicator}
              />
            </Col>
          </Row>
        </Space>
      </Body>
    </Container>
  )
}
