import { useTranslation } from 'react-i18next'

import { LogoColoredWithTextIcon } from 'assets/icons'
import { I18NService } from 'services'
import { usePermissions } from 'hooks'

import {
  Layout,
  Container,
  Title,
  Description,
  CreateClientCard,
  CreateCompanyCard,
} from './components'

export const Onboarding = () => {
  const { t } = useTranslation(I18NService.NAMESPACES.ONBOARDING)

  const { permissions } = usePermissions()

  const translationType = permissions.isAnOrganizationUser ? 'firstClient' : 'firstCompany'

  return (
    <Layout>
      <Container direction="vertical" size={60}>
        <LogoColoredWithTextIcon color="white" />
        <div>
          <Title level={3}>{t(`${translationType}.title`)}</Title>
          <Description level={5}>{t(`${translationType}.description`)}</Description>
        </div>
        {permissions.isAnOrganizationUser ? <CreateClientCard /> : <CreateCompanyCard />}
      </Container>
    </Layout>
  )
}
