import { Modal as AntModal, ModalProps } from 'antd'

type Props = ModalProps & { size?: 'small' | 'large' }

export const Modal: React.FC<Props> = ({ children, size, ...props }) => {
  const width = size === 'small' ? 500 : 750
  return (
    <AntModal width={width} {...props} closable={false} maskClosable>
      {children}
    </AntModal>
  )
}
