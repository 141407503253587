import { Col, Row, Divider as AntDivider, Typography, Space } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { IndicatorStatus, Indicators } from 'types'
import { I18NService } from 'services'

import { Indicator } from '../../../../../../components'
import { Chart } from './components'
import { PendingEmergenceDateTooltip } from '../PendingEmergenceDateTooltip'

const { Title: AntTitle, Text: AntText } = Typography

const TargetYieldCard = styled(Row)`
  background: ${({ theme }) => theme.colors.grey1};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  padding: 24px;
  flex: 1;
`
const Spacer = styled(Space).attrs({
  direction: 'vertical',
  size: 24,
})`
  width: 100%;
`
const HeaderContainer = styled(Row)`
  flex: 1;
`
const ChartContainer = styled(Row)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  width: 100%;
  height: 370px;
  border: 2px solid ${({ theme }) => theme.colors.grey2};
  padding: 10px 0;
`
const Divider = styled(AntDivider)`
  height: 24px !important;
  border-left: ${({ theme }) => `2px solid ${theme.colors.grey2} !important `};
  margin: 0 12px;
`

const Title = styled(AntTitle)`
  color: ${({ theme }) => theme.colors.olive};
  font-weight: 600 !important;
  margin-bottom: 0 !important;
`
const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.olive} !important;
  font-size: 14px !important;
  font-weight: normal;
`

type Values = {
  daysSinceEmergence: number
  chlorophyll: number
}
interface Props {
  targetYield?: number | null
  yieldName?: string
  targetYieldCurveValues?: Values[]
  emergenceDate?: string
  realYieldCurveValues?: Values[]
  chlorophyllIndicator?: IndicatorStatus
}

export const TargetYield: React.FC<Props> = ({
  emergenceDate,
  realYieldCurveValues,
  yieldName,
  targetYieldCurveValues,
  chlorophyllIndicator,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)

  return (
    <PendingEmergenceDateTooltip hasEmergenceDate={!!emergenceDate}>
      <TargetYieldCard>
        <Spacer>
          <HeaderContainer justify="space-between" wrap={false}>
            <Row gutter={[24, 24]}>
              <Title level={5}>{t('targetYieldCard.title')}</Title>
              <Divider type="vertical" />
              <Text>{t('targetYieldCard.description')}</Text>
            </Row>
            <Col>
              <Indicator type={Indicators.CHLOROPHYLL} indicatorStatus={chlorophyllIndicator} />
            </Col>
          </HeaderContainer>
          <ChartContainer>
            <Chart
              emergenceDate={emergenceDate}
              yieldName={yieldName}
              targetYieldCurveValues={targetYieldCurveValues}
              realYieldCurveValues={realYieldCurveValues}
            />
          </ChartContainer>
        </Spacer>
      </TargetYieldCard>
    </PendingEmergenceDateTooltip>
  )
}
