import MapboxDraw from '@mapbox/mapbox-gl-draw'
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect'
import { useControl } from 'react-map-gl'
import { useEffect } from 'react'

import { useEditorStyles } from './hooks'
import { DrawMode, Features } from '../../types'

interface Props {
  mapOptions: ConstructorParameters<typeof MapboxDraw>[0]
  mode:
    | {
        mode: Exclude<DrawMode, 'direct_select'>
      }
    | { mode: 'direct_select'; options: { featureId: string } }
  features: Features
  onCreate: (evt: { features: Features }) => void
  onUpdate: (evt: { features: Features; action: string }) => void
  onDelete: (evt: { features: Features }) => void
  onSelectionChange: (evt: { features: Features }) => void
}

export const Control: React.FC<Props> = ({
  mapOptions,
  onCreate,
  onUpdate,
  onDelete,
  onSelectionChange,
  mode,
  features,
}) => {
  const styles = useEditorStyles()
  const control = useControl(
    ({ map }) => {
      map.on('draw.create', onCreate)
      map.on('draw.update', onUpdate)
      map.on('draw.delete', onDelete)
      map.on('draw.selectionchange', onSelectionChange)
      map.on('draw.actionable', () => ({
        actions: {
          trash: false,
        },
      }))

      return new MapboxDraw({ ...mapOptions, styles })
    },
    ({ map }) => {
      map.off('draw.create', onCreate)
      map.off('draw.update', onUpdate)
      map.off('draw.delete', onDelete)
      map.off('draw.selectionchange', onSelectionChange)
      map.off('draw.actionable', () => ({
        actions: {
          trash: false,
        },
      }))
    },
  )

  const drawControl = control as MapboxDraw

  useDeepCompareEffect(() => {
    drawControl.set({
      type: 'FeatureCollection',
      features: features ?? [],
    })
  }, [features, mode])

  useEffect(() => {
    switch (mode.mode) {
      case 'simple_select': {
        drawControl.changeMode(mode.mode)
        break
      }
      case 'direct_select': {
        const modeOptions = { ...mode.options, featureId: mode.options.featureId }
        drawControl.changeMode(mode.mode, modeOptions)
        break
      }
      default: {
        drawControl.changeMode(mode.mode)
      }
    }
  }, [drawControl, mode])

  return null
}
