import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { Col, notification } from 'antd'
import { MapLayerMouseEvent } from 'mapbox-gl'
import ReactMapGL, { Layer, Marker, Source, useMap } from 'react-map-gl'
import { useLocation, useHistory } from 'react-router-dom'
import { point, points, polygon } from '@turf/helpers'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'
import center from '@turf/center'
import { useTheme } from 'styled-components'
import centroid from '@turf/centroid'
import { Result } from '@mapbox/mapbox-gl-geocoder'

import { I18NService } from 'services'
import { ConversionUtils, MiscUtils } from 'utils'
import { MAP } from 'consts'
import { FieldsSelect } from 'components'
import type { SelectField } from 'components/fields-selects/types'
import { ActiveCompanyContext } from 'contexts'
import { LotsMapLocationState } from 'types'
import { usePermissions } from 'hooks'
import { config } from 'config'

import { DeleteLotConfirmationModal, MapSidebar, SearchBoxContainer } from '../../components'
import {
  FieldsSelectContainer,
  LotsCard,
  Container,
  Control,
  TooltipTextContainer,
  TooltipText,
  GeocoderControl,
} from './components'
import { DrawMode, Features, Lot, MandatoryFeature, ShowToolTipCoords } from './types'
import { useCreateLots, useLots } from './hooks'
import { useDeleteLot } from '../../hooks'
import { useUpdateLots } from './hooks/useUpdateLots'

// TODO: Check how to have for now a preselected crop across whole apps since we are
// only using rice crop as a possible crop to monitor.
const CROP_ID = 1

export const LotsMap: React.FC = () => {
  const { colors } = useTheme()
  const history = useHistory()
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_SETTINGS)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { permissions } = usePermissions()
  const { activeSeasonId, activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()

  const {
    state: { fieldId, seasonId: locationSeasonId, lotId, goTo },
  } = useLocation<LotsMapLocationState>()
  const seasonId = locationSeasonId ?? activeSeasonId

  const { createLots, loading: loadingCreateLots } = useCreateLots()
  const { updateLots, loading: loadingUpdateLots } = useUpdateLots()

  const [field, setField] = useState<SelectField>()

  const { lots: existingLots, loadingLots } = useLots(field?.id, seasonId)
  const { deleteLot, loading: deleteLotLoading } = useDeleteLot()

  const [selectedLotToDelete, setSelectedLotToDelete] = useState<Lot>()
  const [showDeleteLotModal, setShowDeleteLotModal] = useState(false)

  const { lotMap } = useMap()

  const onChangeLotTransition = useCallback(
    ({ longitude, latitude, zoom }) => {
      lotMap.flyTo({
        center: [longitude, latitude],
        duration: MAP.DEFAULT_TRANSITION.transitionDuration,
        zoom,
        essential: true,
      })
    },
    [lotMap],
  )

  const [viewport, setViewport] = useState({
    latitude: MAP.DEFAULT_CENTER.LATITUDE,
    longitude: MAP.DEFAULT_CENTER.LONGITUDE,
    zoom: MAP.ZOOM.DEFAULT,
  })
  const [selectedLotIndex, setSelectedLotIndex] = useState<number>()
  const [lots, setLots] = useState<Lot[]>([])
  const [mode, setMode] = useState<
    | {
        mode: Extract<DrawMode, 'direct_select'>
        options: { featureId: string }
      }
    | { mode: Exclude<DrawMode, 'direct_select'> }
  >({
    mode: 'simple_select',
  })

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        (event.key === 'Backspace' ||
          event.key === 'Esc' ||
          event.key === 'Escape' ||
          event.key === 'Delete') &&
        mode.mode === 'draw_polygon'
      ) {
        setMode({ mode: 'simple_select' })
        setLots(prevLots => {
          const newLots = [...prevLots]
          newLots.splice(newLots.length - 1, 1)
          return newLots
        })
      }
    },
    [mode],
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  const onCreateFeature = useCallback(({ features }: { features: Features }) => {
    if (!features) return

    const createdFeature = features[0]
    const createdFeatureId = createdFeature.id
    if (!createdFeatureId) return

    setLots(prevLots => {
      const newLots = [...prevLots]
      const createdLotIndex = prevLots.findIndex(lot => !lot.feature)
      // Workaround for show again lot on the map when user delete last lot and cancels deletion
      if (createdLotIndex === -1) return prevLots
      newLots[createdLotIndex] = {
        ...newLots[createdLotIndex],
        lot: { ...newLots[createdLotIndex].lot, id: createdFeatureId },
        feature: features[0],
      }
      return newLots
    })
    setMode({
      mode: 'direct_select',
      options: { featureId: createdFeatureId.toString() },
    })
  }, [])

  const onUpdateFeature = useCallback(({ features }: { features: Features }) => {
    if (!features) return
    const updatedFeature = features[0]

    setLots(prevLots => {
      const newLots = [...prevLots]

      const updatedLotIndex = newLots.findIndex(lot => lot.lot.id === updatedFeature.id)
      if (!newLots[updatedLotIndex].lot.isPlanetEnabled)
        newLots[updatedLotIndex] = { ...newLots[updatedLotIndex], feature: updatedFeature }
      return newLots
    })
  }, [])

  const onSelectLot = (lotIndex: number) => {
    const lot = lots[lotIndex]
    // This is for the edge case when a user creates a lot, selects from the card another lot
    // before drawing the recently created one and then comes back to that freshly created lot
    if (!lot.feature) {
      setMode({ mode: 'draw_polygon' })
      return
    }

    const lotGeometry = lot.feature?.geometry
    if (lotGeometry) goToLot(lotGeometry)

    if (!lot.lot.isPlanetEnabled) {
      setMode({ mode: 'direct_select', options: { featureId: lot.lot.id.toString() } })
      return
    }

    setSelectedLotIndex(lotIndex)
    setMode({ mode: 'simple_select' })
  }
  const onAddLot = useCallback(() => {
    const lotWithoutFeature = lots.some(lot => !lot.feature)
    if (lotWithoutFeature) return
    setSelectedLotIndex(lots.length)
    setLots(prevLots => [
      ...prevLots,
      {
        lot: { id: MiscUtils.generateId(), cropId: CROP_ID },
        feature: undefined,
        action: 'create',
      },
    ])
    setMode({ mode: 'draw_polygon' })
  }, [lots])

  const onChangeLot = (lot: Lot['lot'], lotIndex: number) =>
    setLots(prevLots => {
      const newLots = [...prevLots]
      newLots[lotIndex].lot = lot
      return newLots
    })

  const onDeleteLot = (lotIndex: number) => {
    const lotToDelete = lots[lotIndex]
    setSelectedLotToDelete(lotToDelete)
    setShowDeleteLotModal(true)
  }

  const [confirmDelete, setConfirmDelete] = useState(false)

  const onConfirmDeleteLot = async () => {
    if (!selectedLotToDelete) return
    const lotIndex = lots.findIndex(lot => lot.lot.id === selectedLotToDelete.lot.id)
    if (selectedLotToDelete.action === 'update') {
      await deleteLot({ variables: { id: Number(selectedLotToDelete.lot.id) } })
    } else {
      const existingLot = lots.find(lot => lot.lot.id === selectedLotToDelete.lot.id)
      setLots(prevLots => {
        const newLots = [...prevLots]
        newLots.splice(lotIndex, 1)
        // Workaround for when a user deletes a lot by pressing 'backspace', the lot has already been filtered from state
        // in the onDelete function
        return !existingLot ? prevLots : newLots
      })
    }
    setConfirmDelete(true)
    setSelectedLotToDelete(undefined)
    setShowDeleteLotModal(false)
  }

  // Workaround for when a lot is deleted, go to the last of the updated lot list
  useEffect(() => {
    if (confirmDelete) {
      const lastLot = lots[lots.length - 1]
      if (lastLot) {
        if (lastLot.feature) {
          goToLot(lastLot.feature.geometry)
          const lastLotId = lastLot.lot.id?.toString()
          if (!lastLotId || lastLot.lot.isPlanetEnabled) return
          setMode({ mode: 'direct_select', options: { featureId: lastLotId } })
          setSelectedLotIndex(lots.length - 1)
        } else {
          setMode({ mode: 'draw_polygon' })
        }
      } else setMode({ mode: 'simple_select' })
      setConfirmDelete(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDelete, lots])

  const goToLot = useCallback(
    lot => {
      if (lot) {
        const featurePoint = points(lot.coordinates[0])
        const lotCenter = center(featurePoint)
        const longitude = lotCenter.geometry.coordinates[0]
        const latitude = lotCenter.geometry.coordinates[1]
        if (lotMap) onChangeLotTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })
      }
    },
    [lotMap, onChangeLotTransition],
  )

  const onCancelDeleteLot = () => {
    if (!selectedLotToDelete) return
    setMode({ mode: 'simple_select' })
    const existingLot = lots.find(lot => lot.lot.id === selectedLotToDelete.lot.id)
    // Workaround for when a user deletes a lot by pressing 'backspace', we need to set that lot again in the state
    if (!existingLot) {
      // It covers the case when user has only one lot and is deleting by bakspace, to no add a new empty lot
      if (lots.length === 1 && !lots[0].feature) {
        setLots([selectedLotToDelete])
      } else {
        setLots(prevLots => [...prevLots, selectedLotToDelete])
      }
    }

    setSelectedLotToDelete(undefined)
    setShowDeleteLotModal(false)
  }

  const onConfirmLots = async () => {
    if (!field || !seasonId) return

    const createLotDTOs = lots
      .filter(({ action }) => action === 'create')
      .map(({ lot, feature }) => ({
        name: lot.name!,
        commercialName: lot.commercialName ?? undefined,
        area: feature!.geometry,
        cropId: lot.cropId!,
        varietyId: lot.varietyId,
        seasonId,
        fieldId: field.id,
      }))
    const updateLotDTOs = lots
      .filter(({ action }) => action === 'update')
      .map(({ lot, feature }) => ({
        id: Number(lot.id),
        name: lot.name!,
        commercialName: lot.commercialName ?? undefined,
        area: feature!.geometry,
        varietyId: lot.varietyId,
      }))

    if (createLotDTOs.length) {
      await createLots({ variables: { createLotDTOs } })
    }
    if (updateLotDTOs) {
      await updateLots({ variables: { updateLotDTOs } })
    }
    if (goTo) {
      history.push(goTo)
      return
    }
    history.goBack()
  }

  const lotFeatures = useMemo(() => {
    const lotsWithFeature = lots.filter(
      ({ feature, lot }) => !!feature && !lot.isPlanetEnabled,
    ) as (Omit<Lot, 'feature'> & {
      feature: MandatoryFeature
    })[]
    return lotsWithFeature.map(({ feature, lot }) => {
      return { ...feature, id: lot.id.toString() }
    })
  }, [lots])

  const lotsWithPlanet = useMemo(
    () =>
      existingLots
        ?.filter(lot => lot.riceLot.isPlanetEnabled)
        .map(lot => ({
          id: lot.id.toString(),
          feature: polygon(lot.area.coordinates),
          area: lot.area,
        })),
    [existingLots],
  )

  const handleKml = async ({ file: uploadFile }: UploadChangeParam<UploadFile>) => {
    try {
      const file = uploadFile as unknown as File
      const geoJson = await MiscUtils.kmlFileToGeoJson(file)

      const kmlLots: Lot[] = geoJson.features.map(feature => ({
        action: 'create',
        feature: {
          geometry: feature.geometry,
          properties: {},
          type: 'Feature',
        },
        lot: {
          id: MiscUtils.generateId(),
          cropId: CROP_ID,
          name: ConversionUtils.autoCapitalize(feature.properties.name),
        },
      }))
      setMode({ mode: 'simple_select' })

      setLots(prevLots => {
        // this workaround is to remove all empty lots
        const filterLots = prevLots.filter(lot => lot.feature !== undefined)
        const newLots = [...filterLots, ...kmlLots]
        setSelectedLotIndex(newLots.length - 1)
        goToLot(newLots[newLots.length - 1].feature?.geometry)
        return newLots
      })
    } catch (err) {
      notification.error({
        message: t('uploadKMLErrorText'),
      })
    }
  }

  const handleChange = useCallback((newField: SelectField) => {
    // if the effect was already called once, and the field changes we filter those that lots created not confirmed
    if (initLotsEffectCalled.current)
      setLots(prevLots => prevLots.filter(lot => lot.action !== 'create'))

    setField(newField)
  }, [])

  const onMove = useCallback(evt => setViewport(evt.viewState), [])

  const onDelete = useCallback(e => {
    setLots(prevLots => {
      const index = prevLots.findIndex(({ lot }) => lot.id === e.features[0].id)
      const lotToDelete = prevLots[index]
      setSelectedLotToDelete(lotToDelete)
      setShowDeleteLotModal(true)
      return prevLots.filter(lot => lot.lot.id !== lotToDelete.lot.id)
    })
  }, [])

  const onSelectionChange = useCallback(e => {
    if (e.features.length) {
      return setLots(prevLots => {
        const index = prevLots.findIndex(({ lot }) => lot.id === e.features[0].id)
        const selectedLot = prevLots[index]
        if (selectedLot?.lot.isPlanetEnabled) setMode({ mode: 'simple_select' })
        setSelectedLotIndex(index)
        return prevLots
      })
    }
    return setSelectedLotIndex(undefined)
  }, [])

  const initLotsEffectCalled = useRef(false)

  const setLotsWithNewLot = (existedLots: Lot[]) => {
    const newLots: Lot[] = [
      ...existedLots,
      {
        lot: { id: MiscUtils.generateId(), cropId: CROP_ID },
        feature: undefined,
        action: 'create',
      },
    ]
    setLots(newLots)
    setSelectedLotIndex(newLots.length - 1)
    setMode({ mode: 'draw_polygon' })
    const latitude = field?.location.coordinates[1]
    const longitude = field?.location.coordinates[0]

    if (lotMap) onChangeLotTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })
  }

  const setSelectedLotList = (existedLots: Lot[]) => {
    setLots(prevLots => {
      // this filter is to only set the lots created before deleting a lot from DB, and not set a new empty lot.
      const filterPrevLots = prevLots.filter(
        lot => lot.feature && lot.lot.name && lot.action === 'create',
      )
      return [...existedLots, ...filterPrevLots]
    })
    if (existingLots) {
      setSelectedLotIndex(existingLots.findIndex(lot => lot.id === lotId))
      const preselectedLot = existingLots.find(lot => lot.id === lotId)
      if (!preselectedLot) return

      const lotCoordinates = preselectedLot.location.coordinates
      const featurePoint = point(lotCoordinates)
      const lotCenter = center(featurePoint)
      const longitude = lotCenter.geometry.coordinates[0]
      const latitude = lotCenter.geometry.coordinates[1]
      onChangeLotTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })

      const { isPlanetEnabled } = preselectedLot.riceLot
      if (lotId && !isPlanetEnabled)
        setMode({ mode: 'direct_select', options: { featureId: lotId.toString() } })
    }
  }

  useEffect(() => {
    if (loadingLots || !field || !lotMap) return
    if (existingLots?.length) {
      const existedLots: Lot[] = existingLots.map(lot => ({
        lot: {
          id: lot.id.toString(),
          name: lot.name,
          commercialName: lot.commercialName,
          cropId: lot.crop.id,
          varietyId: lot.variety?.id,
          isPlanetEnabled: lot.riceLot.isPlanetEnabled,
        },
        feature: polygon(lot.area.coordinates),
        action: 'update',
      }))

      if (!lotId && !initLotsEffectCalled.current) {
        setLotsWithNewLot(existedLots)
      } else if (lotId && !initLotsEffectCalled.current) {
        setSelectedLotList(existedLots)
      } else {
        setLots(prevLots => {
          // this filter is to only set the lots created before deleting a lot from DB, and not set a new empty lot.
          const filterPrevLots = prevLots.filter(
            lot => lot.feature && lot.lot.name && lot.action === 'create',
          )
          return [...existedLots, ...filterPrevLots]
        })

        setSelectedLotIndex(0)
        const firstLot = existedLots[0]
        if (firstLot && firstLot.feature) {
          goToLot(firstLot.feature.geometry)
          const { isPlanetEnabled } = firstLot.lot
          if (!isPlanetEnabled)
            setMode({ mode: 'direct_select', options: { featureId: firstLot.lot.id.toString() } })
        }
      }
    } else {
      setLots([
        {
          lot: { id: MiscUtils.generateId(), cropId: CROP_ID },
          feature: undefined,
          action: 'create',
        },
      ])
      setSelectedLotIndex(0)
      setMode({ mode: 'draw_polygon' })
      const latitude = field.location.coordinates[1]
      const longitude = field.location.coordinates[0]
      onChangeLotTransition({ longitude, latitude, zoom: MAP.ZOOM.CLOSE })
    }
    initLotsEffectCalled.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingLots, field, loadingLots, lotId, lotMap])

  const [showTooltipCoords, setShowTooltipCoords] = useState<ShowToolTipCoords>()

  const onClickLot = useCallback(
    (event: MapLayerMouseEvent) => {
      if (showTooltipCoords) {
        setShowTooltipCoords(undefined)
        return
      }
      const { features } = event
      const feature = features?.[0]
      if (!feature) return

      const lot = lotsWithPlanet?.find(lotToFind => lotToFind.id === feature.layer.id)

      if (!lot) return

      const polygonLot = polygon(lot.area.coordinates)
      const polygonCenter = centroid(polygonLot)

      setShowTooltipCoords({
        latitude: polygonCenter.geometry.coordinates[1] + 0.003,
        longitude: polygonCenter.geometry.coordinates[0],
      })
    },
    [lotsWithPlanet, showTooltipCoords],
  )

  const interactiveLayerIds = useMemo(() => lotsWithPlanet?.map(lot => lot.id), [lotsWithPlanet])

  const geocoderContainerRef = useRef<HTMLDivElement>(null)

  const onResult = useCallback(({ result }: { result: Result }) => {
    const location =
      result &&
      (result.center || (result.geometry?.type === 'Point' && result.geometry.coordinates))

    if (location) {
      setViewport({ longitude: location[0], latitude: location[1], zoom: MAP.ZOOM.DEFAULT })
    }
  }, [])

  return (
    <>
      <MapSidebar>
        <Col flex={1}>
          {permissions.isFeatureSetGrupoDiana && (
            <SearchBoxContainer ref={geocoderContainerRef} id="geocoder-container" />
          )}
          <FieldsSelectContainer justify="end" hidden={!permissions.fieldEntity}>
            <FieldsSelect companyId={activeCompanyId} onChange={handleChange} defaultId={fieldId} />
          </FieldsSelectContainer>
          <LotsCard
            lots={lots}
            selectedLotIndex={selectedLotIndex}
            field={field}
            onSelectLot={onSelectLot}
            onAddLot={onAddLot}
            onChangeLot={onChangeLot}
            onDeleteLot={onDeleteLot}
            onConfirmLots={onConfirmLots}
            loading={loadingLots || deleteLotLoading || loadingCreateLots || loadingUpdateLots}
            handleKml={handleKml}
            onDrawMode={mode.mode === 'draw_polygon'}
          />
        </Col>
      </MapSidebar>
      <Container>
        <ReactMapGL
          {...viewport}
          mapStyle={MAP.STYLES.SATELLITE_STREET}
          onMove={onMove}
          cursor={mode.mode === 'draw_polygon' ? 'crosshair' : 'grab'}
          id="lotMap"
          onClick={onClickLot}
          interactiveLayerIds={showTooltipCoords ? undefined : interactiveLayerIds}
        >
          {permissions.isFeatureSetGrupoDiana && (
            <GeocoderControl
              geocoderOptions={{
                accessToken: config.mapboxToken,
                language: MAP.LANGUAGES.ES,
                placeholder: t('geocoderPlaceholder'),
                marker: false,
              }}
              containerRef={geocoderContainerRef}
              onResult={onResult}
            />
          )}
          <Control
            mapOptions={{
              displayControlsDefault: false,
              controls: {
                polygon: true,
                trash: true,
              },
            }}
            onCreate={onCreateFeature}
            onUpdate={onUpdateFeature}
            onDelete={onDelete}
            onSelectionChange={onSelectionChange}
            features={lotFeatures}
            mode={mode}
          />
          {lotsWithPlanet?.map(({ id, feature }) => (
            <Source key={id} type="geojson" id={id} data={polygon(feature.geometry.coordinates)}>
              <Layer
                id={id}
                type="fill"
                paint={{
                  'fill-color': colors.yellow,
                  'fill-opacity': 0.5,
                }}
              />
              <Layer
                id={`lot-${id}`}
                type="line"
                paint={{
                  'line-color': colors.yellow,
                  'line-width': 2,
                }}
              />
            </Source>
          ))}
          {showTooltipCoords && (
            <Marker longitude={showTooltipCoords.longitude} latitude={showTooltipCoords.latitude}>
              <TooltipTextContainer>
                <TooltipText>{commonT('messages.highFrequencyLot')}</TooltipText>
              </TooltipTextContainer>
            </Marker>
          )}
        </ReactMapGL>
      </Container>
      {showDeleteLotModal && selectedLotToDelete && field && (
        <DeleteLotConfirmationModal
          lotName={selectedLotToDelete.lot.name!}
          fieldName={field.name}
          onConfirm={onConfirmDeleteLot}
          onCancel={onCancelDeleteLot}
          loading={deleteLotLoading}
        />
      )}
    </>
  )
}
