import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { notification } from 'antd'
import { isToday, differenceInDays } from 'date-fns'

import { DateUtils } from 'utils'

import { LotsVariables, LOTS, Lots } from './api'

interface Props {
  seasonId?: number
  fieldId?: number
  fromTime: Date
  toTime: Date
  setError: (error: boolean) => void
}

export const useLots = ({ seasonId, fromTime, toTime, fieldId, setError }: Props) => {
  const isSpecificDayRequest = differenceInDays(toTime, fromTime) === 0
  return useQuery<Lots, LotsVariables>(LOTS, {
    variables: {
      seasonId: seasonId!,
      fieldId: fieldId!,
      fromTime: DateUtils.formatToUtc(fromTime),
      // We don't wanna subtract one day in the first call or requests from compare images
      toTime:
        isToday(toTime) || isSpecificDayRequest
          ? DateUtils.formatToUtc(toTime)
          : DateUtils.formatToUtcAndSubtractOneDay(toTime),
    },
    onError: error => {
      Sentry.captureException(error)
      notification.error({
        duration: 0,
        message: 'Ocurrió un error, por favor intente refrescando la página',
      })
      setError(true)
    },
    skip: !fieldId || !seasonId,
  })
}
