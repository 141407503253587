import { Col, Row, List as AntList, Typography, Button as AntButton } from 'antd'
import styled from 'styled-components'

export { UserSettings } from './UserSettings'
export { CreateCompany } from './CreateCompany'
export { PopoverSearchInput } from './PopoverSearchInput'
export { OrganizationItem } from './OrganizationItem'

const { Text: AntText, Title: AntTitle } = Typography

export const List = styled(AntList)`
  max-height: 280px;
  overflow: hidden auto;
  & .ant-list-item {
    padding: 0 0 8px 0;
  }
`

export const ListItem = styled(List.Item)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 0 !important;
`

export const FullWidthRow = styled(Row)`
  flex: 1;
  width: 100%;
`

export const Title = styled(AntTitle)`
  margin-bottom: 0 !important;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white} !important;
`

export const Text = styled(AntText)`
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
`

interface UploadProps {
  $isActive?: boolean
}

export const LogoContainer = styled(Col)<UploadProps>`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: ${({ $isActive, theme }) =>
    $isActive
      ? `4px solid ${theme.colors.new.secondary[800]}`
      : `2px solid ${theme.colors.new.baseColor[200]}`};
  overflow: hidden;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
`
export const Button = styled(AntButton)`
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: ${({ theme }) => theme.colors.new.secondary[600]};
  & span {
    font-size: 13px;
    font-style: normal;
    line-height: 24px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.new.secondary[400]};
  }
`

export const Divider = styled.div`
  height: 1px;
  border: ${({ theme }) => `1px solid ${theme.colors.grey3}`};
  margin: 20px 0;
`

export const Container = styled.div`
  padding: 0 30px;
`
