import { Space, Typography } from 'antd'
import styled from 'styled-components'

import { Button } from 'components'

const { Title } = Typography

export { CompanyUsersTable } from './CompanyUsersTable'
export { InviteUserModal } from './InviteUserModal'
export { LeaveCompanyModal } from './LeaveCompanyModal'
export { UpdateClientForm } from './UpdateClientForm'
export { UpdateCompanyForm } from './UpdateCompanyForm'

export const UsersSectionTitle = styled(Title)`
  margin-bottom: 0px !important;
`

export const ButtonsContainer = styled(Space).attrs({
  size: 'large',
})`
  margin: 22px 0;

  & div:last-child > button {
    background-color: ${({ theme }) => theme.colors.grey3};
    color: ${({ theme }) => theme.colors.white};
  }
`

export const StyledButton = styled(Button)`
  width: 100%;
`
