import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { ActiveCompanyContext } from 'contexts'
import { GraphQLUtils } from 'utils'

import { Fields, FieldsSettingsVariables, FIELDS_SETTINGS } from '../../../api'
import { DeleteField, DeleteFieldVariables, DELETE_FIELD } from '../api'

export const useFieldsSettings = () => {
  const history = useHistory()
  const { url } = useRouteMatch()

  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()

  const [selectedFieldIdToDelete, setSelectedFieldIdToDelete] = useState<number>()
  const [showDeleteFieldModal, setShowDeleteFieldModal] = useState(false)

  const { data, loading: loadingFields } = useQuery<Fields, FieldsSettingsVariables>(
    FIELDS_SETTINGS,
    {
      variables: { companyId: activeCompanyId! },
      skip: !activeCompanyId,
    },
  )

  const [deleteField, { loading: deleteFieldLoading }] = useMutation<
    DeleteField,
    DeleteFieldVariables
  >(DELETE_FIELD, {
    update: async cache => {
      const cachedFields = cache.readQuery<Fields>({
        query: FIELDS_SETTINGS,
        variables: { companyId: activeCompanyId },
      })

      if (!cachedFields) return

      const filteredFields = cachedFields.fields.results.filter(
        field => field.id !== selectedFieldIdToDelete,
      )

      cache.writeQuery({
        query: FIELDS_SETTINGS,
        data: { fields: { ...cachedFields.fields, results: filteredFields } },
        variables: { companyId: activeCompanyId },
      })
    },
    onCompleted: () => {
      setSelectedFieldIdToDelete(undefined)
      setShowDeleteFieldModal(false)
    },
    onError: GraphQLUtils.errorHandler,
  })

  const onAddField = () => history.push(`${url}/fields`)

  const onEditField = (id: number) => history.push(`${url}/fields/${id}`)

  const onDeleteField = (id: number) => {
    setSelectedFieldIdToDelete(id)
    setShowDeleteFieldModal(true)
  }

  const onConfirmDeleteField = () => {
    if (!selectedFieldIdToDelete) return
    deleteField({ variables: { id: selectedFieldIdToDelete } })
  }

  const onCancelDeleteField = () => {
    setSelectedFieldIdToDelete(undefined)
    setShowDeleteFieldModal(false)
  }

  return {
    showDeleteFieldModal: showDeleteFieldModal && !!selectedFieldIdToDelete,
    fields: data?.fields.results,
    loadingFields,
    deleteFieldLoading,
    onAddField,
    onEditField,
    onDeleteField,
    onConfirmDeleteField,
    onCancelDeleteField,
  }
}
