import styled from 'styled-components'
import { Col, Typography } from 'antd'

export { Chart } from './Chart'

const { Title: AntTitle } = Typography

export const Title = styled(AntTitle)`
  font-weight: normal !important;
  margin-bottom: 0px !important;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.grey1} !important;
`

export const FlexCol = styled(Col)`
  display: flex;
`
export const StyledCol = styled(Col)`
  width: 170px;
  height: 230px;
`
