import { Col, Row } from 'antd'
import styled from 'styled-components'
import React from 'react'

import { IndicatorStatus } from 'types'

import { PendingEmergenceDateTooltip } from '../PendingEmergenceDateTooltip'
import { TrafficLights } from '../../../components'

const TrafficLightContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: ${({ theme }) => `3px solid ${theme.colors.trafficLightNeutral} !important `};
  padding: 6px;
  min-width: 36px;
  min-height: 84px;
`

const Container = styled(Row)`
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  padding: 9px;
  background-color: ${({ theme }) => theme.colors.grey1};
  max-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 81px;
  height: 100%;
`

interface Props {
  hasEmergenceDate: boolean
  semaphoreIndicator?: IndicatorStatus
}

export const TrafficLight: React.FC<Props> = ({ hasEmergenceDate, semaphoreIndicator }) => {
  return (
    <Container>
      <PendingEmergenceDateTooltip hasEmergenceDate={hasEmergenceDate}>
        <TrafficLightContainer>
          <TrafficLights
            size="large"
            hasEmergenceDate={hasEmergenceDate}
            semaphoreIndicator={semaphoreIndicator}
          />
        </TrafficLightContainer>
      </PendingEmergenceDateTooltip>
    </Container>
  )
}
