import { useCallback, useState } from 'react'
import { Row, Col } from 'antd'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { Season, SeasonLot } from 'api'
import { FieldsSelect, ListCard } from 'components'
import { ActiveCompanyContext } from 'contexts'
import { LotsMapLocationState } from 'types'
import { usePermissions } from 'hooks'
import type { SelectField } from 'components/fields-selects/types'

import { useDeleteLot } from '../../../../hooks'
import { DeleteLotConfirmationModal } from '../../../../components'

const FieldsSelectContainer = styled(Row)`
  margin-bottom: 24px;
`

interface Props {
  season: Season
}

export const SeasonLots: React.FC<Props> = ({ season }) => {
  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()
  const history = useHistory<LotsMapLocationState>()
  const { permissions } = usePermissions()
  const { t } = useTranslation(I18NService.NAMESPACES.SEASON_SETTINGS)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)

  const [selectedFieldId, setSelectedFieldId] = useState<number>()
  const [selectedLotToDelete, setSelectedLotToDelete] = useState<SeasonLot>()
  const [showDeleteLotModal, setShowDeleteLotModal] = useState(false)

  const { deleteLot, loading: deleteLotLoading } = useDeleteLot(() => {
    setSelectedLotToDelete(undefined)
    setShowDeleteLotModal(false)
  })

  const selectedSeasonField = season.seasonFields.find(
    seasonField => seasonField.field?.id === selectedFieldId,
  )

  const onDeleteLot = (id: number) => {
    const selectedLot = selectedSeasonField?.lots.find(lot => lot.id === id)
    if (!selectedLot) return
    setSelectedLotToDelete(selectedLot)
    setShowDeleteLotModal(true)
  }

  const onConfirmDeleteLot = () => {
    if (!selectedLotToDelete) return
    deleteLot({ variables: { id: selectedLotToDelete.id } })
  }

  const onCancelDeleteLot = () => {
    setSelectedLotToDelete(undefined)
    setShowDeleteLotModal(false)
  }

  const handleChange = useCallback((newField: SelectField) => {
    setSelectedFieldId(newField.id)
  }, [])

  const selectedFieldLots = selectedSeasonField?.lots.map(lot => ({
    ...lot,
    isDisabled: lot.riceLot.isPlanetEnabled,
  }))

  return (
    <>
      <ListCard
        data={selectedFieldLots ?? []}
        isAdminUser={permissions.isCompanyAdminUser}
        title={t('lotsCard.title', { context: permissions.fieldEntity && 'withFieldEntity' })}
        description={t('lotsCard.description', {
          context: permissions.fieldEntity && 'withFieldEntity',
        })}
        header={
          <>
            {permissions.isAnOrganizationUser ? (
              <Row>
                <Col span={12}>{t('lotsCard.table.lotText')}</Col>
                <Col>{t('lotsCard.table.commercialName')}</Col>
              </Row>
            ) : (
              <Row>
                <Col span={12}>{t('lotsCard.table.fieldText')}</Col>
                <Col>{t('lotsCard.table.lotText')}</Col>
              </Row>
            )}
          </>
        }
        headerSubsection={
          // For now we just hide the FieldsSelect so logic behind the scenes still works and the
          // first and only field (only one when flag fieldEntity is off) gets selected by default
          <FieldsSelectContainer hidden={!permissions.fieldEntity}>
            <FieldsSelect companyId={activeCompanyId} onChange={handleChange} />
          </FieldsSelectContainer>
        }
        buttonDescription={t('lotsCard.table.addLotsButtonText')}
        onClick={() =>
          history.push('/settings/lots', { seasonId: season.id, fieldId: selectedFieldId })
        }
        onEdit={id =>
          history.push(`/settings/lots`, {
            seasonId: season.id,
            fieldId: selectedFieldId,
            lotId: id,
          })
        }
        onDelete={onDeleteLot}
        renderItem={item => (
          <>
            {permissions.isAnOrganizationUser ? (
              <>
                <Col span={12}>{item.name}</Col>
                <Col>{item.commercialName}</Col>
              </>
            ) : (
              <>
                <Col span={12}>{selectedSeasonField?.field?.name}</Col>
                <Col>{item.name}</Col>
              </>
            )}
          </>
        )}
        disabledTooltipText={commonT('messages.highFrequencyLot')}
      />
      {showDeleteLotModal && selectedLotToDelete && selectedSeasonField && (
        <DeleteLotConfirmationModal
          lotName={selectedLotToDelete.name}
          fieldName={selectedSeasonField.field!.name}
          onConfirm={onConfirmDeleteLot}
          onCancel={onCancelDeleteLot}
          loading={deleteLotLoading}
        />
      )}
    </>
  )
}
