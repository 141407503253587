import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { Divider as AntDivider } from 'antd'
import styled, { useTheme } from 'styled-components'
import { format, parseISO } from 'date-fns'
import { orderBy, uniqBy } from 'lodash'

import { DatePicker as BaseDatePicker } from 'components'
import { DATE_FORMATS } from 'consts'
import { CloudyIcon } from 'assets/icons'
import { MapContext } from 'features/MainRouter/contexts'

import { DateState } from '../../types'
import { CalendarDates, CatalogDates } from '../../hooks/useLots/api'

export const Divider = styled(AntDivider)`
  height: 32px !important;
  border-left: ${({ theme }) => `2px solid ${theme.colors.new.baseColor[400]}`} !important;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Panel = styled.div`
  .ant-picker-cell {
    background: white;

    &:before {
      background: white !important;
    }
  }
  .ant-picker-cell-disabled {
    background: white;
  }
  .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 0px;
  }
`

const DatePicker = styled(BaseDatePicker)`
  width: 50px;
  padding: 7px 0;
  input {
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.white};
  }
` as unknown as typeof BaseDatePicker

type CompareTimelineData = {
  id: number
  calendar: CalendarDates[]
  dates: CatalogDates[]
}

interface CompareTimelineDataProps {
  compareTimelineData: CompareTimelineData[]
  onChange: (date?: string) => void
  defaultValue?: string
  setDates: Dispatch<SetStateAction<DateState>>
  isLotsLoading: boolean
  showDatePicker: boolean
}

export const CompareTimeline: React.FC<CompareTimelineDataProps> = ({
  children,
  compareTimelineData,
  onChange,
  defaultValue,
  setDates,
  isLotsLoading,
  showDatePicker,
}) => {
  const { colors } = useTheme()
  const { setSelectedTimeline, selectedTimeline, setSelectedCompareTimeline } =
    MapContext.useMapContext()

  const compareCalendarDates = useMemo(() => {
    const allCalendarDates = compareTimelineData.map(compareData => compareData.calendar).flat()
    const orderedByDate = orderBy(allCalendarDates, ['date', 'isAvailable'], ['desc', 'desc'])
    const calendarDateswithoutDuplicates = uniqBy(orderedByDate, 'date')

    return calendarDateswithoutDuplicates
  }, [compareTimelineData])

  const compareDates = useMemo(
    () => compareTimelineData.map(compareData => compareData.dates).flat(),
    [compareTimelineData],
  )

  const findDateInCalendar = (date: Date) =>
    compareCalendarDates.find(compareDate => compareDate.date === format(date, DATE_FORMATS.DATE))

  const findDateInDates = (date: Date) =>
    compareDates.find(calendarDate => calendarDate.date === format(date, DATE_FORMATS.DATE))

  useEffect(() => {
    if (!compareCalendarDates.length) return
    if (!selectedTimeline && !isLotsLoading) {
      const firstLotAvailable = compareCalendarDates.find(date => date.isAvailable)
      // Workaround to set first available date when field is changing
      setSelectedTimeline(firstLotAvailable?.date)
      setSelectedCompareTimeline(firstLotAvailable?.date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareCalendarDates])

  const disabledDate = (current: Date) => {
    const isDateInCalendar = findDateInCalendar(current)

    if (!isDateInCalendar || !isDateInCalendar.isAvailable) return true

    return false
  }

  const getDateStyleAndInfo = (date: Date) => {
    const isDateInCalendar = findDateInCalendar(date)

    if (!isDateInCalendar || !isDateInCalendar?.isAvailable)
      return { style: { border: '0', borderRadius: '0' }, isDateInCalendar }

    return {
      style: { border: `1px solid ${colors.olive2}`, borderRadius: '100%' },
      isDateInCalendar,
    }
  }
  return (
    <Container>
      {showDatePicker && (
        <>
          <DatePicker
            disabledDate={disabledDate}
            format={DATE_FORMATS.DAY}
            value={defaultValue ? parseISO(defaultValue) : undefined}
            allowClear={false}
            placeholder=""
            bordered={false}
            showToday={false}
            suffixIcon={null}
            panelRender={originalPanel => {
              return <Panel>{originalPanel}</Panel>
            }}
            onChange={date => {
              if (!date) return
              const isDateInDates = findDateInDates(date)

              if (!isDateInDates) {
                const toTime = new Date(date)
                const fromTime = new Date(date)
                setDates({ fromTime, toTime, newDates: true })
              }
              onChange(format(date, DATE_FORMATS.DATE))
            }}
            dateRender={current => {
              const style = getDateStyleAndInfo(current)
              return (
                <div className="ant-picker-cell-inner" style={style.style}>
                  {style.isDateInCalendar && !style.isDateInCalendar.isAvailable ? (
                    <CloudyIcon />
                  ) : (
                    current.getDate()
                  )}
                </div>
              )
            }}
          />
          <Divider type="vertical" />
        </>
      )}

      {children}
    </Container>
  )
}
