import { useRouteMatch, Switch, Route } from 'react-router-dom'

import { FieldsMap, LotsMap, Settings, Season } from './features'

export const SettingsRouter: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/fields/:id?`} component={FieldsMap} />
      <Route exact path={`${path}/lots`} component={LotsMap} />
      <Route exact path={`${path}/season/:id`} component={Season} />
      <Route component={Settings} />
    </Switch>
  )
}
