import { useState, createContext, useContext } from 'react'

import { MapIndex } from 'features/MainRouter/features/Map/types'
import { Lot } from 'features/MainRouter/features/Map/hooks/useLots/api'

interface MapContextType {
  selectedTimeline?: string
  setSelectedTimeline: (date?: string) => void
  selectedMapIndex: MapIndex
  setSelectedMapIndex: (mapIndex: MapIndex) => void
  selectedCompareMapIndex: MapIndex
  setSelectedCompareMapIndex: (mapIndex: MapIndex) => void
  selectedCompareTimeline?: string
  setSelectedCompareTimeline: (date?: string) => void
  compareLots: Lot[]
  setCompareLots: (compareLots: Lot[]) => void
}

// TODO: Move the logic from the map and the timeline, that we think is important to be here, in order to optimize these components and make the code easier to read than it is.
const Context = createContext<MapContextType>({
  selectedMapIndex: 'irrigation',
  selectedCompareMapIndex: 'irrigation',
  setSelectedTimeline: () => {
    throw new Error(
      'Attempted to use default setSelectedTimeline function for MapContext, make sure to pass a custom function implementation',
    )
  },
  setSelectedMapIndex: () => {
    throw new Error(
      'Attempted to use default setSelectedMapIndex function for MapContext, make sure to pass a custom function implementation',
    )
  },
  setSelectedCompareMapIndex: () => {
    throw new Error(
      'Attempted to use default setSelectedCompareMapIndex function for MapContext, make sure to pass a custom function implementation',
    )
  },
  setSelectedCompareTimeline: () => {
    throw new Error(
      'Attempted to use default setSelectedCompareTimeline function for MapContext, make sure to pass a custom function implementation',
    )
  },
  setCompareLots: () => {
    throw new Error(
      'Attempted to use default setCompareLots function for MapContext, make sure to pass a custom function implementation',
    )
  },
  compareLots: [],
})

const useValue = (): MapContextType => {
  const [selectedTimeline, setSelectedTimeline] = useState<string>()
  const [selectedMapIndex, setSelectedMapIndex] = useState<MapIndex>('irrigation')
  const [selectedCompareMapIndex, setSelectedCompareMapIndex] = useState<MapIndex>('irrigation')
  const [selectedCompareTimeline, setSelectedCompareTimeline] = useState<string>()
  const [compareLots, setCompareLots] = useState<Lot[]>([])

  return {
    selectedTimeline,
    setSelectedTimeline,
    selectedMapIndex,
    setSelectedMapIndex,
    selectedCompareMapIndex,
    setSelectedCompareMapIndex,
    selectedCompareTimeline,
    setSelectedCompareTimeline,
    compareLots,
    setCompareLots,
  }
}

const useMapContext = () => {
  return useContext(Context)
}

export const MapContext = {
  Provider: Context.Provider,
  useValue,
  useMapContext,
}
