import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { UserCompanyRole } from 'types'

type Props = Omit<React.ComponentProps<typeof Select>, 'onChange'> & {
  onChange: (role: UserCompanyRole) => void
}

export const RoleSelect: React.FC<Props> = ({ onChange, ...restProps }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMMON)

  const localizeRole = (role: UserCompanyRole) => t(`roles.${role}`)

  return (
    <Select
      onChange={newRole => {
        onChange(newRole as UserCompanyRole)
      }}
      {...restProps}
    >
      {Object.values(UserCompanyRole).map(role => (
        <Select.Option key={role} value={role}>
          {localizeRole(role)}
        </Select.Option>
      ))}
    </Select>
  )
}
