import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { Dashboard, LotDashboard } from './features'

export const DashboardRouter: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/lots/:id`} component={LotDashboard} />
      <Route component={Dashboard} />
    </Switch>
  )
}
