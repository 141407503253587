import { Select as AntSelect } from 'antd'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'

import { Lot } from '../../hooks/useLots/api'

const Select = styled(AntSelect)`
  min-width: 206px;
`

interface Props {
  fieldId?: number
  defaultId?: number
  onChange: (lots: Lot[]) => void
  className?: string
  lots: Lot[]
  loading: boolean
  dropdownClassName?: string
  dropdownMatchSelectWidth?: boolean
}

export const LotsSelect: React.FC<Props> = ({
  fieldId,
  defaultId,
  onChange,
  className,
  loading,
  lots,
  dropdownClassName,
  dropdownMatchSelectWidth,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.MAP)

  const ONLY_ONE_LOT = 1
  const [selectedLotId, setSelectedLotId] = useState<number | undefined>(defaultId)

  useEffect(() => {
    const selectedLot = lots.find(lot => lot.id === selectedLotId)
    if (!selectedLotId || (!selectedLot && lots.length)) {
      onChange(lots)
      setSelectedLotId(undefined)
      return
    }

    if (!selectedLot) return
    onChange([selectedLot])
  }, [lots, onChange, selectedLotId])

  const defaultValue =
    // eslint-disable-next-line no-nested-ternary
    (loading && !lots.length) || !fieldId
      ? undefined
      : lots.length > ONLY_ONE_LOT
      ? selectedLotId ?? 'all-lots'
      : lots[0]?.id

  return (
    <Select
      dropdownClassName={dropdownClassName}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      loading={loading}
      className={className}
      defaultValue={defaultValue}
      key={`${fieldId}-${defaultValue}`}
      onChange={id => {
        const selectedLot = lots.find(lot => lot.id === id)
        if (!selectedLot) {
          setSelectedLotId(undefined)
          return
        }

        setSelectedLotId(id as number)
      }}
    >
      {lots.length > ONLY_ONE_LOT && (
        <Select.Option value="all-lots">{t('lotsSelect.allLots')}</Select.Option>
      )}

      {lots.map(lot => (
        <Select.Option key={lot.id} value={lot.id}>
          {lot.name}
        </Select.Option>
      ))}
    </Select>
  )
}
