import { Redirect, RouteProps, Route } from 'react-router-dom'

import { useAuth } from 'hooks'

export const PrivateRoute: React.FC<RouteProps> = routeProps => {
  const { userId } = useAuth()

  if (!userId) return <Redirect to="/login" />

  return <Route {...routeProps} />
}
