/* eslint-disable i18next/no-literal-string */
import { Form, Space } from 'antd'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { arFlagImage, usaFlagImage, colFlagImage, parFlagImage, urFlagImage } from 'assets/images'
import { ConversionUtils } from 'utils'
import { I18NService } from 'services'
import { useCountries, useCurrentUser, useSubregions } from 'hooks'

import {
  FormContainer as FormSectionContainer,
  FormItemContainer,
  Input,
  Select,
  Image,
} from '../../..'

const flagCountries: {
  [key: string]: string
} = {
  argentina: arFlagImage,
  uruguay: urFlagImage,
  paraguay: parFlagImage,
  'united-states': usaFlagImage,
  colombia: colFlagImage,
}

interface Props {
  control: Control<{
    companyName: string
    country: string
    governmentId?: string
    subregion: number
  }>
  onInputNameBlur?: () => void
  onInputGovernmentIdBlur?: () => void
  organizationCountry: string
  commercialExecutiveRegion: string
  onSelectSubregionBlur?: () => void
}

export const CreateClientForm: React.FC<Props> = ({
  control,
  onInputNameBlur,
  onInputGovernmentIdBlur,
  organizationCountry,
  commercialExecutiveRegion,
  onSelectSubregionBlur,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMPANY_SETTINGS)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { countries, loading } = useCountries()
  const { user } = useCurrentUser()

  const getLocalizedNumber = (value?: string) => {
    if (!value) return ''
    const onlyNumbers = ConversionUtils.keepOnlyNumbers(value)
    if (!onlyNumbers) return ''
    const localizedNumber = commonT('systemsFormat.localizeNumber', {
      val: onlyNumbers,
    })
    return localizedNumber
  }
  const { subregions } = useSubregions(user?.regions?.length ? user?.regions?.[0].id : undefined)

  const subregionOptions = subregions?.map(subregion => {
    return { label: subregion.name, value: subregion.id }
  })

  return (
    <Space direction="vertical" size={35} style={{ width: '100%' }}>
      <FormSectionContainer>
        <FormItemContainer>
          <Controller
            name="companyName"
            control={control}
            render={({
              field: { onChange, onBlur, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Input
                  placeholder={t('inputs.fullName')}
                  size="large"
                  onBlur={() => {
                    onInputNameBlur?.()
                    onBlur()
                  }}
                  {...restFieldProps}
                  onChange={event => onChange(ConversionUtils.autoCapitalize(event.target.value))}
                />
              </Form.Item>
            )}
          />
        </FormItemContainer>
        <FormItemContainer>
          <Controller
            name="governmentId"
            control={control}
            render={({
              field: { onChange, onBlur, value, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Input
                  placeholder={t('inputs.NIT')}
                  type="text"
                  size="large"
                  onBlur={() => {
                    onInputGovernmentIdBlur?.()
                    onBlur()
                  }}
                  value={getLocalizedNumber(value)}
                  {...restFieldProps}
                  onChange={event => onChange(getLocalizedNumber(event.target.value))}
                />
              </Form.Item>
            )}
          />
        </FormItemContainer>
      </FormSectionContainer>
      <FormSectionContainer>
        <FormItemContainer>
          <Controller
            name="country"
            control={control}
            defaultValue={organizationCountry}
            render={({
              field: { onChange, onBlur, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Select
                  disabled
                  placeholder={t('inputs.country')}
                  size="large"
                  loading={loading}
                  onBlur={() => {
                    onInputNameBlur?.()
                    onBlur()
                  }}
                  {...restFieldProps}
                  value={organizationCountry}
                  onChange={event => onChange(event)}
                  dropdownClassName="custom-select-dropdown"
                >
                  {countries?.map(country => (
                    <Select.Option key={country.id} value={country.id}>
                      <Image src={flagCountries[country.id]} />
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          />
        </FormItemContainer>
        <FormItemContainer>
          <Form.Item>
            <Select
              placeholder={t('inputs.region')}
              size="large"
              disabled
              defaultValue={commercialExecutiveRegion}
            />
          </Form.Item>
        </FormItemContainer>
        <FormItemContainer>
          <Controller
            name="subregion"
            control={control}
            render={({
              field: { onChange, onBlur, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Select
                  placeholder={t('inputs.subregion')}
                  size="large"
                  onBlur={() => {
                    onSelectSubregionBlur?.()
                    onBlur()
                  }}
                  {...restFieldProps}
                  onChange={event => onChange(event)}
                  options={subregionOptions}
                  dropdownClassName="custom-select-dropdown"
                />
              </Form.Item>
            )}
          />
        </FormItemContainer>
      </FormSectionContainer>
    </Space>
  )
}
