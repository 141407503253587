import { Col, Row, Space, Button as AntButton } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { usePermissions } from 'hooks'

const FullWidthRow = styled(Row)`
  flex: 1;
`

const NewCompanyContainer = styled(Col)`
  height: 48px;
`

const IconContainer = styled(Col)`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: 2px solid ${({ theme }) => theme.colors.grey2};
  overflow: hidden;
`
const StyledPlusCircleOutlined = styled(PlusCircleOutlined)`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.new.secondary[600]};
`

const NewCompanyButton = styled(AntButton)`
  padding: 0;
  display: flex;
  column-gap: 4px;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.new.secondary[600]};
  & span {
    font-size: 13px;
    font-style: normal;
    line-height: 24px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.new.secondary[400]};
  }
`

const TextButton = styled.span`
  color: ${({ theme }) => theme.colors.white};
`
interface Props {
  isFirstCompany?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}
export const CreateCompany: React.FC<Props> = ({ isFirstCompany, onClick }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.NAVBAR)
  const { permissions } = usePermissions()

  return (
    <FullWidthRow wrap={false}>
      <Col flex={1} onClick={onClick}>
        <Space size="middle">
          <IconContainer>
            <StyledPlusCircleOutlined />
          </IconContainer>
          <NewCompanyContainer span={24}>
            {permissions.isAnOrganizationUser ? (
              <NewCompanyButton type="link">
                {t('user.menu.companies.newClient.part1')}
                <TextButton>{t('user.menu.companies.newClient.part2')}</TextButton>
                <TextButton>{t('user.menu.companies.newClient.part3')}</TextButton>
              </NewCompanyButton>
            ) : (
              <NewCompanyButton type="link">
                {t('user.menu.companies.newCompany.part1')}
                <TextButton>
                  {t('user.menu.companies.newCompany.part2', {
                    context: isFirstCompany && 'firstCompany',
                  })}
                </TextButton>
                <TextButton> {t('user.menu.companies.newCompany.part3')}</TextButton>
              </NewCompanyButton>
            )}
          </NewCompanyContainer>
        </Space>
      </Col>
    </FullWidthRow>
  )
}
