import { useEffect } from 'react'
import { Input, Row, Col, Space, Form, Typography } from 'antd'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@apollo/client'
import { point } from '@turf/helpers'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ConversionUtils, GraphQLUtils } from 'utils'
import { ActiveCompanyContext } from 'contexts'
import { InputTitle, Button } from 'components'
import type { SelectField } from 'components/fields-selects/types'
import {
  COMPANY_FIELDS,
  CompanyFields,
  CompanyFieldsVariables,
} from 'hooks/useHasCompanyOrFieldsOrLots/api'
import { I18NService } from 'services'

import { StyledCard, Title } from '../../../../components'
import {
  CreateField,
  CreateFieldVariables,
  CREATE_FIELD,
  UpdateField,
  UpdateFieldVariables,
  UPDATE_FIELD,
} from './api'
import { Fields, FieldsSettingsVariables, FIELDS_SETTINGS } from '../../../../api'

const { Text: AntText } = Typography

const Text = styled(AntText)`
  font-size: 16px;
  font-style: normal;
`
const StyledRow = styled(Row)`
  font-size: 13px;
  background-color: ${({ theme }) => theme.colors.grey2};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  padding: 14px;
  width: 100%;
`

const TextArea = styled(Input.TextArea)`
  height: 90px !important;
  @media (max-height: 735px) {
    height: 55px !important;
  }
`

const StyledItem = styled(Form.Item)`
  margin-bottom: 0px !important;
`
interface FormType {
  fieldName: string
  directions?: string
}

interface Props {
  field?: SelectField
  markerCoordinates: number[]
  onSuccess: (fieldName: string, fieldId?: number) => void
}

export const FieldCard: React.FC<Props> = ({ field, markerCoordinates, onSuccess }) => {
  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()
  const { t } = useTranslation(I18NService.NAMESPACES.FIELD_SETTINGS)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const validationSchema = yup.object().shape({
    fieldName: yup.string().required(commonT('validations.required')),
    directions: yup.string(),
  })

  const { control, handleSubmit, reset } = useForm<FormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: { fieldName: field?.name ?? '', directions: field?.directions ?? '' },
  })

  useEffect(() => {
    if (field) reset({ fieldName: field.name, directions: field.directions ?? '' })
  }, [field, reset])

  const [createField, { loading: createLoading }] = useMutation<CreateField, CreateFieldVariables>(
    CREATE_FIELD,
    {
      onCompleted: (data: CreateField) => {
        onSuccess(data.createField.field.name, data.createField.field.id)
      },
      update: (cache, { data: response }) => {
        if (!response || !activeCompanyId) return

        const cachedFields = cache.readQuery<Fields, FieldsSettingsVariables>({
          query: FIELDS_SETTINGS,
          variables: { companyId: activeCompanyId },
        })

        const cachedCompanyFields = cache.readQuery<CompanyFields, CompanyFieldsVariables>({
          query: COMPANY_FIELDS,
          variables: { id: activeCompanyId },
        })

        const newField = response.createField.field
        const fields: Fields['fields'] = cachedFields
          ? { ...cachedFields.fields, results: [...cachedFields.fields.results, newField] }
          : { results: [newField], __typename: 'Fields' }

        cache.writeQuery<Fields, FieldsSettingsVariables>({
          query: FIELDS_SETTINGS,
          data: { fields },
          variables: { companyId: activeCompanyId },
        })

        if (!cachedCompanyFields) return

        const companyFields = {
          company: {
            ...cachedCompanyFields.company,
            fields: [...cachedCompanyFields.company.fields!, newField],
          },
        }

        cache.writeQuery<CompanyFields, CompanyFieldsVariables>({
          query: COMPANY_FIELDS,
          data: { ...companyFields },
          variables: { id: activeCompanyId },
        })
      },
      onError: GraphQLUtils.errorHandler,
    },
  )

  const [updateField, { loading: updateLoading }] = useMutation<UpdateField, UpdateFieldVariables>(
    UPDATE_FIELD,
    {
      onCompleted: (data: UpdateField) => {
        onSuccess(data.updateField.field.name)
      },
      onError: GraphQLUtils.errorHandler,
    },
  )

  return (
    <StyledCard $isEditMode={!!field}>
      <Title level={3}>{field ? field.name : t('createField.title')}</Title>
      <Space direction="vertical" size={22}>
        <Text>{field ? t('editField.description') : t('createField.description')}</Text>
        <Row align="middle">
          <Col flex={1}>
            <InputTitle title={t('inputs.newField')} />
            <Controller
              // eslint-disable-next-line i18next/no-literal-string
              name="fieldName"
              control={control}
              render={({
                field: { onChange, ...restFieldProps },
                fieldState: { error: fieldError },
              }) => (
                <StyledItem validateStatus={fieldError && 'error'} help={fieldError?.message}>
                  <Input
                    size="large"
                    onChange={event => onChange(ConversionUtils.autoCapitalize(event.target.value))}
                    {...restFieldProps}
                  />
                </StyledItem>
              )}
            />
          </Col>
        </Row>
        <Row>
          <InputTitle title={t('inputs.coordinates')} />
          <StyledRow>
            <Col span={12}>
              {commonT('vocabulary.latitude')}: {markerCoordinates[1].toFixed(6)}
            </Col>
            <Col span={12}>
              {commonT('vocabulary.longitude')}: {markerCoordinates[0].toFixed(6)}
            </Col>
          </StyledRow>
        </Row>
        <Row>
          <Col flex={1}>
            <InputTitle title={t('inputs.comments')} />
            <Controller
              // eslint-disable-next-line i18next/no-literal-string
              name="directions"
              control={control}
              render={({
                field: { onChange, value, ...restFieldProps },
                fieldState: { error: fieldError },
              }) => (
                <StyledItem validateStatus={fieldError && 'error'} help={fieldError?.message}>
                  <TextArea
                    onChange={event => onChange(event.target.value)}
                    value={value ?? ''}
                    {...restFieldProps}
                  />
                </StyledItem>
              )}
            />
          </Col>
        </Row>
        <Row justify="end">
          <Button
            type="primary"
            loading={createLoading || updateLoading}
            onClick={handleSubmit(({ fieldName, directions }) => {
              if (!activeCompanyId) return
              const markerPoint = point(markerCoordinates)
              if (field) {
                updateField({
                  variables: {
                    id: field.id,
                    updateFieldDTO: {
                      name: fieldName,
                      directions,
                      location: markerPoint.geometry,
                    },
                  },
                })
                return
              }

              createField({
                variables: {
                  createFieldDTO: {
                    name: fieldName,
                    directions,
                    location: markerPoint.geometry,
                    companyId: activeCompanyId,
                  },
                },
              })
            })}
          >
            {field ? t('editField.buttonText') : t('createField.buttonText')}
          </Button>
        </Row>
      </Space>
    </StyledCard>
  )
}
