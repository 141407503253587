import { Row, Col, Input } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

import { Modal, Button, Upload, InputTitle, FormInput } from 'components'
import { useUser, useUpdateUser } from 'hooks'
import { I18NService } from 'services'

import { Description, InputsCol, ButtonsContainer, ChangePasswordModal } from './components'

interface Props {
  onCancel: () => void
}

const validationSchema = yup.object().shape({
  name: yup.string(),
  lastName: yup.string(),
})
interface FormType {
  profilePicture: File
  name: string
  lastName: string
}

export const AccountSettingsModal: React.FC<Props> = ({ onCancel }) => {
  const user = useUser()
  const { t } = useTranslation(I18NService.NAMESPACES.ACCOUNT_SETTINGS)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)

  const { updateUser, loading, error } = useUpdateUser(onCancel)

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [image, setImage] = useState<string>()

  const { handleSubmit, setValue, control } = useForm<FormType>({
    resolver: yupResolver(validationSchema),
  })

  return (
    <>
      <Modal title={t('title')} visible centered footer={null} onCancel={onCancel}>
        <Description level={5}>{t('editText')}</Description>
        <Row gutter={38}>
          <Col>
            <Upload
              title={t('profilePicture.title')}
              allowCrop
              size="large"
              image={image ?? user.profilePicture ?? undefined}
              onChange={(imageResult, file) => {
                setImage(imageResult)
                setValue('profilePicture', file)
              }}
            />
          </Col>
          <InputsCol>
            <Row gutter={38}>
              <Col span={12}>
                <InputTitle title={commonT('inputs.firstName')} />
                <FormInput
                  control={control}
                  defaultValue={user.name}
                  // eslint-disable-next-line i18next/no-literal-string
                  name="name"
                  placeholder={commonT('inputs.firstName')}
                  capitalize
                />
              </Col>
              <Col span={12}>
                <InputTitle title={commonT('inputs.lastName')} />
                <FormInput
                  control={control}
                  defaultValue={user.lastName}
                  // eslint-disable-next-line i18next/no-literal-string
                  name="lastName"
                  placeholder={commonT('inputs.lastName')}
                  capitalize
                />
              </Col>
            </Row>
            <Row>
              <InputTitle title={commonT('inputs.email')} />
              <Input size="large" disabled width="100%" value={user.email} />
            </Row>
          </InputsCol>
        </Row>
        <ButtonsContainer>
          <Button type="primary" onClick={() => setShowChangePasswordModal(true)}>
            {t('buttons.changePassword')}
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={handleSubmit(({ profilePicture, name, lastName }) =>
              updateUser({
                variables: {
                  id: user.id,
                  updateUserDTO: {
                    profilePicture,
                    name,
                    lastName,
                  },
                },
              }),
            )}
          >
            {t('buttons.save')}
          </Button>
        </ButtonsContainer>
      </Modal>
      {showChangePasswordModal && (
        <ChangePasswordModal
          onCancel={() => setShowChangePasswordModal(false)}
          error={error}
          loading={loading}
          onSubmit={({ password, actualPassword }) =>
            updateUser({
              variables: {
                id: user.id,
                updateUserDTO: {
                  password,
                  actualPassword,
                },
              },
            })
          }
        />
      )}
    </>
  )
}
