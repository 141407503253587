import { useHistory } from 'react-router-dom'
import { useMutation, useApolloClient } from '@apollo/client'

import { STORAGE_KEYS } from 'consts'
import { ActiveCompanyContext, AuthContext } from 'contexts'

import { useLocalStorage } from '../useLocalStorage'
import { LoginWithToken, LOGIN_WITH_TOKEN } from './api'

export const useAuth = () => {
  const history = useHistory()
  const client = useApolloClient()
  const { login: contextLogin, logout: contextLogout, userId } = AuthContext.useAuthContext()
  const { setActiveCompany, setActiveSeason } = ActiveCompanyContext.useActiveCompanyContext()

  const [userToken, setUserToken, clearUserToken] = useLocalStorage<string>(STORAGE_KEYS.USER_TOKEN)
  const [, , clearUserLanguage] = useLocalStorage<string>(STORAGE_KEYS.USER_LANGUAGE)

  const logout = async () => {
    await client.clearStore()
    clearUserToken()
    contextLogout()
    clearUserLanguage()
    setActiveCompany({})
    setActiveSeason(undefined)
    history.replace('/login')
  }

  const [tokenLoginMutation, { loading: tokenLoginLoading }] = useMutation<LoginWithToken>(
    LOGIN_WITH_TOKEN,
    {
      onCompleted: data => {
        contextLogin({ userId: data.loginWithToken.user.id })
        setUserToken(data.loginWithToken.token)
      },
      onError: logout,
    },
  )

  const tokenLogin = () => tokenLoginMutation()

  return {
    userId,
    userToken,
    tokenLogin,
    tokenLoginLoading,
    logout,
  }
}
