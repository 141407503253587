import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'

interface Props {
  hasEmergenceDate: boolean
}

export const PendingEmergenceDateTooltip: React.FC<Props> = ({ hasEmergenceDate, children }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)

  if (hasEmergenceDate) return <>{children}</>
  return <Tooltip title={t('emergenceDatePendingTooltip')}>{children}</Tooltip>
}
