import ReactMapGL, { Layer, Source, useMap as useReactMapGl } from 'react-map-gl'
import { polygon } from '@turf/helpers'
import React, { useEffect } from 'react'
import bbox from '@turf/bbox'
import { useTheme } from 'styled-components'

import { MAP } from 'consts'
import { config } from 'config'
import { MapContext } from 'features/MainRouter/contexts'
import { SentinelHubProvider } from 'types'

import { GeoUtils } from '../../utils'
import { Lot } from '../../hooks/useLots/api'

interface Props {
  lots: Lot[]
  initialViewPort: {
    latitude: number
    longitude: number
    zoom: number
  }
  onMount: () => void
}

export const CompareImagesMap: React.FC<Props> = ({ lots, initialViewPort, onMount }) => {
  const { colors } = useTheme()

  const { selectedCompareMapIndex, selectedCompareTimeline } = MapContext.useMapContext()
  const { compareMap } = useReactMapGl()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMount, [compareMap])

  return (
    <ReactMapGL
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
      }}
      mapStyle={MAP.STYLES.SATELLITE_STREET}
      id="compareMap"
      initialViewState={{ ...initialViewPort }}
    >
      {selectedCompareTimeline &&
        lots.map(lot => {
          const foundLot = lots.find(lotToFind => lotToFind.id === lot.id)
          const getImageUrl = () => {
            if (selectedCompareMapIndex === 'estimatedYield')
              return lot.riceLot.estimatedYield.mapUrl ?? undefined

            const selectedLotDate = foundLot?.riceLot.catalog.dates.find(
              date => date.date === selectedCompareTimeline,
            )

            if (
              selectedLotDate?.provider === SentinelHubProvider.PLANET &&
              selectedCompareMapIndex !== 'realImage'
            )
              return undefined
            return selectedLotDate?.mapUrls[selectedCompareMapIndex]?.url ?? undefined
          }

          return (
            <React.Fragment key={lot.id}>
              <>
                {config.isSentinelImageryOn && getImageUrl() && (
                  <Source
                    type="image"
                    id={`image-${lot.id.toString()}`}
                    url={getImageUrl()}
                    coordinates={GeoUtils.bboxCoords(bbox(lot.area))}
                  >
                    <Layer type="raster" id={`image-${lot.id.toString()}`} paint={{}} />
                  </Source>
                )}
                <Source type="geojson" id={lot.id.toString()} data={polygon(lot.area.coordinates)}>
                  <Layer
                    id={lot.id.toString()}
                    type="fill"
                    paint={{
                      'fill-color': getImageUrl() ? 'transparent' : colors.grey3,
                    }}
                  />
                  <Layer
                    id={`lot-${lot.id}`}
                    type="line"
                    paint={{
                      'line-color': colors.olive5,
                      'line-width': 4,
                    }}
                  />
                </Source>
              </>
            </React.Fragment>
          )
        })}
    </ReactMapGL>
  )
}
