import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { LotsMapLocationState } from 'types'
import { ActiveCompanyContext } from 'contexts'
import { I18NService } from 'services'

import { Card } from '../Card'

interface Props {
  isCommercialUser: boolean
}
export const OrganizationEmptyResults: React.FC<Props> = ({ isCommercialUser }) => {
  const history = useHistory<LotsMapLocationState>()
  const { activeSeasonId } = ActiveCompanyContext.useActiveCompanyContext()
  const { t } = useTranslation(I18NService.NAMESPACES.SETTINGS)

  if (!isCommercialUser) return null

  return (
    <Card
      title={t('modals.firstClientLot.title')}
      description={t('modals.firstClientLot.description')}
      onClick={() => history.push('/settings/lots', { seasonId: activeSeasonId, goTo: '/map' })}
      buttonText={t('modals.firstClientLot.buttonText')}
    />
  )
}
