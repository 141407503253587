import { useContext, useState, createContext } from 'react'
import * as Sentry from '@sentry/react'

interface AuthContextType {
  userId?: number
  login: (auth: { userId: number }) => void
  logout: () => void
}

const Context = createContext<AuthContextType>({
  userId: undefined,
  login: () => {
    throw new Error(
      'Attempted to use default login function for AuthContext, make sure to pass a custom function implementation',
    )
  },
  logout: () => {
    throw new Error(
      'Attempted to use default logout function for AuthContext, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): AuthContextType => {
  const [userId, setUserId] = useState<AuthContextType['userId']>()

  const login: AuthContextType['login'] = ({ userId: id }) => {
    setUserId(id)
    Sentry.setUser({ id: id.toString() })
  }

  const logout: AuthContextType['logout'] = () => {
    setUserId(undefined)
    Sentry.configureScope(scope => scope.setUser(null))
  }

  return { userId, login, logout }
}

const useAuthContext = () => {
  return useContext(Context)
}

export const AuthContext = {
  Provider: Context.Provider,
  useValue,
  useAuthContext,
}
