/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { Crops, Crops_crops_results as Crop } from './types/Crops'

export const CROPS = gql`
  query Crops {
    crops {
      results {
        id
        name
        locales {
          es
          en
        }
      }
    }
  }
`
