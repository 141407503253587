/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  FieldsSettings as Fields,
  FieldsSettingsVariables,
  FieldsSettings_fields_results as Field,
} from './types/FieldsSettings'
export type {
  SeasonsSettings as Seasons,
  SeasonsSettingsVariables,
  SeasonsSettings_seasons_results as Season,
} from './types/SeasonsSettings'
export type { DeleteLot, DeleteLotVariables } from './types/DeleteLot'

export const FIELDS_SETTINGS = gql`
  query FieldsSettings($companyId: Int!) {
    fields(companyId: $companyId) {
      results {
        id
        name
      }
    }
  }
`

export const SEASONS_SETTINGS = gql`
  query SeasonsSettings($companyId: Int!) {
    seasons(companyId: $companyId) {
      results {
        id
        name
      }
    }
  }
`

export const DELETE_LOT = gql`
  mutation DeleteLot($id: Int!) {
    deleteLot(id: $id) {
      success
    }
  }
`
