import { Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { VarietiesIcon } from 'assets/icons'
import { I18NService } from 'services'

import { Chart, Title, FlexCol, StyledCol } from './components'
import { Props } from '../../../types'

export const VarietiesCard: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.DASHBOARD)
  return (
    <>
      <FlexCol span={3}>
        <VarietiesIcon />
      </FlexCol>
      <Col span={19}>
        <Title level={5}>{t('varietiesCard.title')}</Title>
      </Col>
      <StyledCol>
        <Chart data={data} />
      </StyledCol>
    </>
  )
}
