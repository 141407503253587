import { Row, Button, Space } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Modal as ModalBase } from 'components'
import { I18NService } from 'services'

const Modal = styled(ModalBase)`
  width: 450px !important;
`

const Description = styled(Row)`
  padding-right: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`

const ButtonsContainer = styled(Space).attrs({
  size: 20,
})`
  width: 100%;
  justify-content: flex-end;
  margin-top: 22px;
  & button {
    width: 114px;
  }

  & div:last-child > button {
    background-color: ${({ theme }) => theme.colors.grey3};
    color: ${({ theme }) => theme.colors.white};
  }
`

interface Props {
  onLeaveCompany: () => void
  onCancel: () => void
  loading: boolean
  isAnOrganizationCompany?: boolean
}

export const LeaveCompanyModal: React.FC<Props> = ({
  onCancel,
  onLeaveCompany,
  loading,
  isAnOrganizationCompany,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMPANY_SETTINGS)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)

  const translationType = isAnOrganizationCompany ? 'leaveFarmerModal' : 'leaveCompanyModal'

  return (
    <Modal title={t(`${translationType}.title`)} visible centered onCancel={onCancel} footer={null}>
      <>
        <Description>{t(`${translationType}.description`)}</Description>
        <ButtonsContainer>
          <Button type="primary" onClick={onCancel}>
            {commonT('buttons.no')}
          </Button>
          <Button onClick={onLeaveCompany} loading={loading}>
            {commonT('buttons.yes')}
          </Button>
        </ButtonsContainer>
      </>
    </Modal>
  )
}
