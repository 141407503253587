import React from 'react'
import ReactDOM from 'react-dom'

import 'antd/dist/antd.less'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import { SentryService, I18NService } from './services'
import { App } from './features/App'
import reportWebVitals from './reportWebVitals'

import 'styles/theme/fonts.css'

SentryService.init()
I18NService.init()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
