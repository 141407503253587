import { Row, Col } from 'antd'
import styled from 'styled-components'

import { Layout as BaseLayout } from 'components'

export { InformationCard } from './InformationCard'
export { VarietiesCard } from './VarietiesCard'
export { LotCard } from './LotCard'
export { SortSelect } from './SortSelect'
export { Card } from './Card'
export { Weather } from './Weather'
export { RiceProductionCard } from './RiceProductionCard'
export { EmptyResultsCard } from './EmptyResultsCard'

export const Layout = styled(BaseLayout)`
  display: flex;
  flex-direction: column;
`

export const BodyContainerRow = styled(Row)`
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
`

export const ScrollColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const ContainerRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 90%;
  grid-gap: 24px;
  overflow-y: auto;
  overflow-x: auto;
`

export const ContainerLotCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
`

export const WeatherCard = styled(Row)`
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  height: 300px;
  width: 600px;
  padding: 24px;
`
