import { Row, Button, Space } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'

import { Modal as BaseModal } from '../Modal'

const Modal = styled(BaseModal)`
  width: 450px !important;
`

const Description = styled(Row)`
  padding-right: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`

const ButtonsContainer = styled(Space).attrs({
  size: 20,
})`
  width: 100%;
  justify-content: flex-end;
  margin-top: 22px;
  & button {
    width: 114px;
  }

  & div:last-child > button {
    background-color: ${({ theme }) => theme.colors.grey3};
    color: ${({ theme }) => theme.colors.white};
  }
`

interface Props {
  title?: string
  description?: string
  onConfirm: () => void
  onCancel: () => void
  loading: boolean
}

export const ConfirmationModal: React.FC<Props> = ({
  title,
  description,
  onConfirm,
  onCancel,
  loading,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMMON)

  return (
    <Modal title={title ?? ' '} visible centered onCancel={onCancel} footer={null}>
      <>
        <Description>{description}</Description>
        <ButtonsContainer>
          <Button type="primary" onClick={onCancel}>
            {t('buttons.no')}
          </Button>
          <Button onClick={onConfirm} loading={loading}>
            {t('buttons.yes')}
          </Button>
        </ButtonsContainer>
      </>
    </Modal>
  )
}
