import styled from 'styled-components'
import { Col, Space } from 'antd'

export { MeasureAreaDropdown } from './MeasureAreaDropdown'

interface Props {
  $isActive?: boolean
}

export const IconContainer = styled(Col)<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.white : theme.colors.new.baseColor[400]};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]};
`

export const ToolbarContainer = styled(Space).attrs({
  size: 11,
})`
  position: absolute;
  top: 15px;
  left: calc((100% - 300px) * 2.5 / 100);
  z-index: 10;
  @media (max-height: 850px) {
    top: 10px;
  }
`
