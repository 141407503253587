import { Control, Controller } from 'react-hook-form'
import { Form, Input, InputProps } from 'antd'
import styled from 'styled-components'

import { ConversionUtils } from 'utils'

const FormItem = styled(Form.Item)`
  .ant-input-affix-wrapper > .ant-input {
    padding: inherit;
  }
  .ant-form-item-explain {
    line-height: 14px;
    font-size: 12px;
    min-height: 14px;
  }
`

interface Props extends InputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  name: string
  placeholder: string
  defaultValue?: string
  prefixIcon?: React.ReactNode
  type?: 'password'
  capitalize?: boolean
}

export const FormInput: React.FC<Props> = ({
  control,
  name,
  prefixIcon,
  placeholder,
  defaultValue,
  type,
  capitalize,
  ...restProps
}) => {
  const InputComponent = type === 'password' ? Input.Password : Input
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...restFieldProps }, fieldState: { error: fieldError } }) => (
        <FormItem validateStatus={fieldError && 'error'} help={fieldError?.message}>
          <InputComponent
            defaultValue={defaultValue}
            placeholder={placeholder}
            prefix={prefixIcon}
            {...restFieldProps}
            {...restProps}
            onChange={event => {
              if (capitalize) {
                onChange(ConversionUtils.autoCapitalize(event.target.value))
                return
              }
              onChange(event.target.value)
            }}
          />
        </FormItem>
      )}
    />
  )
}
