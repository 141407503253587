export const BOX_COLORS = {
  IRRIGATION: {
    levelOne: '#0E2BFF',
    levelTwo: '#33E3FF',
    levelThree: '#FCFF1F',
    levelFour: '#F5B708',
    levelFive: '#8D5F0A',
  },
  CHLOROPHYLL: {
    levelOne: '#00BBF6',
    levelTwo: '#0E7115',
    levelThree: '#43D341',
    levelFour: '#FAFF00',
    levelFive: '#A51414',
    levelSix: '#AB9797',
    levelSeven: '#330000',
  },
}
