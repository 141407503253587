import styled from 'styled-components'

import { FieldsSelect as BaseFieldsSelect } from 'components'

export { FieldCard } from './FieldCard'
export { GeocoderControl } from './GeocoderControl'

export const FieldsSelect = styled(BaseFieldsSelect)`
  margin-bottom: 30px;
  @media (max-height: 735px) {
    margin-bottom: 15px;
  }
`
