import { Table as AntTable, Space as AntSpace, Input, Select as AntSelect, Row, Col } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { DatePicker as BaseDatePicker } from 'components'
import { DateUtils } from 'utils'
import { TrashIcon } from 'assets/icons'
import { I18NService } from 'services'
import { Language } from 'types/i18n'

import { useUpdateRiceFertilizer } from './hooks'
import { AppliedFertilizer, FertilizerGrades, RiceNutrient, RiceUnit } from '../../api'
import { DeleteAplicationData, DeleteType } from '../types'

const Space = styled(AntSpace)`
  width: 100%;
`
const Select = styled(AntSelect)`
  width: 74px;
`
const TrashButton = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.grey3};
`

const DatePicker = styled(BaseDatePicker)`
  padding: 6px;
`

const StyledInput = styled(Input)`
  width: 40px;
  padding-left: 9px !important;
  padding-right: 9px !important;
`

const DoseInput = styled(StyledInput)`
  width: 50px;
`

const Table = styled(AntTable)<AppliedFertilizer>`
  & .ant-table-cell {
    border-bottom: unset;
    padding: 5px;
  }

  & th {
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.grey3} !important`};
    background: ${({ theme }) => theme.colors.transparent} !important;
    border-radius: 0px !important;
    text-align: center !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }
  & td:nth-child(even) {
    background: ${({ theme }) => theme.colors.grey2} !important;
  }
  .ant-table-tbody {
    & tr:last-child {
      & td:nth-child(even) {
        border-bottom-left-radius: ${({ theme }) => theme.foundation.borderRadius};
        border-bottom-right-radius: ${({ theme }) => theme.foundation.borderRadius};
      }
    }
  }
  & .ant-table-cell-scrollbar {
    box-shadow: none;
  }
` as typeof AntTable

interface Props {
  appliedFertilizers: AppliedFertilizer[]
  onDelete: (data: DeleteAplicationData) => void
  riceUnits: RiceUnit[]
  riceNutrients: RiceNutrient[]
}

const enum NutrientsSymbols {
  NITROGEN = 'N',
  PHOSPHORUS = 'P',
  POTASSIUM = 'K',
}

const symbolsOfNutrientsToFilter = [
  NutrientsSymbols.NITROGEN,
  NutrientsSymbols.PHOSPHORUS,
  NutrientsSymbols.POTASSIUM,
]

export const FertilizerTable: React.FC<Props> = ({
  appliedFertilizers,
  onDelete,
  riceUnits,
  riceNutrients,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { colors } = useTheme()

  const { updateRiceFertilizer } = useUpdateRiceFertilizer()

  const { Option } = Select

  // we need to filter nitrogen, phosphorus and potassium because these have their own input
  const filteredRiceNutrients = riceNutrients.filter(
    riceNutrient => !symbolsOfNutrientsToFilter.some(symbol => symbol === riceNutrient.symbol),
  )
  type DataObject = {
    [key: string]: { grade?: number; symbol: string; nutrientId: number }
  }

  const findNutrientIdBySymbol = (symbol: NutrientsSymbols | string) =>
    riceNutrients.find(nutrient => nutrient.symbol === symbol)?.id

  const handleNutrientChange = (
    id: number,
    newNutrientValue: number,
    symbol: NutrientsSymbols | string,
    index: number,
    grades: FertilizerGrades[],
  ) => {
    if (index >= 0) {
      const newGrades = [...grades]
      // eslint-disable-next-line no-param-reassign
      newGrades[index] = { ...newGrades[index], grade: newNutrientValue }

      const fertilizerGrades = newGrades.map(grade => ({
        grade: grade.grade,
        nutrientId: grade.nutrient?.id,
      }))

      updateRiceFertilizer({
        variables: { id, updateFertilizerDTO: { fertilizerGrades } },
      })
    } else {
      const newGrade = {
        nutrientId: findNutrientIdBySymbol(symbol) ?? null,
        grade: newNutrientValue,
      }
      const fertilizerGrades = grades.map(grade => ({
        grade: grade.grade,
        nutrientId: grade.nutrient?.id,
      }))

      updateRiceFertilizer({
        variables: {
          id,
          updateFertilizerDTO: { fertilizerGrades: [...fertilizerGrades, newGrade] },
        },
      })
    }
  }

  const getNutrientSymbolValue = (grades: FertilizerGrades[]) =>
    grades.reduce((accumulator: DataObject, { grade, nutrient }) => {
      if (!nutrient) return {}
      const key = symbolsOfNutrientsToFilter.some(symbolKey => nutrient?.symbol === symbolKey)
        ? nutrient?.symbol
        : 'other'
      // eslint-disable-next-line no-param-reassign
      accumulator[key] = {
        grade: grade ?? undefined,
        symbol: nutrient.symbol,
        nutrientId: nutrient.id,
      }
      return accumulator
    }, {})

  const columns: ColumnsType<AppliedFertilizer> = [
    {
      title: t('fertilizersTable.dateColumn'),
      render: ({ date, id }) => (
        <DatePicker
          suffixIcon={null}
          placeholder={commonT('inputs.date')}
          format={commonT('dateFormats.medium')}
          defaultValue={DateUtils.parseDate(date)}
          onChange={value =>
            updateRiceFertilizer({
              variables: {
                id,
                updateFertilizerDTO: { date: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      width: '100px',
    },
    {
      title: t('fertilizersTable.productColumn'),
      width: '93px',
      render: ({ name, id }: AppliedFertilizer) => (
        <Input
          placeholder={t('fertilizersTable.productColumn')}
          defaultValue={name ?? undefined}
          onBlur={(event: React.FormEvent<HTMLInputElement>) => {
            const newName = event.currentTarget.value
            updateRiceFertilizer({ variables: { id, updateFertilizerDTO: { name: newName } } })
          }}
        />
      ),
    },
    {
      title: t('fertilizersTable.brandColumn'),
      width: '93px',
      render: ({ brand, id }: AppliedFertilizer) => (
        <Input
          placeholder={t('fertilizersTable.brandColumn')}
          defaultValue={brand ?? undefined}
          onBlur={(event: React.FormEvent<HTMLInputElement>) => {
            const newBrand = event.currentTarget.value
            updateRiceFertilizer({ variables: { id, updateFertilizerDTO: { brand: newBrand } } })
          }}
        />
      ),
    },
    {
      title: t('fertilizersTable.doseColumn'),
      width: '125px',
      render: ({ unit, quantity, id }: AppliedFertilizer) => (
        <Space size={4}>
          <DoseInput
            defaultValue={quantity ?? undefined}
            onBlur={(event: React.FormEvent<HTMLInputElement>) => {
              const newQuantity = Number(event.currentTarget.value)
              updateRiceFertilizer({
                variables: { id, updateFertilizerDTO: { quantity: newQuantity } },
              })
            }}
          />
          <Select
            defaultValue={unit?.id}
            options={riceUnits.map(riceUnit => ({
              label: riceUnit.locales[i18next.resolvedLanguage as Language],
              value: riceUnit.id,
            }))}
            dropdownStyle={{
              minWidth: '80px',
            }}
            placeholder={commonT('systems.doseSurfaceDensity')}
            onChange={value =>
              updateRiceFertilizer({
                variables: {
                  id,
                  updateFertilizerDTO: { unitId: Number(value) },
                },
              })
            }
          />
        </Space>
      ),
    },
    {
      title: t('fertilizersTable.gradeColumn'),
      width: '320px',
      render: ({ grades, id }: AppliedFertilizer) => {
        const nutrientsValues = getNutrientSymbolValue(grades ?? [])
        return (
          <Row gutter={[8, 0]} wrap={false}>
            <Col>
              <Space size={4}>
                {NutrientsSymbols.NITROGEN}
                <StyledInput
                  defaultValue={nutrientsValues.N ? nutrientsValues.N.grade : undefined}
                  onBlur={(event: React.FormEvent<HTMLInputElement>) => {
                    const newNitrogenValue = Number(event.currentTarget.value)
                    const index = grades?.findIndex(
                      grade => grade.nutrient?.symbol === NutrientsSymbols.NITROGEN,
                    )
                    if (index === undefined || !grades) return

                    handleNutrientChange(
                      id,
                      newNitrogenValue,
                      NutrientsSymbols.NITROGEN,
                      index,
                      grades,
                    )
                  }}
                />
              </Space>
            </Col>
            <Col>
              <Space size={4}>
                {NutrientsSymbols.PHOSPHORUS}
                <StyledInput
                  defaultValue={nutrientsValues.P ? nutrientsValues.P.grade : undefined}
                  onBlur={(event: React.FormEvent<HTMLInputElement>) => {
                    const newPhosphorusValue = Number(event.currentTarget.value)
                    const index = grades?.findIndex(
                      grade => grade.nutrient?.symbol === NutrientsSymbols.PHOSPHORUS,
                    )
                    if (index === undefined || !grades) return

                    handleNutrientChange(
                      id,
                      newPhosphorusValue,
                      NutrientsSymbols.PHOSPHORUS,
                      index,
                      grades,
                    )
                  }}
                />
              </Space>
            </Col>

            <Col>
              <Space size={4}>
                {NutrientsSymbols.POTASSIUM}
                <StyledInput
                  defaultValue={nutrientsValues.K ? nutrientsValues.K.grade : undefined}
                  onBlur={(event: React.FormEvent<HTMLInputElement>) => {
                    const newPotassiumValue = Number(event.currentTarget.value)

                    const index = grades?.findIndex(
                      grade => grade.nutrient?.symbol === NutrientsSymbols.POTASSIUM,
                    )

                    if (index === undefined || !grades) return

                    handleNutrientChange(
                      id,
                      newPotassiumValue,
                      NutrientsSymbols.POTASSIUM,
                      index,
                      grades,
                    )
                  }}
                />
              </Space>
            </Col>
            <Col>
              <Space size={4}>
                <Select
                  placeholder={commonT('inputs.other')}
                  optionLabelProp="label"
                  allowClear
                  defaultValue={nutrientsValues.other ? nutrientsValues.other.symbol : undefined}
                  dropdownStyle={{
                    minWidth: '105px',
                  }}
                  onChange={value => {
                    const filteredNutrients = grades?.filter(riceNutrient =>
                      symbolsOfNutrientsToFilter.some(
                        symbol => symbol === riceNutrient.nutrient?.symbol,
                      ),
                    )
                    updateRiceFertilizer({
                      variables: {
                        id,
                        updateFertilizerDTO: {
                          fertilizerGrades: [
                            ...(filteredNutrients
                              ? filteredNutrients.map(filteredNutrient => ({
                                  grade: filteredNutrient.grade,
                                  nutrientId: filteredNutrient.nutrient?.id,
                                }))
                              : []),
                            {
                              nutrientId: Number(value),
                              grade: nutrientsValues.other ? nutrientsValues.other.grade : 0,
                            },
                          ],
                        },
                      },
                    })
                  }}
                >
                  {filteredRiceNutrients?.map(riceNutrient => {
                    return (
                      <Option
                        key={riceNutrient.id}
                        value={riceNutrient.id}
                        label={riceNutrient.symbol}
                      >
                        <div className="demo-option-label-item">
                          {riceNutrient.locales[i18next.resolvedLanguage as Language]}
                        </div>
                      </Option>
                    )
                  })}
                </Select>

                <StyledInput
                  defaultValue={nutrientsValues.other ? nutrientsValues.other.grade : undefined}
                  onBlur={(event: React.FormEvent<HTMLInputElement>) => {
                    const newNutrientValue = Number(event.currentTarget.value)
                    if (!nutrientsValues.other) return
                    const index = grades?.findIndex(
                      grade => grade.nutrient?.symbol === nutrientsValues.other.symbol,
                    )
                    if (index === undefined || !grades) return

                    handleNutrientChange(
                      id,
                      newNutrientValue,
                      nutrientsValues.other.symbol,
                      index,
                      grades,
                    )
                  }}
                />
              </Space>
            </Col>
            <Col>
              <TrashButton
                onClick={() => onDelete({ showModal: true, id, type: DeleteType.FERTILIZER })}
              >
                <TrashIcon color={colors.white} />
              </TrashButton>
            </Col>
          </Row>
        )
      },
    },
  ]

  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={appliedFertilizers}
      rowKey={({ id }) => id}
      scroll={{ y: 200 }}
    />
  )
}
