import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { ActiveCompanyContext } from 'contexts'
import { I18NService } from 'services'
import { Language } from 'types/i18n'

import { Varieties, VARIETIES, VarietiesVariables, Variety } from './api'

interface Props {
  cropId: number
  onChange: (variety: Variety) => void
  className?: string
  onClick?: () => void
  value?: number
}

export const VarietiesSelect: React.FC<Props> = ({
  cropId,
  onChange,
  className,
  onClick,
  value,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { activeCountryId } = ActiveCompanyContext.useActiveCompanyContext()
  const { data, loading } = useQuery<Varieties, VarietiesVariables>(VARIETIES, {
    variables: { countryId: activeCountryId!, cropId },
    skip: !activeCountryId,
  })

  return (
    <Select
      showSearch
      filterOption={(input, option) =>
        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      placeholder={t('inputs.variety')}
      loading={loading}
      className={className}
      onClick={onClick}
      value={value}
      onChange={id => {
        const selectedVariety = data?.varieties.results.find(variety => variety.id === id)
        if (!selectedVariety) return
        onChange(selectedVariety)
      }}
    >
      {data?.varieties.results.map(variety => (
        <Select.Option key={variety.id} value={variety.id}>
          {variety.locales[i18next.resolvedLanguage as Language]}
        </Select.Option>
      ))}
    </Select>
  )
}
