import { Typography, Row, Col, Image as AntImage } from 'antd'
import styled from 'styled-components'
import { ShopOutlined as AntShopOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'

import { CompanyUserInvited, CompanyUserInvitedVariables, COMPANY_USER_INVITED } from './api'

const { Title: AntTitle } = Typography

const CenteredRow = styled(Row)`
  flex-grow: 1;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  margin-top: -50px;
`

const Description = styled(AntTitle)`
  color: ${({ theme }) => theme.colors.olive} !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 35px !important;
`

const ImageContainer = styled(Col)`
  width: 156px;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  overflow: hidden;
`

const ColContainer = styled(Col)`
  width: 750px;
  background: ${({ theme }) => theme.colors.grey1};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px 10px 20px rgba(211, 211, 211, 0.25);
  border-radius: ${({ theme }) => theme.foundation.borderRadius};

  &.ant-col:first-child {
    padding: 30px 48px !important;
  }
`

const Image = styled(AntImage)`
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  height: 156px;
`
const ShopOutlined = styled(AntShopOutlined)`
  font-size: 156px;
`

interface Props {
  companyId?: number
}
export const InvitedUserCard: React.FC<Props> = ({ companyId }) => {
  const { data } = useQuery<CompanyUserInvited, CompanyUserInvitedVariables>(COMPANY_USER_INVITED, {
    variables: { id: companyId! },
    skip: !companyId,
  })
  const { t } = useTranslation(I18NService.NAMESPACES.SETTINGS)

  return (
    <CenteredRow justify="center" align="middle">
      <ColContainer>
        <Row>
          <Col flex={1}>
            <AntTitle level={3}>{data?.company.name} </AntTitle>
          </Col>
        </Row>
        <Row gutter={32} wrap={false}>
          <Col flex={1}>
            <ImageContainer>
              {data?.company.logo ? (
                <Image src={data.company.logo} preview={false} />
              ) : (
                <ShopOutlined />
              )}
            </ImageContainer>
          </Col>
          <Col flex={3}>
            <Description level={3}>{t('cards.invitedUser.notFields')}</Description>
          </Col>
        </Row>
      </ColContainer>
    </CenteredRow>
  )
}
