import { Col, Select as AntSelect, Space, Tooltip, Typography } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import {
  CameraIcon,
  ChlorophyllIcon,
  DropIcon,
  GrainHumidityIndicatorIcon,
  YieldIcon,
} from 'assets/icons'

import { MapIndex } from '../../types'

interface SelectProps {
  $borderRadius?: string
}
const Select = styled(AntSelect)<SelectProps>`
  width: 265px;
  & .ant-select-selector {
    background-color: ${({ theme }) => theme.colors.new.baseColor[850]} !important;
    height: 48px !important;
    border-radius: ${({ $borderRadius }) => $borderRadius ?? 'none'} !important;
    font-weight: 600;
    font-size: 13px;
    border: none !important;
    box-shadow: none !important;
  }
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.new.baseColor[400]};
  }
`
export const StyledSpace = styled(Space)`
  width: 100%;
`

const { Text: AntText } = Typography

export const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px !important;
`
export const IconContainer = styled(Col)`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  isGrainHumidityNotAvailable: boolean
  grainHumidityHasNotemErgenceDaysActivation: boolean
  isEstimatedYieldNotAvailable: boolean
  estimatedYieldHasNotFormula: boolean
  isIrrigationNotAvailable: boolean
  isChlorophyllNotAvailable: boolean
  isNotAvailableForHighFrequency?: boolean
  onChange: (value: MapIndex) => void
  selectedMapIndex: MapIndex
  bordered?: boolean
  borderRadius?: string
}

export const SatelliteInformationSelect: React.FC<Props> = ({
  isEstimatedYieldNotAvailable,
  isGrainHumidityNotAvailable,
  grainHumidityHasNotemErgenceDaysActivation,
  estimatedYieldHasNotFormula,
  isIrrigationNotAvailable,
  isChlorophyllNotAvailable,
  isNotAvailableForHighFrequency,
  onChange,
  selectedMapIndex,
  bordered,
  borderRadius,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation(I18NService.NAMESPACES.MAP)

  const dataSource = [
    {
      Logo: DropIcon,
      title: t('indicators.irrigation'),
      mapIndex: 'irrigation' as MapIndex,
      disabled: isIrrigationNotAvailable || isNotAvailableForHighFrequency,
      tooltipText:
        isNotAvailableForHighFrequency && t('indicators.tooltips.isNotAvailableForHighFrequency'),
    },
    {
      Logo: ChlorophyllIcon,
      title: t('indicators.chlorophyll'),
      mapIndex: 'chlorophyll' as MapIndex,
      disabled: isChlorophyllNotAvailable || isNotAvailableForHighFrequency,
      tooltipText:
        isNotAvailableForHighFrequency && t('indicators.tooltips.isNotAvailableForHighFrequency'),
    },
    {
      Logo: CameraIcon,
      title: t('indicators.realImage'),
      mapIndex: 'realImage' as MapIndex,
    },
    {
      Logo: GrainHumidityIndicatorIcon,
      title: t('indicators.grainHumidity'),
      mapIndex: 'grainHumidity' as MapIndex,
      disabled: isGrainHumidityNotAvailable || isNotAvailableForHighFrequency,
      tooltipText: isNotAvailableForHighFrequency
        ? t('indicators.tooltips.isNotAvailableForHighFrequency')
        : t('indicators.tooltips.isGrainHumidityNotAvailable', {
            context:
              grainHumidityHasNotemErgenceDaysActivation &&
              'grainHumidityHasNotemErgenceDaysActivation',
          }),
    },
    {
      Logo: YieldIcon,
      title: t('indicators.estimatedYield'),
      mapIndex: 'estimatedYield' as MapIndex,
      disabled: isEstimatedYieldNotAvailable || isNotAvailableForHighFrequency,
      tooltipText: isNotAvailableForHighFrequency
        ? t('indicators.tooltips.isNotAvailableForHighFrequency')
        : t('indicators.tooltips.isEstimatedYieldNotAvailable', {
            context: estimatedYieldHasNotFormula && 'estimatedYieldHasNotFormula',
          }),
    },
  ]
  return (
    <Select
      onChange={value => {
        const selectedIndex = value as MapIndex
        onChange(selectedIndex)
      }}
      value={selectedMapIndex}
      bordered={bordered}
      dropdownClassName="custom-select-dropdown"
      $borderRadius={borderRadius}
    >
      {dataSource.map(data => {
        const { Logo, title, disabled, mapIndex, tooltipText } = data

        return (
          <Select.Option key={mapIndex} value={mapIndex} disabled={disabled}>
            {disabled ? (
              <Tooltip title={tooltipText} placement="left" overlayStyle={{ maxWidth: '310px' }}>
                <Space size={10}>
                  <IconContainer>
                    <Logo color={colors.white} />
                  </IconContainer>
                  <Text>{title}</Text>
                </Space>
              </Tooltip>
            ) : (
              <StyledSpace size={10}>
                <IconContainer>
                  <Logo color={colors.white} />
                </IconContainer>
                <Text>{title}</Text>
              </StyledSpace>
            )}
          </Select.Option>
        )
      })}
      )
    </Select>
  )
}
