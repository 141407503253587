/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react'
import { Col, Space } from 'antd'
import { differenceInDays } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { dashboardImage } from 'assets/images'
import { FullScreenLoader, SeasonSelect } from 'components'
import { ActiveCompanyContext } from 'contexts'
import { useHasCompanyOrFieldsOrLots, useLocalStorage, usePermissions, useSeason } from 'hooks'
import { SeasonLot } from 'api'
import { STORAGE_KEYS, UNITS } from 'consts'
import { I18NService } from 'services'
import { Language } from 'types/i18n'

import {
  InformationCard,
  VarietiesCard,
  ScrollColumn,
  LotCard,
  ContainerRow,
  BodyContainerRow,
  ContainerLotCards,
  Layout,
  SortSelect,
  Card,
  Weather,
  WeatherCard,
  RiceProductionCard,
  EmptyResultsCard,
} from './components'
import { SortUtils } from './utils'
import { LotOrFieldInformation } from '../components'
import { SELECT } from './consts'

const today = new Date()

export const Dashboard: React.FC = () => {
  const { activeSeasonId, setActiveSeason } = ActiveCompanyContext.useActiveCompanyContext()
  const { data, loading } = useSeason(activeSeasonId)
  const [storedOrderDashboardLotsBy, setOrderDashboardLotsBy] = useLocalStorage<string>(
    STORAGE_KEYS.ORDER_DASHBOARD_LOTS_BY,
    SELECT.VALUES.CRITICALITY,
  )
  const history = useHistory()
  const { t } = useTranslation(I18NService.NAMESPACES.DASHBOARD)
  const { permissions } = usePermissions()
  const { noFields, noLots } = useHasCompanyOrFieldsOrLots()

  interface DataObject {
    [key: number]: { name: string; value: number; size: number }
  }
  const handleChartData = (lots?: SeasonLot[]) => {
    const reduced = lots?.reduce((accumulator: DataObject, { variety, size }) => {
      // We assign 0 when the lot hasn't variety, assuming the ids of the varieties always start at 1
      const key = variety?.id ?? 0
      // eslint-disable-next-line no-param-reassign
      accumulator[key] = accumulator[key] ?? {
        name:
          variety?.locales[i18next.resolvedLanguage as Language] ?? t('varietiesCard.noVariety'),
        value: 0,
        size: 0,
      }
      // eslint-disable-next-line no-param-reassign
      accumulator[key].value += 1
      // eslint-disable-next-line no-param-reassign
      accumulator[key].size += size
      return accumulator
    }, {})
    const result = Object.values(reduced || '')
    return result
  }

  const getEstimatedRiceProduction = useCallback(
    () =>
      data?.season.seasonFields.reduce(
        (acc, { lots }) =>
          acc +
          lots.reduce((accLot, { riceLot }) => accLot + (riceLot.estimatedYield.value ?? 0), 0),
        0,
      ) ?? 0,

    [data?.season.seasonFields],
  )

  const getDeclaredRiceProduction = useCallback(
    () =>
      data?.season.seasonFields.reduce(
        (acc, { lots }) =>
          acc + lots.reduce((accLot, { riceLot }) => accLot + (riceLot.yield ?? 0), 0),
        0,
      ) ?? 0,

    [data?.season.seasonFields],
  )

  const onChangeActiveSeason = (seasonId?: number) => setActiveSeason(seasonId)

  const headerItems = [<SeasonSelect onChange={onChangeActiveSeason} />]

  return (
    <Layout
      showAppHeader={permissions.isFeatureSetGrupoDiana}
      appHeaderItems={headerItems}
      headerTitle={permissions.fieldEntity ? t('headerTitleWithField') : t('headerTitle')}
      headerImage={dashboardImage}
      actionButton={
        permissions.satelliteImagery && !noFields && !noLots ? (
          <SortSelect onChange={value => setOrderDashboardLotsBy(value)} />
        ) : undefined
      }
    >
      {loading ? (
        <FullScreenLoader />
      ) : (
        <ContainerRow>
          {noFields || noLots ? (
            <EmptyResultsCard
              noFields={noFields}
              isCommercialUser={permissions.isCommercialUser}
              isOrganizationUser={!!permissions.isAnOrganizationUser}
              isAdminUser={permissions.isCompanyAdminUser}
            />
          ) : (
            <>
              <ScrollColumn>
                {data?.season.seasonFields
                  .filter(({ field }) => field)
                  .map(seasonField => {
                    const field = seasonField.field!
                    const { lots } = seasonField
                    const sortedLots =
                      storedOrderDashboardLotsBy === SELECT.VALUES.CRITICALITY
                        ? SortUtils.sortLotsByCriticality(lots)
                        : lots
                    return (
                      <div key={field.id}>
                        <LotOrFieldInformation
                          name={field.name}
                          lotsOrHaQty={t('lotsUnits', { count: lots.length })}
                          onSettingsClick={() =>
                            permissions.fieldEntity
                              ? history.push(`/settings/fields/${field.id}`)
                              : history.push('/settings')
                          }
                        />
                        <BodyContainerRow key={field.id} gutter={[0, 24]}>
                          <Col>
                            <Space size={24}>
                              <Card>
                                <VarietiesCard data={handleChartData(lots)} />
                              </Card>
                              {permissions.isFeatureSetGrupoDiana && (
                                <RiceProductionCard
                                  seasonId={data.season.id}
                                  estimated={getEstimatedRiceProduction() * UNITS.KG_TONS_RATIO}
                                  declared={getDeclaredRiceProduction() * UNITS.KG_TONS_RATIO}
                                  delivered={
                                    data.season.productionToMill
                                      ? data.season.productionToMill * UNITS.KG_TONS_RATIO
                                      : undefined
                                  }
                                  millDeliveryType={data.season.millDeliveryType ?? undefined}
                                />
                              )}
                              {!permissions.isFeatureSetGrupoDiana && (
                                <WeatherCard>
                                  <Weather days={field.weather?.days ?? undefined} />
                                </WeatherCard>
                              )}
                            </Space>
                          </Col>
                          <ContainerLotCards>
                            {sortedLots.map(lot => (
                              <Col flex="0 0 290" key={lot.id}>
                                <LotCard
                                  name={lot.name}
                                  commercialName={lot.commercialName ?? undefined}
                                  id={lot.id}
                                  daysSinceEmergenceDate={
                                    lot.riceLot.emergenceDate
                                      ? differenceInDays(
                                          today,
                                          new Date(lot.riceLot.emergenceDate),
                                        ).toString()
                                      : '-'
                                  }
                                  size={lot.size}
                                  variety={
                                    lot.variety?.locales[i18next.resolvedLanguage as Language]
                                  }
                                  lotCoordinates={lot.area.coordinates[0]}
                                  chlorophyllIndicator={
                                    lot.riceLot.indicators?.chlorophyll ?? undefined
                                  }
                                  irrigationIndicator={
                                    lot.riceLot.indicators?.irrigation ?? undefined
                                  }
                                  semaphoreIndicator={
                                    lot.riceLot.indicators?.semaphore ?? undefined
                                  }
                                />
                              </Col>
                            ))}
                          </ContainerLotCards>
                        </BodyContainerRow>
                      </div>
                    )
                  })}
              </ScrollColumn>
              <InformationCard />
            </>
          )}
        </ContainerRow>
      )}
    </Layout>
  )
}
