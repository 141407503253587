import { Col, Row, Typography, Table as AntTable, Checkbox, Input as AntInput } from 'antd'
import styled from 'styled-components'
import React, { useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'
import { VarietiesSelect, DeleteButton } from 'components'
import { TABLE } from 'consts'
import { usePermissions } from 'hooks'
import { ConversionUtils } from 'utils'

import { Lot } from '../../../../types'

const { Text } = Typography

const Header = styled(Row)`
  background-color: ${({ theme }) => theme.colors.grey2};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  height: 45px;
  padding: 0 17px;
  margin-bottom: 12px;
  align-items: center;
`

const Table = styled(AntTable)<Lot>`
  & .ant-table {
    background-color: ${({ theme }) => theme.colors.grey1};
  }
  & .ant-table-cell {
    padding: 12px 2px !important;
    border-bottom: unset;
  }
` as typeof AntTable
// Workaraound to avoid losing generic typing after styling the component

const StyledVarietiesSelect = styled(VarietiesSelect)`
  width: 100%;
`

const Input = styled(AntInput)`
  padding: 6px 9px;
`

interface Props {
  lots: Lot[]
  selectedLotIndex?: number
  onSelect: (lotIndex: number) => void
  onChange: (lot: Lot['lot'], lotIndex: number) => void
  onDelete: (lotIndex: number) => void
  loading?: boolean
  disableDelete: boolean
}

export const LotsList: React.FC<Props> = ({
  lots,
  selectedLotIndex,
  onSelect,
  onChange,
  onDelete,
  loading,
  disableDelete,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_SETTINGS)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { permissions } = usePermissions()

  const selectCheckBox = (index: number) => {
    if (index !== selectedLotIndex) onSelect(index)
  }

  type DataObject = {
    [key: number]: { current: HTMLDivElement | null }
  }

  const refs = useMemo(
    () =>
      lots.reduce((accumulator: DataObject, _, index) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[index] = React.createRef<HTMLDivElement>()
        return accumulator
      }, {}),
    [lots],
  )

  const handleListScroll = useCallback(
    (index: number) => {
      if (!refs[index]) return
      const ref = refs[index].current

      if (ref === null) return

      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    },
    [refs],
  )

  useEffect(() => {
    if (selectedLotIndex === undefined || selectedLotIndex === -1 || selectedLotIndex === null)
      return
    handleListScroll(selectedLotIndex)
  }, [handleListScroll, selectedLotIndex])

  const columns = [
    {
      render: (_: Lot, __: Lot, index: number) => (
        <div ref={refs[index]}>
          <Checkbox checked={index === selectedLotIndex} onClick={() => onSelect(index)} />
        </div>
      ),
      width: '22px',
    },
    {
      render: ({ lot }: Lot, __: Lot, index: number) => (
        <Input
          onKeyDown={e => {
            if (e.key === 'Backspace') {
              e.stopPropagation()
            }
          }}
          placeholder={commonT('inputs.commercialName')}
          value={lot.commercialName ?? undefined}
          onChange={event =>
            onChange(
              { ...lot, commercialName: ConversionUtils.autoCapitalize(event.target.value) },
              index,
            )
          }
        />
      ),
      width: '65px',
      hide: !permissions.isFeatureSetGrupoDiana,
    },
    {
      render: ({ lot }: Lot, __: Lot, index: number) => (
        <div ref={refs[index]}>
          <Input
            onKeyDown={e => {
              if (e.key === 'Backspace') {
                e.stopPropagation()
              }
            }}
            placeholder={commonT('inputs.name')}
            value={lot.name}
            onClick={() => selectCheckBox(index)}
            onChange={event =>
              onChange({ ...lot, name: ConversionUtils.autoCapitalize(event.target.value) }, index)
            }
            autoFocus={index === selectedLotIndex}
          />
        </div>
      ),
    },
    {
      render: ({ lot }: Lot, __: Lot, index: number) => {
        // TODO: Check how to have for now a preselected crop across whole apps since we are
        // only using rice crop as a possible crop to monitor.
        const cropId = 1
        return (
          <div style={{ width: '130px' }}>
            <StyledVarietiesSelect
              cropId={cropId}
              onClick={() => selectCheckBox(index)}
              value={lot.varietyId}
              onChange={variety => {
                onChange({ ...lot, cropId, varietyId: variety.id }, index)
              }}
            />
          </div>
        )
      },
      width: '135px',
    },
    {
      render: (_: Lot, __: Lot, index: number) =>
        lots[index].lot.isPlanetEnabled ? (
          <DeleteButton disabled toolTipText={commonT('messages.highFrequencyLot')} />
        ) : (
          <DeleteButton
            disabled={disableDelete && index !== lots.length - 1}
            onClick={() => onDelete(index)}
          />
        ),
      width: '42px',
    },
  ]

  const isDataSourceBiggerThanMaxTableLots = lots.length > TABLE.MAX_TABLE_DATA
  return (
    <Row>
      <Col flex={1}>
        <Header>
          <Text strong>{t('tableTitle')}</Text>
        </Header>
        <Table
          size="small"
          rowKey={({ lot }) => lot.id}
          dataSource={lots}
          columns={columns.filter(column => !column.hide)}
          pagination={false}
          showHeader={false}
          scroll={isDataSourceBiggerThanMaxTableLots ? { y: 200 } : undefined}
          loading={loading}
        />
      </Col>
    </Row>
  )
}
