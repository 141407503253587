import { Control, Controller } from 'react-hook-form'
import { Row, Col, Form, Input, Select as AntSelect } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { InputTitle } from 'components'
import { arFlagImage, usaFlagImage, colFlagImage, parFlagImage, urFlagImage } from 'assets/images'
import { I18NService } from 'services'
import { useCountries } from 'hooks'
import { ConversionUtils } from 'utils'

const FormContainer = styled(Row)`
  margin-top: 22px;
  flex-flow: row;
`

const Image = styled.img`
  background-position: center;
  width: 26px;
  height: 26px;
  margin-right: 13px;
`

const Select = styled(AntSelect)`
  & .ant-select-selector {
    height: 55px !important;
  }
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`

const flagCountries: {
  [key: string]: string
} = {
  argentina: arFlagImage,
  uruguay: urFlagImage,
  paraguay: parFlagImage,
  'united-states': usaFlagImage,
  colombia: colFlagImage,
}

interface Props {
  control: Control<{
    companyName: string
    country: string
    governmentId?: string
    subregion: number
  }>
  organizationCountry: string
  commercialExecutiveRegion: string
  subregionOptions: { label: string; value: number }[]
  selectedSubregionId?: number
  onInputNameBlur?: () => void
  onInputGovernmentIdBlur?: () => void
  onSelectSubregionBlur?: () => void
}

export const CreateClientForm: React.FC<Props> = ({
  control,
  organizationCountry,
  commercialExecutiveRegion,
  subregionOptions,
  selectedSubregionId,
  onInputNameBlur,
  onInputGovernmentIdBlur,
  onSelectSubregionBlur,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMPANY_SETTINGS)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { countries, loading } = useCountries()

  const getLocalizedNumber = (value?: string) => {
    if (!value) return ''
    const onlyNumbers = ConversionUtils.keepOnlyNumbers(value)
    if (!onlyNumbers) return ''
    const localizedNumber = commonT('systemsFormat.localizeNumber', {
      val: onlyNumbers,
    })
    return localizedNumber
  }

  return (
    <>
      <FormContainer gutter={25}>
        <Col span={12}>
          <InputTitle title={t('inputs.fullName')} />
          <Controller
            // eslint-disable-next-line i18next/no-literal-string
            name="companyName"
            control={control}
            render={({
              field: { onChange, onBlur, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Input
                  size="large"
                  onBlur={() => {
                    onInputNameBlur?.()
                    onBlur()
                  }}
                  {...restFieldProps}
                  onChange={event => onChange(ConversionUtils.autoCapitalize(event.target.value))}
                />
              </Form.Item>
            )}
          />
        </Col>

        <Col span={12}>
          <InputTitle title={t('inputs.NIT')} />
          <Controller
            // eslint-disable-next-line i18next/no-literal-string
            name="governmentId"
            control={control}
            render={({
              field: { onChange, onBlur, value, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Input
                  type="text"
                  size="large"
                  onBlur={() => {
                    onInputGovernmentIdBlur?.()
                    onBlur()
                  }}
                  value={getLocalizedNumber(value)}
                  {...restFieldProps}
                  onChange={event => onChange(getLocalizedNumber(event.target.value))}
                />
              </Form.Item>
            )}
          />
        </Col>
      </FormContainer>

      <Row gutter={24}>
        <Col flex={1}>
          <InputTitle title={t('inputs.country')} />
          <Controller
            // eslint-disable-next-line i18next/no-literal-string
            name="country"
            control={control}
            defaultValue={organizationCountry}
            render={({
              field: { onChange, onBlur, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Select
                  disabled
                  size="large"
                  loading={loading}
                  onBlur={() => {
                    onBlur()
                  }}
                  {...restFieldProps}
                  value={organizationCountry}
                >
                  {countries?.map(country => (
                    <Select.Option key={country.id} value={country.id}>
                      <Image src={flagCountries[country.id]} />
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          />
        </Col>
        <Col flex={1}>
          <InputTitle title={t('inputs.region')} />
          <Form.Item>
            <Select size="large" disabled defaultValue={commercialExecutiveRegion} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <InputTitle title={t('inputs.subregion')} />
          <Controller
            // eslint-disable-next-line i18next/no-literal-string
            name="subregion"
            control={control}
            render={({
              field: { onChange, onBlur, ...restFieldProps },
              fieldState: { error: fieldError },
            }) => (
              <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                <Select
                  size="large"
                  onBlur={() => {
                    onSelectSubregionBlur?.()
                    onBlur()
                  }}
                  {...restFieldProps}
                  onChange={event => onChange(event)}
                  options={subregionOptions}
                  defaultValue={selectedSubregionId}
                />
              </Form.Item>
            )}
          />
        </Col>
      </Row>
    </>
  )
}
