import { StoreObject, useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { UpdateLots, UpdateLotsVariables, UPDATE_LOTS } from '../api'

export const useUpdateLots = () => {
  const [updateLots, { loading }] = useMutation<UpdateLots, UpdateLotsVariables>(UPDATE_LOTS, {
    onError: GraphQLUtils.errorHandler,
    update: (cache, { data }) => {
      if (!data) return

      data.updateLots.results.forEach(lot => {
        // Workaraound because of this: https://github.com/apollographql/apollo-client/issues/7577
        const cacheLotId = cache.identify(lot as unknown as StoreObject)
        cache.evict({ id: cacheLotId, fieldName: 'riceLot' })
      })
    },
  })

  return { updateLots, loading }
}
