import { ApolloError } from '@apollo/client'
import { notification } from 'antd'
import * as Sentry from '@sentry/react'
import { getI18n } from 'react-i18next'

import { I18NService } from 'services'

const errorHandler = (error: ApolloError) => {
  console.log(error)
  Sentry.captureException(error)
  notification.error({
    message: getI18n().t('messages.genericErrorTryAgain', { ns: I18NService.NAMESPACES.COMMON }),
  })
}

export const GraphQLUtils = {
  errorHandler,
}
