import { gql } from '@apollo/client'

export type { UpdateLot, UpdateLotVariables } from './types/UpdateLot'

export const UPDATE_LOT = gql`
  mutation UpdateLot($updateLotDTO: UpdateLotInput!) {
    updateLot(updateLotDTO: $updateLotDTO) {
      code
      lot {
        id
        previousCrop {
          id
        }
      }
    }
  }
`
