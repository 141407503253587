import { Typography, Row, Col, Divider as AntDivider, Space } from 'antd'
import styled from 'styled-components'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IndicatorStatus, Indicators } from 'types'
import { EllipsisText, EllipsisTitle } from 'components'
import { I18NService } from 'services'

import { Indicator } from '../../../../../../components'
import { MapImage, TrafficLights } from '../../../components'

const { Text: AntText } = Typography

const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.olive} !important;
  font-size: 14px !important;
  padding: 0 0 0 5px;
  font-weight: 400;
`

const Divider = styled(AntDivider)`
  height: 24px !important;
  border-left: ${({ theme }) => `2px solid ${theme.colors.grey2} !important `};
`

const StyledRow = styled(Row)`
  border: ${({ theme }) => `2px solid ${theme.colors.grey2} !important `};
  border-radius: 6px;
  height: 36px;
  max-width: 80px;
  display: flex;
  padding: 4px;
`

const Container = styled(Row)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey1};
  padding: 24px;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  overflow: hidden;
  cursor: pointer;
  max-width: 288px;
`

const HeaderRow = styled(Row)`
  margin-bottom: 8px;
`

const FullWidthRow = styled(Row)`
  width: 100%;
`

const DaysText = styled(AntText)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.olive5} !important;
  font-size: 14px !important;
  padding: 1px;
`

const DaysTextContainer = styled(Col)`
  background: ${({ theme }) => theme.colors.grey2};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  height: 100%;
  min-width: 24px;
`

const TrafficLightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: ${({ theme }) => `2px solid ${theme.colors.grey2} !important `};
  padding: 2px;
  max-width: 56px;
  height: 24px;
`

const DataRow = styled(FullWidthRow)`
  margin-top: 24px;
`

const ResponsiveCol = styled(Col)`
  max-width: 100px;
`

interface Props {
  name: string
  commercialName?: string
  daysSinceEmergenceDate: string
  variety?: string
  size: number
  lotCoordinates: number[][]
  id: number
  irrigationIndicator?: IndicatorStatus
  chlorophyllIndicator?: IndicatorStatus
  semaphoreIndicator?: IndicatorStatus
}

export const LotCard: React.FC<Props> = ({
  name,
  commercialName,
  daysSinceEmergenceDate,
  variety,
  size,
  lotCoordinates,
  id,
  irrigationIndicator,
  chlorophyllIndicator,
  semaphoreIndicator,
}) => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)

  return (
    <Container
      justify="space-around"
      gutter={[0, 24]}
      onClick={() => history.push(`${url}/lots/${id}`)}
    >
      <Col>
        <HeaderRow>
          <Col>
            <EllipsisTitle level={5} title={name} />
          </Col>
          {commercialName && (
            <>
              <Divider type="vertical" />
              <Col>
                <EllipsisTitle level={5} title={commercialName} />
              </Col>
            </>
          )}
        </HeaderRow>
        <FullWidthRow align="middle" wrap={false}>
          <ResponsiveCol>
            <EllipsisText
              text={variety ?? '-'}
              overlayInnerStyle={{
                fontWeight: 400,
                width: '100%',
              }}
            />
          </ResponsiveCol>
          <Divider type="vertical" />
          <Col flex="1 1 100%">
            <Text>{commonT('systemsFormat.area', { val: size })}</Text>
          </Col>

          <FullWidthRow justify="end" wrap={false}>
            <TrafficLightContainer>
              <TrafficLights
                hasEmergenceDate={!!daysSinceEmergenceDate}
                semaphoreIndicator={semaphoreIndicator}
              />
            </TrafficLightContainer>
          </FullWidthRow>
        </FullWidthRow>
        <DataRow wrap={false} gutter={[8, 0]} justify="space-between">
          <Col flex="1 1 94">
            <Space direction="vertical" size={8}>
              <StyledRow align="middle" justify="space-around" wrap={false}>
                <DaysTextContainer>
                  <DaysText>{daysSinceEmergenceDate}</DaysText>
                </DaysTextContainer>
                <Col>
                  <Text>{commonT('vocabulary.daysAfterEmergenceAbbreviation')}</Text>
                </Col>
              </StyledRow>
              <Row justify="start" gutter={8} wrap={false}>
                <Col>
                  <Indicator type={Indicators.IRRIGATION} indicatorStatus={irrigationIndicator} />
                </Col>
                <Col>
                  <Indicator type={Indicators.CHLOROPHYLL} indicatorStatus={chlorophyllIndicator} />
                </Col>
              </Row>
            </Space>
          </Col>
          <Col>
            <MapImage lotCoordinates={lotCoordinates} width="152px" height="80px" />
          </Col>
        </DataRow>
      </Col>
    </Container>
  )
}
