import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { LotsMapLocationState } from 'types'
import { ActiveCompanyContext } from 'contexts'
import { I18NService } from 'services'

import { Card } from '../Card'
import { InvitedUserCard } from '../InvitedUserCard'

interface Props {
  noFields?: boolean
  isAdminUser: boolean
}
export const EmptyResults: React.FC<Props> = ({ noFields, isAdminUser }) => {
  const history = useHistory<LotsMapLocationState>()
  const { activeSeasonId, activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()
  const { t } = useTranslation(I18NService.NAMESPACES.SETTINGS)

  if (!isAdminUser) return <InvitedUserCard companyId={activeCompanyId} />

  return noFields ? (
    <Card
      title={t('modals.firstField.title')}
      description={t('modals.firstField.description')}
      onClick={() => history.push('/settings/fields')}
      buttonText={t('modals.firstField.buttonText')}
    />
  ) : (
    <Card
      title={t('modals.firstLot.title')}
      description={t('modals.firstLot.description')}
      onClick={() => history.push('/settings/lots', { seasonId: activeSeasonId, goTo: '/map' })}
      buttonText={t('modals.firstLot.buttonText')}
    />
  )
}
