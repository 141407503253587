import { addMinutes, format, subMinutes, subDays, parseISO } from 'date-fns'

import { DATE_FORMATS } from 'consts'

const formatToUtc = (date: Date) => {
  const dateTimezone = date.getTimezoneOffset()
  if (dateTimezone < 0) return format(subMinutes(date, dateTimezone), DATE_FORMATS.DATE)

  return format(addMinutes(date, dateTimezone), DATE_FORMATS.DATE)
}

const formatToUtcAndSubtractOneDay = (date: Date) => {
  const subTractOneDay = subDays(date, 1)
  return formatToUtc(subTractOneDay)
}

const parseDate = (date?: string) => {
  if (!date) return undefined
  return parseISO(formatToUtc(new Date(date)))
}

const formatValue = (value: Date | null) => {
  if (!value) return null
  return format(value, DATE_FORMATS.DATE)
}

export const DateUtils = {
  formatToUtc,
  formatToUtcAndSubtractOneDay,
  parseDate,
  formatValue,
}
