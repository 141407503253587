import { Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { useUser, useSubregions } from 'hooks'
import { Button } from 'components'
import { I18NService } from 'services'
import { ConversionUtils } from 'utils'

import { Modal, Description } from '../components'
import { useCreateCompany, useCreateOrganizationCompanyForm } from '../../../hooks'
import { CreateClientForm } from './components'

interface Props {
  onSuccess: () => void
  onCancel: () => void
}

export const CreateClientModal: React.FC<Props> = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMPANY_SETTINGS)

  const { control, handleSubmit } = useCreateOrganizationCompanyForm()

  const user = useUser()
  const { createCompany, loading } = useCreateCompany(onSuccess)

  const { subregions } = useSubregions(user.regions?.length ? user.regions?.[0].id : undefined)

  const subregionOptions = subregions?.map(subregion => {
    return { label: subregion.name, value: subregion.id }
  })
  return (
    <Modal
      title={t('newCompany.agrosalesModalTitle')}
      visible
      footer={null}
      onCancel={onCancel}
      centered
    >
      <Description level={5}>{t('newCompany.agrosalesDescription')}</Description>
      <CreateClientForm
        control={control}
        organizationCountry={user.organizationRole?.organization.country.id ?? ''}
        commercialExecutiveRegion={user.regions?.length ? user.regions?.[0].name : ''}
        subregionOptions={subregionOptions ?? []}
      />
      <Row justify="end">
        <Button
          type="primary"
          loading={loading}
          onClick={handleSubmit(({ companyName, country, governmentId, subregion }) =>
            createCompany(
              {
                name: companyName,
                country,
                organizationId: user.organizationRole?.organization.id,
                governmentId: governmentId
                  ? ConversionUtils.keepOnlyNumbers(governmentId)
                  : undefined,
                subregionId: subregion,
              },
              user.id,
            ),
          )}
        >
          {t('buttons.createClient')}
        </Button>
      </Row>
    </Modal>
  )
}
