import { useTranslation } from 'react-i18next'

import { I18NService } from 'services'

import { FeedbackCard } from '../../components'

export const PasswordChangeRequest: React.FC = () => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOGIN)

  return <FeedbackCard title={t('passwordChangeRequestPage.title')} />
}
