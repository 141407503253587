import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { settingsImage } from 'assets/images'
import { ConfirmationModal, ListCard } from 'components'
import { useUser, usePermissions } from 'hooks'
import { I18NService } from 'services'
import { ActiveCompanyContext } from 'contexts'

import { Layout } from '../../components'
import { useFieldsSettings, useSeasonsSettings } from './hooks'

export const Settings: React.FC = () => {
  const { activeCompanyId } = ActiveCompanyContext.useActiveCompanyContext()

  const user = useUser()
  const { permissions } = usePermissions()
  const { t } = useTranslation(I18NService.NAMESPACES.SETTINGS)

  const {
    showDeleteFieldModal,
    fields,
    loadingFields,
    deleteFieldLoading,
    onAddField,
    onEditField,
    onDeleteField,
    onConfirmDeleteField,
    onCancelDeleteField,
  } = useFieldsSettings()

  const {
    selectedSeasonToDelete,
    showDeleteSeasonModal,
    seasons,
    loadingSeasons,
    deleteSeasonLoading,
    onAddSeason,
    onEditSeason,
    onDeleteSeason,
    onConfirmDeleteSeason,
    onCancelDeleteSeason,
  } = useSeasonsSettings()

  const isAnOrganizationCompany = user.companiesRoles?.find(
    company => company.company.id === activeCompanyId,
  )?.company.organization

  return (
    <Layout
      headerTitle={t('title', { context: permissions.fieldEntity && 'withFieldEntity' })}
      headerImage={settingsImage}
    >
      <Row gutter={24} wrap={false}>
        {permissions.fieldEntity && (
          <Col span={12}>
            <Row>
              <ListCard
                data={fields ?? []}
                title={t('cards.fields.title')}
                description={t('cards.fields.description')}
                header={t('cards.fields.header')}
                buttonDescription={t('cards.fields.buttonText')}
                loading={loadingFields}
                onClick={onAddField}
                onEdit={onEditField}
                onDelete={onDeleteField}
                renderItem={item => <Col>{item.name}</Col>}
                isAdminUser={permissions.isCompanyAdminUser}
              />
            </Row>
          </Col>
        )}
        <Col span={12}>
          <Row>
            <ListCard
              data={seasons ?? []}
              title={
                isAnOrganizationCompany ? t('cards.seasons.titleClient') : t('cards.seasons.title')
              }
              description={
                isAnOrganizationCompany
                  ? t('cards.seasons.descriptionClient')
                  : t('cards.seasons.description')
              }
              header={t('cards.seasons.header')}
              buttonDescription={t('cards.seasons.buttonText')}
              loading={loadingSeasons}
              onClick={onAddSeason}
              onEdit={onEditSeason}
              onDelete={onDeleteSeason}
              renderItem={item => <Col>{item.name}</Col>}
              isAdminUser={permissions.isCompanyAdminUser}
              disabledButton={!permissions.isFeatureSetGrupoDiana || !permissions.isCommercialUser}
            />
          </Row>
        </Col>
      </Row>
      {showDeleteFieldModal && (
        <ConfirmationModal
          description={t('cards.fields.deleteConfirmationText')}
          onConfirm={onConfirmDeleteField}
          onCancel={onCancelDeleteField}
          loading={deleteFieldLoading}
        />
      )}
      {showDeleteSeasonModal && (
        <ConfirmationModal
          description={t('cards.seasons.deleteConfirmationText', {
            seasonName: selectedSeasonToDelete!.name,
          })}
          onConfirm={onConfirmDeleteSeason}
          onCancel={onCancelDeleteSeason}
          loading={deleteSeasonLoading}
        />
      )}
    </Layout>
  )
}
