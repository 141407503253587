import { Row, Col, Typography } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { EllipsisText } from 'components'
import { I18NService } from 'services'

const { Text: AntText } = Typography

const StyledRow = styled(Row)`
  border: ${({ theme }) => `2px solid ${theme.colors.grey2} !important `};
  border-radius: 6px;
  padding: 4px;
`
const Container = styled(Row)`
  padding: 20px;
  background: ${({ theme }) => theme.colors.grey1};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 235px;
`
interface ColProps {
  $maxWidth: string
}
const ResponsiveCol = styled(Col)<ColProps>`
  max-width: ${({ $maxWidth }) => $maxWidth};
`

const CenterCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DaysText = styled(AntText)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.olive5} !important;
  font-size: 16px !important;
  padding: 6px !important;
`

const DaysTextContainer = styled(Col)`
  background: ${({ theme }) => theme.colors.grey2};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  min-width: 44px;
  height: 100%;
`

const EmergenceDateText = styled(AntText)`
  font-size: 14px !important;
  font-weight: 500;
`

interface Props {
  variety: string
  daysSinceEmergenceDate: string
}

export const EmergenceDateCard: React.FC<Props> = ({ variety, daysSinceEmergenceDate }) => {
  const { colors } = useTheme()
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)

  return (
    <Container>
      <CenterCol>
        <ResponsiveCol $maxWidth="135px">
          <EllipsisText
            text={variety}
            overlayInnerStyle={{
              fontWeight: 600,
              width: '100%',
              lineHeight: '24px',
              fontSize: '16px',
              color: colors.olive5,
            }}
          />
        </ResponsiveCol>
      </CenterCol>
      <Col>
        <StyledRow gutter={8} align="middle" justify="center">
          <DaysTextContainer>
            <DaysText>{daysSinceEmergenceDate}</DaysText>
          </DaysTextContainer>
          <ResponsiveCol $maxWidth="100px">
            <EmergenceDateText>{t('emergenceDateCard.daysAfterEmergence')}</EmergenceDateText>
          </ResponsiveCol>
        </StyledRow>
      </Col>
    </Container>
  )
}
