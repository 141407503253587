import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import i18next from 'i18next'

import { Language } from 'types/i18n'

import { Crop, CROPS, Crops } from './api'

export type { Crop }

interface Props {
  placeholder: string
  onChange: (crop: Crop) => void
  className?: string
  loading?: boolean
  defaultValue?: number
}

export const CropsSelect: React.FC<Props> = ({
  placeholder,
  onChange,
  className,
  loading,
  defaultValue,
}) => {
  const { data, loading: dataLoading } = useQuery<Crops>(CROPS)

  return (
    <Select
      placeholder={placeholder}
      defaultValue={defaultValue}
      loading={dataLoading || loading}
      className={className}
      onChange={id => {
        const selectedCrop = data?.crops.results.find(crop => crop.id === id)
        if (!selectedCrop) return
        onChange(selectedCrop)
      }}
    >
      {data?.crops.results.map(crop => (
        <Select.Option key={crop.id} value={crop.id}>
          {crop.locales[i18next.resolvedLanguage as Language]}
        </Select.Option>
      ))}
    </Select>
  )
}
