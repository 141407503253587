import { Row, Space, Col, notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { UpdateRiceLotVariables } from 'features/MainRouter/features/DasboardRouter/features/LotDashboard/api'
import { I18NService } from 'services'

import { Title, Border, ContainerNumber, UnitNumber, UnitText, Input } from '../components'

interface Props {
  isAvailable?: boolean
  yieldValue?: number
  estimatedTnValue?: number
  estimatedKgValuePerHa?: number
  yieldKgValuePerHa?: number
  riceLotId?: number
  updateRiceLotMutation: (updateRiceLotDTO: { variables: UpdateRiceLotVariables }) => void
}

export const DianaGroupBody: React.FC<Props> = ({
  isAvailable,
  estimatedTnValue,
  estimatedKgValuePerHa,
  yieldKgValuePerHa,
  yieldValue,
  riceLotId,
  updateRiceLotMutation,
}) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)

  return (
    <Row>
      <Col span={12}>
        <Row gutter={[0, 24]} align="middle">
          <Col span={24}>
            <Title>{t('yieldCard.expected')}</Title>
          </Col>
          <Col>
            <Space size={24}>
              <Border>
                <ContainerNumber>
                  <UnitNumber>
                    {isAvailable && estimatedTnValue ? estimatedTnValue.toFixed() : '-'}
                  </UnitNumber>
                </ContainerNumber>
                <UnitText>{t('yieldCard.tonnes')}</UnitText>
              </Border>
              <Border>
                <ContainerNumber>
                  <UnitNumber>
                    {isAvailable && estimatedKgValuePerHa
                      ? commonT('systemsFormat.surfaceDensity', {
                          val: estimatedKgValuePerHa,
                          noUnit: true,
                        })
                      : '-'}
                  </UnitNumber>
                </ContainerNumber>
                <UnitText>{commonT('systems.surfaceDensity')}</UnitText>
              </Border>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row gutter={[0, 24]} align="middle">
          <Col span={24}>
            <Title>{t('yieldCard.declared')}</Title>
          </Col>
          <Col>
            <Space size={24}>
              <Border>
                <Input
                  defaultValue={yieldValue}
                  onBlur={(event: React.FormEvent<HTMLInputElement>) => {
                    if (!riceLotId)
                      return notification.error({
                        message: commonT('messages.genericErrorTryAgain'),
                      })
                    const newYield = event.currentTarget.value
                    return updateRiceLotMutation({
                      variables: {
                        id: riceLotId,
                        updateRiceLotDTO: { yield: Number(newYield) * 1000 },
                      },
                    })
                  }}
                />
                <UnitText>{t('yieldCard.tonnes')}</UnitText>
              </Border>
              <Border>
                <ContainerNumber>
                  <UnitNumber>
                    {yieldKgValuePerHa
                      ? commonT('systemsFormat.surfaceDensity', {
                          val: yieldKgValuePerHa,
                          noUnit: true,
                        })
                      : '-'}
                  </UnitNumber>
                </ContainerNumber>
                <UnitText>{commonT('systems.surfaceDensity')}</UnitText>
              </Border>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
