import { ActiveCompanyContext } from 'contexts'
import { useSeason } from 'hooks'

import { BaseFieldsSelect } from '../BaseFieldsSelect'
import { SelectField } from '../types'

interface Props {
  onChange: (field: SelectField) => void
  defaultId?: number
  className?: string
  dropdownClassName?: string
  dropdownMatchSelectWidth?: boolean
}

export const SeasonFieldsSelect: React.FC<Props> = ({
  onChange,
  defaultId,
  className,
  dropdownClassName,
  dropdownMatchSelectWidth,
}) => {
  const { activeSeasonId } = ActiveCompanyContext.useActiveCompanyContext()
  const { data, loading } = useSeason(activeSeasonId)

  const fields = data?.season.seasonFields.filter(({ field }) => field).map(({ field }) => field!)

  return (
    <BaseFieldsSelect
      dropdownClassName={dropdownClassName}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      onChange={onChange}
      defaultId={defaultId}
      className={className}
      loading={loading}
      fields={fields}
    />
  )
}
