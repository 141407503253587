import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { CreateCompanyForm } from 'features/MainRouter/components/create-company-modals/CreateCompanyModal/components'
import { UpdateCompany, UpdateCompanyVariables, UPDATE_COMPANY } from 'api'
import { GraphQLUtils } from 'utils'
import { I18NService } from 'services'

interface FormType {
  companyName: string
  country: string
}

interface Props {
  companyId: number
  data: { companyName?: string; logo?: string; countryId?: string }
  isReadOnly: boolean
}

export const UpdateCompanyForm: React.FC<Props> = ({ companyId, data, isReadOnly }) => {
  const [image, setImage] = useState<string>()
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)

  const [updateCompany] = useMutation<UpdateCompany, UpdateCompanyVariables>(UPDATE_COMPANY, {
    onError: GraphQLUtils.errorHandler,
  })

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(commonT('validations.required')),
  })

  const { control, handleSubmit, reset } = useForm<FormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      companyName: data.companyName,
      country: data.countryId,
    },
  })

  useEffect(() => {
    reset({ companyName: data.companyName, country: data.countryId })
  }, [reset, data])

  return (
    <CreateCompanyForm
      isCreateCompanyMode={false}
      image={image ?? data.logo}
      onImageChange={(imageResult, file) => {
        setImage(imageResult)
        updateCompany({ variables: { id: companyId, updateCompanyDTO: { logo: file } } })
      }}
      control={control}
      onInputNameBlur={handleSubmit(({ companyName }) => {
        updateCompany({ variables: { id: companyId, updateCompanyDTO: { name: companyName } } })
      })}
      isReadyOnly={isReadOnly}
      selectedCompanyCountry={data.countryId}
    />
  )
}
