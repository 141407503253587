const Colors = {
  white: 'white',
  black: 'black',
  primary: '#FF7C33',
  primaryGradient: 'linear-gradient(270deg, #FF9533 0%, #FF7C33 100%)',
  olive: '#546154',
  olive2: '#BBC0BB',
  olive3: '#768176',
  olive4: '#444E45',
  olive5: '#242825',
  grey1: '#F6F6F6',
  grey2: '#DDDFDD',
  grey3: '#999898',
  errorRed: '#FF4D4F',
  transparent: 'transparent',
  trafficLightGreen: '#80E152',
  trafficLightRed: '#FF8970',
  trafficLightField: '#98A098',
  trafficLightNeutral: '#DFDFDF',
  trafficLightYellow: '#FFE070',
  grainHumedityBrown: '#834124',
  grainHumedityLightBrown: '#C2854C',
  grainHumedityOrange: '#FFB84F',
  grainHumedityYellow: '#FAFF00',
  grainHumedityLightGreen: '#43D341',
  grainHumedityGreen: '#0E7115',
  yieldOrange: '#FF7C33',
  yieldRed: '#EA3E27',
  yieldYellow: '#FAFF00',
  yieldLightGreen: '#43D341',
  yieldGreen: '#0E7115',
  yellow: '#FAFF00',
  new: {
    primary: {
      100: '#FFB085',
      200: '#FFA370',
      300: '#FF965C',
      400: '#FF8947',
      500: '#FF7C33',
      600: '#E6702E',
      700: '#CC6329',
      800: '#B35724',
      900: '#994A1F',
    },
    secondary: {
      100: '#80F8CD',
      200: '#66F7C3',
      300: '#4CF6B9',
      400: '#33F5AF',
      500: '#00F29B',
      600: '#00DA8C',
      700: '#00C27C',
      800: '#00A96D',
      900: '#00915D',
    },
    baseColor: {
      50: '#F4F4F5',
      100: '#E9EAEB',
      200: '#D3D4D6',
      300: '#BDBFC2',
      400: '#A7AAAE',
      500: '#7C7F85',
      600: '#666A71',
      700: '#50555D',
      800: '#3A3F48',
      850: '#2F353E',
      900: '#242A34',
    },
    trafficLightGreen: '#00C27C',
    trafficLightRed: '#EA5642',
    trafficLightYellow: '#F2AE00',
  },
}

module.exports = { Colors }
