import styled, { useTheme } from 'styled-components'

import { BallColorsTypes } from '../../types'

interface BallProps {
  background: string
  size: 'small' | 'large'
}

const Ball = styled.div<BallProps>`
  width: ${({ size }) => (size === 'large' ? '18px' : '12px')};
  height: ${({ size }) => (size === 'large' ? '18px' : '12px')};
  border-radius: 50%;
  background-color: ${({ background }) => background};
  margin: ${({ size }) => (size === 'large' ? 'unset' : '2px')};
`

interface Props {
  type?: string
  size?: 'small' | 'large'
}

export const TrafficLightBall: React.FC<Props> = ({
  type = BallColorsTypes.neutral,
  size = 'small',
}) => {
  const { colors } = useTheme()

  interface BallColors {
    [key: string]: string
  }
  const BALL_COLORS: BallColors = {
    FIELD: colors.new.baseColor[850],
    LOT: colors.grey1,
    NEUTRAL: colors.trafficLightNeutral,
    GOOD: colors.trafficLightGreen,
    ALERT: colors.trafficLightYellow,
    DANGER: colors.trafficLightRed,
  }

  return <Ball background={BALL_COLORS[type]} size={size} />
}
