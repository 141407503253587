import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { useMutation } from '@apollo/client'
import { Tooltip } from 'antd'

import { I18NService } from 'services'
import { DateUtils, GraphQLUtils } from 'utils'
import { usePermissions } from 'hooks'

import { UpdateRiceLotVariables } from '../../../api'
import { UpdateLot, UpdateLotVariables, UPDATE_LOT } from '../api'
import { CropsSelect, DatePicker } from '../components'

interface Props {
  lotId: number
  previousCropId?: number
  riceLotId: number
  emergenceDate?: string
  plantingDate?: string
  harvestDate?: string
  preparationDate?: string
  burningDate?: string
  earAppearanceDate?: string
  firstIrrigationStartDate?: string
  firstIrrigationEndDate?: string
  floweringDate?: string
  differentiationDate?: string
  physiologicalMaturityDate?: string
  updateRiceLotMutation: (updateRiceLotDTO: { variables: UpdateRiceLotVariables }) => void
}

export const useBody = ({
  riceLotId,
  plantingDate,
  emergenceDate,
  harvestDate,
  preparationDate,
  burningDate,
  earAppearanceDate,
  lotId,
  differentiationDate,
  firstIrrigationEndDate,
  firstIrrigationStartDate,
  floweringDate,
  physiologicalMaturityDate,
  previousCropId,
  updateRiceLotMutation,
}: Props) => {
  const { t } = useTranslation(I18NService.NAMESPACES.LOT_DASHBOARD)
  const { t: commonT } = useTranslation(I18NService.NAMESPACES.COMMON)
  const { colors } = useTheme()
  const { permissions } = usePermissions()

  const [updateLot, { loading }] = useMutation<UpdateLot, UpdateLotVariables>(UPDATE_LOT, {
    onError: GraphQLUtils.errorHandler,
  })

  const dianaGroupTimelineData = [
    {
      title: t('dianaGroupTimeline.preparation'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(preparationDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { preparationDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!preparationDate,
    },
    {
      title: t('dianaGroupTimeline.burning'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(burningDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { burningDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!burningDate,
    },
    {
      title: t('dianaGroupTimeline.planting'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(plantingDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { plantingDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!plantingDate,
    },
    {
      title: t('dianaGroupTimeline.emergence'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(emergenceDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { emergenceDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!emergenceDate,
    },
    {
      title: t('dianaGroupTimeline.earAppearance'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(earAppearanceDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { earAppearanceDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!earAppearanceDate,
    },
    {
      title: t('dianaGroupTimeline.harvest'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(harvestDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { harvestDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!harvestDate,
    },
  ]

  const timelineData = [
    {
      title: t('timeline.previousCrop'),
      component: (
        <CropsSelect
          loading={loading}
          placeholder={t('timeline.selectCrop')}
          defaultValue={previousCropId}
          onChange={value =>
            updateLot({ variables: { updateLotDTO: { id: lotId, previousCropId: value.id } } })
          }
        />
      ),
      completedDate: !!previousCropId,
    },
    {
      title: t('timeline.currentPlanting'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(plantingDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { plantingDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!plantingDate,
    },

    {
      title: t('timeline.emergence'),
      component: permissions.isCompanyAdminUser ? (
        <DatePicker
          defaultValue={DateUtils.parseDate(emergenceDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { emergenceDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ) : (
        <Tooltip title={commonT('messages.readOnlyAccess')}>
          <DatePicker
            disabled
            defaultValue={DateUtils.parseDate(emergenceDate)}
            onChange={value =>
              updateRiceLotMutation({
                variables: {
                  id: riceLotId,
                  updateRiceLotDTO: { emergenceDate: DateUtils.formatValue(value) },
                },
              })
            }
          />
        </Tooltip>
      ),
      completedDate: !!emergenceDate,
    },
    {
      title: t('timeline.initialIrrigation'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(firstIrrigationStartDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { firstIrrigationStartDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!firstIrrigationStartDate,
    },
    {
      title: t('timeline.irrigationCompleted'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(firstIrrigationEndDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { firstIrrigationEndDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!firstIrrigationEndDate,
    },
    {
      title: t('timeline.panicleInitiation'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(differentiationDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { differentiationDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!differentiationDate,
    },
    {
      title: t('timeline.flowering'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(floweringDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { floweringDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!floweringDate,
    },
    {
      title: t('timeline.maturation'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(physiologicalMaturityDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { physiologicalMaturityDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!physiologicalMaturityDate,
    },
    {
      title: t('timeline.harvest'),
      component: (
        <DatePicker
          defaultValue={DateUtils.parseDate(harvestDate)}
          onChange={value =>
            updateRiceLotMutation({
              variables: {
                id: riceLotId,
                updateRiceLotDTO: { harvestDate: DateUtils.formatValue(value) },
              },
            })
          }
        />
      ),
      completedDate: !!harvestDate,
    },
  ]

  return {
    bodyData: permissions.isFeatureSetGrupoDiana ? dianaGroupTimelineData : timelineData,
    ballBackground: permissions.isFeatureSetGrupoDiana ? colors.new.secondary[600] : colors.primary,
  }
}
