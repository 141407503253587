import { useState } from 'react'
import { Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { useUser } from 'hooks'
import { Button } from 'components'
import { I18NService } from 'services'

import { Modal, Description } from '../components'
import { CreateCompanyForm } from './components'
import { useCreateCompany, useCreateCompanyForm } from '../../../hooks'

interface Props {
  isFirstCompany?: boolean
  onSuccess: () => void
  onCancel: () => void
}

export const CreateCompanyModal: React.FC<Props> = ({ onSuccess, onCancel, isFirstCompany }) => {
  const { t } = useTranslation(I18NService.NAMESPACES.COMPANY_SETTINGS)
  const [image, setImage] = useState<string>()
  const [imageFile, setImageFile] = useState<File>()

  const { control, handleSubmit } = useCreateCompanyForm()

  const user = useUser()
  const { createCompany, loading } = useCreateCompany(onSuccess)

  return (
    <Modal
      title={isFirstCompany ? t('firstCompany.modalTitle') : t('newCompany.modalTitle')}
      visible
      footer={null}
      onCancel={onCancel}
      centered
    >
      <Description level={5}>
        {isFirstCompany ? t('firstCompany.description') : t('newCompany.description')}
      </Description>
      <CreateCompanyForm
        image={image}
        onImageChange={(imageResult, file) => {
          setImage(imageResult)
          setImageFile(file)
        }}
        control={control}
        isCreateCompanyMode
      />
      <Row justify="end">
        <Button
          type="primary"
          loading={loading}
          onClick={handleSubmit(({ companyName, country }) =>
            createCompany({ name: companyName, country, logo: imageFile! }, user.id),
          )}
        >
          {t('buttons.create')}
        </Button>
      </Row>
    </Modal>
  )
}
