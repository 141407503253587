import { LANGUAGE_CODES } from 'consts'

const celsiusToFahrenheit = (value: number) => `${(value * 1.8 + 32).toFixed()}°`

// Value system unit is celsius
const formatDegree = (value: number, lng?: string) => {
  switch (lng) {
    case LANGUAGE_CODES.US:
      return celsiusToFahrenheit(value)
    default:
      return `${value.toFixed()}°`
  }
}

const hectareToAcres = (value: number) => `${(value * 2.4711).toFixed()} ac`

// Value system unit is hectares
const formatArea = (value: number, lng?: string) => {
  switch (lng) {
    case LANGUAGE_CODES.US:
      return hectareToAcres(value)
    default:
      return `${value.toFixed()} ha`
  }
}

// 1 bushel of rice = 20.4117 kg & 1 hectare = 2.47105 acre
const kgPerHaToBuPerAc = (value: number, noUnit?: boolean) =>
  `${(value / 50.438331285).toFixed()} ${noUnit ? '' : 'bu/ac'}`

// Value system unit is kg/ha
const formatSurfaceDensity = (value: number, options: { lng?: string; noUnit?: boolean } = {}) => {
  const { lng, noUnit } = options
  switch (lng) {
    case LANGUAGE_CODES.US:
      return kgPerHaToBuPerAc(value, noUnit)
    default:
      return `${value.toFixed()} ${noUnit ? '' : 'kg/ha'}`
  }
}

const keepOnlyNumbers = (value: string) => value.replace(/[^0-9]/g, '')

const autoCapitalize = (value: string): string => {
  const splittedString = value.split(' ')
  const capitalizedWords = splittedString.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  )

  return capitalizedWords.join(' ')
}

export const ConversionUtils = {
  formatDegree,
  formatArea,
  formatSurfaceDensity,
  keepOnlyNumbers,
  autoCapitalize,
}
